import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  DOWNLOAD_FORMAT_EXCEL,
  FETCH_WAREHOUSE_REQUEST,
  UPLOAD_WAREHOUSE_STOCK,
  FETCH_PRODUCT_REQUEST,
  UPLOAD_PRODUCT_STOCK,
} from "./warehouseAction";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import shipmenetServices from "../../services/shipmenet.services";
import stockServices from "../../services/stock.services";
import { FETCH_LOCAL_REQUEST_FAILED, LOCAL_DATA } from "../stock/stockAction";

function* downloadFormate(action) {
  try {
    const { data } = yield call(
      shipmenetServices.downlaodFormat,
      action.payload
    );
    const link = document.createElement("a");
    link.href = data?.excel_url;
    link.download = "your-excel-file.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showSuccessAlert("Excel format downlaod successfully!");
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* fetchWarehouseData(action) {
  try {
    const { data } = yield call(
      stockServices.fetchdatawareHouse,
      action.currentPage,
      action.itemsPerPage
    );
    yield put({ type: LOCAL_DATA, payload: data });
  } catch (error) {
    yield put({ type: FETCH_LOCAL_REQUEST_FAILED });
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* Uploadwarehouse(action) {
  try {
    const { data } = yield call(stockServices.Uploadwarehouse, action.payload);
    showSuccessAlert("warehouse file uploaded successfully!");
    yield call(fetchWarehouseData);
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* fetchProductData(action) {
  try {
    const { data } = yield call(
      stockServices.fetchdataproduct,
      action.currentPage,
      action.itemsPerPage
    );
    yield put({ type: LOCAL_DATA, payload: data });
  } catch (error) {
    yield put({ type: FETCH_LOCAL_REQUEST_FAILED });
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* UploadProduct(action) {
  try {
    const { data } = yield call(stockServices.Uploadproduct, action.payload);
    showSuccessAlert("Product file uploaded successfully!");
    yield call(fetchProductData);
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
export default function* warehouseSaga() {
  yield all([
    takeLatest(DOWNLOAD_FORMAT_EXCEL, downloadFormate),
    takeLatest(FETCH_WAREHOUSE_REQUEST, fetchWarehouseData),
    takeLatest(UPLOAD_WAREHOUSE_STOCK, Uploadwarehouse),
    takeLatest(FETCH_PRODUCT_REQUEST, fetchProductData),
    takeLatest(UPLOAD_PRODUCT_STOCK, UploadProduct),
  ]);
}
