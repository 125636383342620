import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line, RiSave3Line, RiCloseFill } from "react-icons/ri";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  TableCell,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { useUIContext } from "../../context/Basicproviders";
import {
  DeleteButton,
  StyledTableCell,
  TableContainerStyled,
} from "../../Style/Common.style";
import { ToastContainer } from "react-toastify";
import Loader from "../globel/Loader";
import Searchbar from "../globel/Searchbar";
import { BpCheckbox, DownloadPdf, Localstock } from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import Bottombar from "../globel/Bottombar";
import { useDispatch } from "react-redux";
import {
  FETCH_LOCAL_REQUEST,
  LOCAL_FILTER,
  REQUEST_DELETE_LOCAL,
  REQUEST_UPDATE_LOCAL,
} from "../../redux/stock/stockAction";
import { useStockMaster } from "../../redux/stock/stockReducer";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const Local = () => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const { localStock } = useStockMaster();
  const { indicater } = useStockMaster();
  const {
    editingRow,
    setEditingRow,
    setCurrentPage,
    currentPage,
    itemsPerPage,
    searchValue,
    handleCloseEditrow,
    selectedDomain,
    isLaptopSize,
  } = useUIContext();

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowSelect = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId)); // Deselect row if already selected
    } else {
      setSelectedRows([...selectedRows, rowId]); // Select row if not selected
    }
  };

  const isSelected = (rowId) => selectedRows.includes(rowId);

  const fetchdata = () => {
    dispatch({
      type: FETCH_LOCAL_REQUEST,
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
  };

  const date = new Date();
  const [editData, setEditData] = useState({
    product_name: "",
    product_price: "",
    cartoon_pieces: 0,
    total_pieces: 0,
    cartoon_weight: 0,
    cartoon_height: 0,
    cartoon_width: 0,
    purchased_date: moment(date).format("YYYY-MM-DD"),
  });

  const handleEditClick = (rowId, item) => {
    setEditingRow(rowId);
    const editedFields = {};
    localStock?.editable_fields?.forEach((field) => {
      editedFields[field] =
        field === "purchased_date"
          ? moment(item[field]).format("YYYY-MM-DD")
          : item[field];
    });
    setEditData(editedFields);
  };

  const handleInputChange = (e) => {
    setEditData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleupdateSave = (id) => {
    setEditingRow(null);
    const updatedData = {};
    localStock?.editable_fields?.forEach((field) => {
      if (editData.hasOwnProperty(field)) {
        if (field.startsWith("product")) {
          updatedData.product = {
            ...updatedData.product,
            [field]: editData[field],
          };
        } else {
          updatedData[field] =
            field === "purchased_date"
              ? moment(editData[field]).format("YYYY-MM-DD")
              : editData[field];
        }
      }
    });
    dispatch({
      type: REQUEST_UPDATE_LOCAL,
      id: id,
      payload: updatedData,
    });
  };

  const deletelocalstock = (deletetype, id) => {
    if (deletetype === "singledelete") {
      dispatch({
        type: REQUEST_DELETE_LOCAL,
        payload: id,
        deletetype: deletetype,
      });
    } else {
      dispatch({
        type: REQUEST_DELETE_LOCAL,
        payload: selectedRows,
        deletetype: deletetype,
      });
    }
  };

  useEffect(() => {
    if (searchValue !== null && selectedDomain !== null) {
      if (searchValue) {
        const filterdata = localStock?.results?.filter(
          (item) =>
            (item?.product?.product_name &&
              item?.product?.product_name
                .toLowerCase()
                .includes(searchValue ? searchValue.toLowerCase() : "")) ||
            (item?.product?.sku &&
              item?.product?.sku
                .toLowerCase()
                .includes(searchValue ? searchValue.toLowerCase() : ""))
        );
        dispatch({ type: LOCAL_FILTER, payload: filterdata });
      } else {
        fetchdata();
      }
    }
  }, [selectedDomain, searchValue, currentPage, itemsPerPage]);

  return (
    <>
      {indicater ? (
        <Loader />
      ) : (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
              sx={{
                height: "78vh",
                overflowY: "auto",
                padding: "0rem 1rem 0rem",
                width: isLaptopSize ? "80vw" : "86vw",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Searchbar placeholder="Search product name,sku" />
                <Box sx={{ display: "flex", gap: 1 }}>
                  {" "}
                  <DeleteButton
                    onClick={() => deletelocalstock("multidelete", "")}
                  >
                    <RiDeleteBin5Line size="1rem" /> Multi Delete
                  </DeleteButton>
                  <Localstock />
                  <DownloadPdf data={localStock} filename="local" />
                </Box>
              </Box>
              <Box
                sx={{
                  borderRadius: "0.50rem",
                  padding: "1rem 0rem",
                  marginBottom: "2rem",
                  height: "65vh",
                }}
              >
                <TableContainerStyled>
                  <Table aria-label="customized table">
                    <TableHead>
                      {localStock?.display_names &&
                        localStock?.display_names && (
                          <TableRow>
                            <StyledTableCell>
                              {" "}
                              <FormControl
                                control={
                                  <BpCheckbox
                                  // checked={selectAll}
                                  // indeterminate={!selectAll && checkedRows.length > 0}
                                  // onChange={handleSelectAllChange}
                                  />
                                }
                                label="ID"
                              />
                            </StyledTableCell>
                            {Object.entries(localStock?.display_names).map(
                              ([key, value], index) => (
                                <StyledTableCell
                                  sx={{ textAlign: "center" }}
                                  key={index}
                                >
                                  {value}
                                </StyledTableCell>
                              )
                            )}
                            {localStock?.editable_fields && (
                              <StyledTableCell> Edit</StyledTableCell>
                            )}
                          </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                      {localStock?.results && localStock?.results.length > 0 ? (
                        localStock?.results?.map((row, index) => {
                          return (
                            <TableRow key={row.id}>
                              <StyledTableCell align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isSelected(row.id)}
                                      onChange={() => handleRowSelect(row.id)}
                                    />
                                  }
                                />
                              </StyledTableCell>
                              {Object.entries(localStock?.display_names).map(
                                ([key, displayName], index) => {
                                  const isEditable =
                                    localStock?.editable_fields.includes(key);
                                  return (
                                    <StyledTableCell align="center" key={index}>
                                      {isEditable && editingRow === row.id ? (
                                        key === "purchased_date" ? (
                                          <TextField
                                            variant="standard"
                                            label={displayName}
                                            type="date"
                                            name={key}
                                            value={editData[key]}
                                            onChange={(e) =>
                                              setEditData((prevValues) => ({
                                                ...prevValues,
                                                [key]: e.target.value,
                                              }))
                                            }
                                            InputProps={{
                                              sx: {
                                                borderBottom: "1px solid #ccc",
                                              },
                                            }}
                                          />
                                        ) : (
                                          <TextField
                                            id={key}
                                            label={displayName}
                                            variant="standard"
                                            type="text"
                                            name={key}
                                            value={editData[key]}
                                            onChange={handleInputChange}
                                          />
                                        )
                                      ) : key === "product_name" ? (
                                        row?.product?.product_name
                                      ) : key === "product_price" ? (
                                        row?.product?.product_price
                                      ) : key === "sku" ? (
                                        row?.product?.sku
                                      ) : (
                                        row[key]
                                      )}
                                    </StyledTableCell>
                                  );
                                }
                              )}
                              <StyledTableCell align="center">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    cursor: "pointer",
                                    gap: 2,
                                  }}
                                >
                                  {editingRow === row.id ? (
                                    <RiCloseFill
                                      onClick={handleCloseEditrow}
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                    />
                                  ) : (
                                    <RiDeleteBin5Line
                                      onClick={() =>
                                        deletelocalstock("singledelete", row.id)
                                      }
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                    />
                                  )}
                                  {editingRow === row.id ? (
                                    <RiSave3Line
                                      onClick={() => handleupdateSave(row.id)}
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                    />
                                  ) : (
                                    <FiEdit
                                      onClick={() =>
                                        handleEditClick(row.id, row)
                                      }
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                    />
                                  )}
                                </Box>
                              </StyledTableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow sx={{ textAlign: "center" }}>
                          <TableCell
                            align="center"
                            colSpan={
                              Object.keys(localStock?.display_names || {})
                                .length + 1
                            }
                          >
                            NO ROW DATA
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainerStyled>
              </Box>
            </Box>
          </LocalizationProvider>
          <Bottombar
            currentPage={currentPage}
            totalPages={Math.ceil(localStock?.count / itemsPerPage)}
            itemsPerPage={itemsPerPage}
            onChangePage={handlePageChange}
          />
        </>
      )}

      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default Local;
