import React, { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Avatar from "@mui/material/Avatar";
import { ToastContainer } from "react-toastify";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
  styled,
  Box,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import Parmissionmodel from "../modals/Permissionmodel";
import Createuser from "../modals/Createuser";
import userServices from "../../services/user.services";
import { showDeleteDataAlert, showSuccessAlert } from "../../toast/toast";
import Resetpassword from "../modals/Resetpassword";
import { TableContainerStyled } from "../../Style/Common.style";
import { RiDeleteBin5Line } from "react-icons/ri";
import Bottombar from "../globel/Bottombar";
import { useUIContext } from "../../context/Basicproviders";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { useDispatch } from "react-redux";
import { USERDATA_REQUEST } from "../../redux/auth/authAction";
import { useAuthMaster } from "../../redux/auth/authReducer";

const Colors = tokens();
const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  width: "10rem",
  height: "2.5rem",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});
const CustomdResetButton = styled(Button)({
  backgroundColor: Colors.primary[400],
  color: Colors.white[100],
  width: "10rem",
  height: "2.5rem",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.primary[400],
  },
});
const StyledTableCell = styled(TableCell)(({ theme, index }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.black[400],
      color: Colors.header_fontcolor[100],
      fontSize: "1rem",
      position: "sticky",
      top: 0,
      zIndex: 1000,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: Colors.black_font[100],
      padding: "1rem 1rem",
      backgroundColor: Colors.black[700],
      borderBottom: "15px solid #fff",
    },
  };
});
const UserManagement = () => {
  const { currentPage, itemsPerPage, setCurrentPage } = useUIContext();
  const dispatch = useDispatch();
  const { userData } = useAuthMaster();
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [passwordError, setpassworderror] = useState("");
  const [userId, setUserid] = useState("");

  const handleOpen = (id) => {
    setUserid(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setpassworderror("");
  };

  const handleClickCreateOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getUsers = () => {
    dispatch({ type: USERDATA_REQUEST });
  };
  const deleteuser = (id) => {
    try {
      showDeleteDataAlert(
        "Are you sure you want to delete this user?",
        async () => {
          userServices
            .deleteuser(id)
            .then(() => {
              showSuccessAlert("User deleted successfully done");
              getUsers();
            })
            .catch((error) => console.log("error", error));
        }
      );
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box
        sx={{ padding: "1rem 2rem 0rem", overflowY: "auto", height: "76vh" }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <CustomStyledButton
            component="label"
            variant="contained"
            onClick={handleClickCreateOpenModal}
          >
            + Create User
          </CustomStyledButton>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "1rem",
            height: "63vh",
          }}
        >
          <TableContainerStyled>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                {userData?.display_names && userData?.display_names && (
                  <TableRow>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      No
                    </StyledTableCell>

                    {Object.entries(userData?.display_names).map(
                      ([key, value], index) => (
                        <StyledTableCell
                          sx={{ textAlign: "center" }}
                          key={index}
                        >
                          {value}
                        </StyledTableCell>
                      )
                    )}
                    <StyledTableCell align="center">
                      Reset Password
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Remove User
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {userData?.results && userData?.results.length > 0 ? (
                  userData?.results
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((row, index) => (
                      <TableRow key={row.id} index={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>

                        {Object.entries(userData?.display_names).map(
                          ([key, displayName], index) => {
                            return (
                              <StyledTableCell align="center" key={index}>
                                {key === "schema_name" ? (
                                  row?.organizations
                                    .map((org) => org.schema_name)
                                    .join(", ")
                                ) : key === "username" ? (
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "left",
                                        gap: 2,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          borderRadius: "2.375rem",
                                        }}
                                      >
                                        <Avatar
                                          alt={
                                            row.username
                                              ? row.username
                                                  .charAt(0)
                                                  .toUpperCase()
                                              : ""
                                          }
                                          src={
                                            row.username
                                              ? row.username
                                                  .charAt(0)
                                                  .toUpperCase()
                                              : ""
                                          }
                                        />
                                      </Box>
                                      <Box>
                                        <Box>{row.username}</Box>
                                        <Box
                                          sx={{
                                            color: Colors.grey[100],
                                          }}
                                        >
                                          {row.email}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                ) : (
                                  row[key]
                                )}
                              </StyledTableCell>
                            );
                          }
                        )}
                        <StyledTableCell align="center">
                          <Box sx={{ paddingLeft: "1rem", cursor: "pointer" }}>
                            {" "}
                            <CustomdResetButton
                              onClick={() => handleOpen(row.id)}
                            >
                              Reset Password
                            </CustomdResetButton>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            sx={{
                              paddingLeft: "1rem",
                              cursor: "pointer",
                              paddingTop: "1.2rem",
                            }}
                            onClick={() => deleteuser(row.id)}
                          >
                            <RiDeleteBin5Line
                              style={{
                                fontSize: "1.5rem",
                                color: Colors.black[100],
                                margin: "auto",
                                display: "block",
                              }}
                            />
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow sx={{ textAlign: "center" }}>
                    <TableCell
                      align="center"
                      colSpan={
                        Object.keys(userData?.display_names || {}).length + 1
                      }
                    >
                      NO ROW DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainerStyled>
        </Box>
        <Resetpassword
          open={open}
          handleClose={handleClose}
          setpassworderror={setpassworderror}
          passwordError={passwordError}
          userId={userId}
        />
        <Createuser
          show={showModal}
          handleCloseModal={handleCloseModal}
          getUsers={getUsers}
        />
        <Parmissionmodel />
      </Box>
      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(userData?.results?.length / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default UserManagement;
