import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Button, Typography, Box, Alert } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import signin from "../Images/signin.png";
import logo from "../Images/logo.png";
import { tokens } from "../theme";
import { Formik } from "formik";
import * as yup from "yup";
import userServices from "../services/user.services";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AUTH_REQUEST } from "../redux/auth/authAction";
import { useAuthMaster } from "../redux/auth/authReducer";

const useStyles = makeStyles(() => ({
  formLabel: {
    display: "block",
    fontSize: "1.5rem",
    fontWeight: "300",
  },

  button: {
    marginTop: "3rem",
    padding: "0.8rem 2rem",
    backgroundColor: "#002B53",
    color: "#fff",
    border: "none",
    fontSize: "18px",
    borderRadius: "0.5rem",
  },
}));
const SigninGoogle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authFailed } = useAuthMaster();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const Submitform = (values) => {
    dispatch({ type: AUTH_REQUEST, payload: values });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);
  const Colors = tokens();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        minWidth: "100vw",
        justifyContent: "space-around",
        backgroundColor: Colors.white[400],
      }}
    >
      <Box
        className="d-none d-lg-block"
        sx={{
          width: "50%",
          height: "100%",
        }}
      >
        <img
          src={signin}
          alt="Signin"
          style={{
            width: "65%",
            height: "70%",
            position: "relative",
            left: "8rem",
            top: "8rem",
          }}
        />
      </Box>
      <Box className="container" style={{ maxWidth: "600px" }}>
        <img src={logo} className="pb-4 w-25" alt="Logo" />
        <Typography variant="h4" className="pb-4 fw-bold">
          Welcome back
        </Typography>
        <Typography
          style={{
            paddingBottom: "2rem",
            color: "#A0A0A0",
            fontWeight: "400",
            fontSize: "18px",
          }}
        >
          Please Enter Your Details.
        </Typography>
        {authFailed && <Alert severity="error">{authFailed.message}</Alert>}
        <Formik
          onSubmit={Submitform}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
          }) => (
            <form className="container">
              <label className={classes.formLabel}>Username</label>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.formInput}
                name="username"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
              />

              <label className={classes.formLabel}>Password</label>
              <TextField
                variant="outlined"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                type={showPassword ? "text" : "password"}
                fullWidth
                margin="normal"
                className={classes.formInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={handleTogglePassword}
                        sx={{ color: Colors.black[100] }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <button
                className={`${classes.button} mt-3`}
                variant="contained"
                onClick={handleSubmit}
              >
                Login
              </button>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});
const initialValues = {
  username: "",
  password: "",
};
export default SigninGoogle;
