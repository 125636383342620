import "./App.css";
import SigninGoogle from "./Account/SigninGoogle";
import { Route, Routes } from "react-router-dom";
import FBA from "./componet/Stock/FBA";
// import "antd/dist/antd.css";
import Local from "./componet/Stock/Local";
import Dashboard from "./componet/globel/Dashboard";
import ShipmentAlert from "./componet/Shipment/ShipmentAlert";
import Main from "./Main";
import Report from "./componet/report/Report";
import Process from "./componet/Shipment/Process";
import Intransaction from "./componet/Shipment/InTransaction ";
import ShipmentTransaction from "./componet/Shipment/ShipmentTransaction";
import Receive from "./componet/Shipment/Receive";
import UserManagement from "./componet/Configuration/UserManagement";
import SystemDataMapping from "./componet/Configuration/SystemDataMapping";
import Basicprovider from "./context/Basicproviders";
import { Container, CssBaseline } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container disableGutters maxWidth="xxl">
            <Basicprovider>
              <Routes>
                <Route path="/" element={<SigninGoogle />} />
                <Route
                  path="/dashboard"
                  element={<Main title={Dashboard} headerTitle="Analysis" />}
                />
                <Route
                  path="/fba"
                  element={<Main title={FBA} headerTitle="FBA" />}
                />
                <Route
                  path="/local"
                  element={<Main title={Local} headerTitle="Local" />}
                />
                <Route
                  path="/shipment-alert"
                  element={
                    <Main title={ShipmentAlert} headerTitle="Shipment Alert" />
                  }
                />
                <Route
                  path="/report"
                  element={<Main title={Report} headerTitle="Report" />}
                />
                <Route
                  path="/process"
                  element={<Main title={Process} headerTitle="Process" />}
                />
                <Route
                  path="/in-transaction"
                  element={
                    <Main title={Intransaction} headerTitle="In Transaction" />
                  }
                />
                <Route
                  path="/shipment-in-transaction"
                  element={
                    <Main
                      title={ShipmentTransaction}
                      headerTitle="Shipment In Transaction"
                    />
                  }
                />
                <Route
                  path="/received"
                  element={<Main title={Receive} headerTitle="Received" />}
                />
                <Route
                  path="/user-management"
                  element={
                    <Main
                      title={UserManagement}
                      headerTitle="User Management"
                    />
                  }
                />
                <Route
                  path="/system-data-mapping"
                  element={
                    <Main
                      title={SystemDataMapping}
                      headerTitle="System data mapping"
                    />
                  }
                />
              </Routes>
            </Basicprovider>
          </Container>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
