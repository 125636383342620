import React from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { Formik } from "formik";
import { tokens } from "../../theme";
import { Box, Typography, TextField, styled, Button } from "@mui/material";
import * as yup from "yup";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import stockServices from "../../services/stock.services";
import { useDispatch } from "react-redux";
import { FETCH_WAREHOUSE_REQUEST } from "../../redux/warehouse/warehouseAction";

const Colors = tokens();

const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],
});
const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});
const EditWarehouse = ({ handleClose, open, formValues }) => {
  const dispatch = useDispatch();
  const handleUpdateSave = async (values) => {
    try {
      const data = await stockServices.warehouesupdate(formValues.id, values);
      if (data.result === "success") {
        showSuccessAlert(" warehoues successfully updated");
        handleClose();
        dispatch({ type: FETCH_WAREHOUSE_REQUEST });
      }
    } catch (error) {
      showErrorAlert(
        error.response?.data?.data.message ||
          "An error occurred while updating warehoues"
      );
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "590px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "1rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" mb={2}>
              Edit Warehouse
            </Typography>
            <IoMdClose
              size={"25px"}
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
          <hr />
          <Formik initialValues={formValues} onSubmit={handleUpdateSave}>
            {(formikProps) => (
              <form
                style={{ marginLeft: "5rem" }}
                onSubmit={formikProps.handleSubmit}
              >
                <FormRow>
                  <Label htmlFor="fc_code">fc Code</Label>
                  <TextField
                    type="text"
                    id="fc_code"
                    name="fc_code"
                    value={formikProps.values.fc_code}
                    onChange={formikProps.handleChange}
                    size="small"
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="state">State</Label>
                  <TextField
                    type="text"
                    id="state"
                    name="state"
                    value={formikProps.values.state}
                    onChange={formikProps.handleChange}
                    size="small"
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="state">Master State</Label>
                  <TextField
                    type="text"
                    id="state"
                    name="master_state"
                    value={formikProps.values.master_state}
                    onChange={formikProps.handleChange}
                    size="small"
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="region">Region</Label>
                  <TextField
                    type="text"
                    id="region"
                    name="region"
                    value={formikProps.values.region}
                    onChange={formikProps.handleChange}
                    size="small"
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="region_code">Region Code</Label>
                  <TextField
                    type="text"
                    id="region_code"
                    name="region_code"
                    value={formikProps.values.region_code}
                    onChange={formikProps.handleChange}
                    size="small"
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="day">Day</Label>
                  <TextField
                    type="text"
                    id="day"
                    name="day"
                    value={formikProps.values.day}
                    onChange={formikProps.handleChange}
                    size="small"
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>

                <CustomStyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </CustomStyledButton>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default EditWarehouse;
