import React from "react";
import {
  Box,
  styled,
  Modal,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
const Label = styled("label")({
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  textWrap: "nowrap",
});

const Sellerdata = ({ open, handleClose, errorResponse }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          width:"90%",
          width:"550px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0rem 2rem",
            paddingTop: "0.5rem",

          }}
        >
          <Typography variant="h5">Errors</Typography>
          <IoMdClose
            size={"25px"}
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <hr />
          <Table sx={{margin:"1.5rem",width:"31rem"}}>
            <TableHead >
              <TableRow>
                <TableCell sx={{border:"2px solid #ccc",fontWeight:500,fontSize:"1.2rem"}}>Type</TableCell>
                <TableCell sx={{border:"2px solid #ccc",fontWeight:500,fontSize:"1.2rem"}}>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorResponse && errorResponse.length > 0 ? (
                errorResponse.map((value, index) => (
                  <TableRow key={index} sx={{border:"2px solid #ccc"}}>
                    <TableCell sx={{border:"2px solid #ccc"}}>{value.error_data_type}</TableCell>
                    <TableCell sx={{border:"2px solid #ccc"}}>
                      {value.claim_name && (
                        <Typography >
                          <Label>Claim Name:</Label> {value.claim_name}
                        </Typography>
                      )}
                      {value.claim_id && (
                        <Typography>
                          <Label>Claim ID:</Label> {value.claim_id}
                        </Typography>
                      )}
                      {value.notes && (
                        <Typography>
                          <Label>Notes:</Label> {value.notes}
                        </Typography>
                      )}
                      {value.error_quantity && (
                        <Typography>
                          <Label>Error Quantity:</Label> {value.error_quantity}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>No data found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
      </Box>
    </Modal>
  );
};

export default Sellerdata;
