import React, { useEffect, useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import { tokens } from "../../theme";
import Datamapping from "../modals/Datamapping";
import { useUIContext } from "../../context/Basicproviders";
import {
  SystemdataButton,
  VisuallyHiddenInput,
  Editbutton,
  DownloadPdfbutton,
} from "../../Style/Common.style";
import { ReportBoxtitle } from "../../data/Mockdata";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { FiUpload } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import Product from "../modals/Product";
import Warehouse from "../modals/Warehouse";
import Localstock from "../modals/Localstock";
import { useDispatch } from "react-redux";
import { DOWNLOAD_FORMAT_EXCEL } from "../../redux/warehouse/warehouseAction";
import { TbFileDownload } from "react-icons/tb";

const Colors = tokens();

const CustomStyledBox = styled(Box)({
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "1.6px",
  marginBottom: "3rem",
});

const SystemDataMapping = () => {
  const { handleShow, currentPage, itemsPerPage, setCurrentPage } =
    useUIContext();
  const [showWarehouseModal, setShowWarehouseModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showLocalstockModal, setShowLocalStockModal] = useState(false);
  const [modalTypeName, setmodalTypeName] = useState("");
  const dispatch = useDispatch();

  const handleFileInputChange = (handler, event) => {
    const file = event.target.files[0];
    handler(dispatch, file);
  };
  const handleShowModal = (modalType) => {
    if (modalType === "Manage Warehouses") {
      setShowWarehouseModal(true);
      setShowProductModal(false);
      setShowLocalStockModal(false);
    } else if (modalType === "Manage Product") {
      setShowWarehouseModal(false);
      setShowProductModal(true);
      setShowLocalStockModal(false);
    } else if (modalType === "Manage Local") {
      setShowWarehouseModal(false);
      setShowProductModal(false);
      setShowLocalStockModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowWarehouseModal(false);
    setShowProductModal(false);
    setShowLocalStockModal(false);
  };

  useEffect(() => {
    const currentItem = ReportBoxtitle?.find(
      (item) => item.name === modalTypeName
    );
    currentItem?.fetchdata(dispatch, currentPage, itemsPerPage);
  }, [dispatch, currentPage, itemsPerPage, modalTypeName]);
  return (
    <>
      <Box
        sx={{
          height: "80vh",
          overflowY: "auto",
          padding: "1rem 3rem 0rem",
        }}
      >
        <Grid
          container
          rowSpacing={6}
          columnSpacing={{ xs: 1, sm: 2, md: 6, lg: 6, xl: 6 }}
          sx={{
            padding: {
              xl: "0rem 3rem",
              lg: "0rem 3rem",
              md: "0rem 3rem",
              sm: "0rem 0rem",
              xs: "0rem 0rem",
            },
          }}
        >
          {ReportBoxtitle?.map((item, index) => (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={index}>
              <Box
                sx={{
                  boxShadow: Colors.boxshadow[100],
                  padding: "2rem 2rem",
                  borderRadius: "0.5rem",
                  background: Colors.white[400],
                  width: "20rem",
                  border: "1px solid #f2f2f2",
                  boxshadow: Colors.boxshadow[500],
                  backgroundColor: Colors.primary[100],
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      border: "8px solid rgba(254, 238, 236, 0.5)",
                      color: Colors.white[100],
                      backgroundColor: Colors.grey[200],
                      marginBottom: "2rem",
                      width: "4rem",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleShowModal(item.name)}
                  >
                    <GoPlus style={{ fontSize: "3rem" }} />
                  </Box>
                  <DownloadPdfbutton
                    sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    onClick={() =>
                      dispatch({
                        type: DOWNLOAD_FORMAT_EXCEL,
                        payload: item.sample_excel,
                      })
                    }
                  >
                    <TbFileDownload fontSize="1.2rem" /> Excel Format
                  </DownloadPdfbutton>
                </Box>
                <CustomStyledBox>{item.name}</CustomStyledBox>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <Editbutton
                    onClick={() => {
                      handleShow("", item.name);
                      setmodalTypeName(item.name);
                      setCurrentPage(1);
                    }}
                  >
                    <FiEdit style={{ fontSize: "1.5rem" }} />
                    Edit
                  </Editbutton>
                  <SystemdataButton component="label" variant="contained">
                    <FiUpload style={{ fontSize: "1.5rem" }} /> Upload
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleFileInputChange(item.handleFileChange, event)
                      }
                    />
                  </SystemdataButton>
                </Box>
              </Box>
            </Grid>
          ))}
          <Datamapping />
          <Warehouse open={showWarehouseModal} handleClose={handleCloseModal} />
          <Product open={showProductModal} handleClose={handleCloseModal} />
          <Localstock
            open={showLocalstockModal}
            handleClose={handleCloseModal}
          />
        </Grid>
      </Box>
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default SystemDataMapping;
