import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { Box, Typography, TextField, styled, Button } from "@mui/material";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import stockServices from "../../services/stock.services";
import { tokens } from "../../theme";
import { useDispatch } from "react-redux";
import { FETCH_LOCAL_REQUEST } from "../../redux/stock/stockAction";

const Colors = tokens();

const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],
});

const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});

const EditLocalstock = ({ handleClose, open, formValues }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(formValues || defaultFormData);
  const defaultProduct = {
    product_name: "",
    product_price: "",
  };

  const defaultFormData = {
    product: defaultProduct,
    cartoon_pieces: "",
    cartoon_weight: "",
    total_pieces: "",
    purchased_date: new Date(),
  };

  useEffect(() => {
    setFormData(formValues || defaultFormData);
  }, [formValues]);

  const formatDateTime = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "product_name" || name === "product_price") {
      setFormData({
        ...formData,
        product: {
          ...formData.product,
          [name]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdateSave = async () => {
    try {
      const data = await stockServices.localStockupdate(formData.id, formData);
      if (data.result === "success") {
        showSuccessAlert("Localstock successfully updated");
        handleClose();
        dispatch({ type: FETCH_LOCAL_REQUEST });
      }
    } catch (error) {
      showErrorAlert(
        error.response?.data?.data.message ||
          "An error occurred while editing Localstock"
      );
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "590px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            padding: "1rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" mb={2}>
              Edit Localstock
            </Typography>
            <IoMdClose
              size={"25px"}
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
          <hr />
          <form style={{ marginLeft: "5rem" }} onSubmit={handleUpdateSave}>
            <FormRow>
              <Label htmlFor="product_name">Product Name</Label>
              <TextField
                type="text"
                id="product_name"
                name="product_name"
                value={formData.product?.product_name || ""}
                onChange={handleInputChange}
                size="small"
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <FormRow>
              <Label htmlFor="product_price">Product Price</Label>
              <TextField
                type="text"
                id="product_price"
                name="product_price"
                value={formData.product?.product_price || ""}
                onChange={handleInputChange}
                size="small"
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <FormRow>
              <Label htmlFor="cartoon_pieces">cartoon Pieces</Label>
              <TextField
                type="text"
                id="cartoon_pieces"
                name="cartoon_pieces"
                value={formData.cartoon_pieces}
                size="small"
                onChange={handleInputChange}
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <FormRow>
              <Label htmlFor="cartoon_weight">cartoon weight</Label>
              <TextField
                type="text"
                id="cartoon_weight"
                name="cartoon_weight"
                value={formData.cartoon_weight}
                size="small"
                onChange={handleInputChange}
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <FormRow>
              <Label htmlFor="cartoon_height">cartoon height</Label>
              <TextField
                type="text"
                id="cartoon_height"
                name="cartoon_height"
                value={formData.cartoon_height}
                size="small"
                onChange={handleInputChange}
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <FormRow>
              <Label htmlFor="cartoon_width">cartoon Width</Label>
              <TextField
                type="text"
                id="cartoon_width"
                name="cartoon_width"
                value={formData.cartoon_width}
                size="small"
                onChange={handleInputChange}
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <FormRow>
              <Label htmlFor="total_pieces">Total pieces</Label>
              <TextField
                type="text"
                id="total_pieces"
                name="total_pieces"
                value={formData.total_pieces}
                size="small"
                onChange={handleInputChange}
                sx={{
                  width: "22rem",
                  border: "0.5px solid black",
                  borderRadius: "8px",
                }}
              />
            </FormRow>
            <CustomStyledButton onClick={handleUpdateSave}>
              Edit
            </CustomStyledButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditLocalstock;
