  import axios from "axios";
  import { Limit, PORT } from "../config/config";
  const stockApi = "/api/stocks/";
  const warehouse = stockApi + "warehouse/";
  const product = stockApi + "product/";
  const localApiurl = stockApi + "local/";
  const fbaApiurl = stockApi + "fba/";
  const sellApiurl = "/api/sales/";
  //warehouse api
  const Uploadwarehouse = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + warehouse,
        formData,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  const fetchdatawareHouse = async (currentPage, itemsPerPage) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.get(
        `http://${domainName?.value}.${PORT}` + warehouse,
        {
          headers,
          params: {
            limit: itemsPerPage,
            offset: (currentPage - 1) * itemsPerPage,
          },
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  const createwareHouse = async (data) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));

    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + warehouse,
        data,
        {
          headers,
        }
      );
      if (response.data) {
        return { data: response.data, status: response.status };
      }
    } catch (error) {
      throw error;
    }
  };
  const wareHousedelete = async (id) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.delete(
        `http://${domainName?.value}.${PORT}` + warehouse + id,
        {
          headers: headers,
        }
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  const warehouesupdate = async (id, data) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.patch(
        `http://${domainName?.value}.${PORT}` + warehouse + id + "/",
        data,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  //upload product
  const productupdate = async (id, data) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.patch(
        `http://${domainName?.value}.${PORT}` + product + id + "/",
        data,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const Uploadproduct = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + product,
        formData,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  const fetchdataproduct = async (currentPage, itemsPerPage) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.get(
        `http://${domainName?.value}.${PORT}` + product,
        {
          headers,
          params: {
            limit: itemsPerPage,
            offset: (currentPage - 1) * itemsPerPage,
          },
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
  const createproduct = async (data) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));

    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + product,
        data,
        {
          headers,
        }
      );
      if (response.data) {
        return { data: response.data, status: response.status };
      }
    } catch (error) {
      throw error;
    }
  };
  const productdelete = async (id) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.delete(
        `http://${domainName?.value}.${PORT}` + product + id,
        {
          headers: headers,
        }
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  // single localstock
  const createLocalStock = async (data) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));

    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + localApiurl,
        data,
        {
          headers,
        }
      );
      if (response.data) {
        return { data: response.data, status: response.status };
      }
    } catch (error) {
      throw error;
    }
  };
  const UploadLocalstock = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}` + localApiurl,
      formData,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  };

  const fetchLocalstock = async (itemsPerPage, currentPage) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.get(
        `http://${domainName?.value}.${PORT}` + localApiurl,
        {
          headers,
          params: {
            limit: itemsPerPage,
            offset: (currentPage - 1) * itemsPerPage,
          },
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  //local stock api
  const Uploadlocalstock = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + localApiurl,
        formData,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const localStockupdate = async (id, data) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.patch(
        `http://${domainName?.value}.${PORT}` + localApiurl + id + "/",
        data,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const localStockdelete = async (uuids, deletetype) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));

    let params = {
      uuids: deletetype === "singledelete" ? uuids : uuids.join(","),
    };

    try {
      const response = await axios.delete(
        `http://${domainName?.value}.${PORT}${localApiurl}delete-multiple/`,
        {
          headers: headers,
          params: params,
        }
      );

      if (response) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  //FBA stock api
  const Uploadlocalfba = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("force", true);
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + fbaApiurl,
        formData,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const fbaStock = async (itemsPerPage, currentPage) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    try {
      const response = await axios.get(
        `http://${domainName?.value}.${PORT}` + fbaApiurl,
        {
          headers,
          params: {
            limit: itemsPerPage,
            offset: (currentPage - 1) * itemsPerPage,
          },
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  //Sell stock api
  const Uploadsell = async (file) => {
    const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `http://${domainName?.value}.${PORT}` + sellApiurl,
        formData,
        {
          headers,
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  //warehouse filter api
  const filterFba = async (fc_code, sku, disposition,fba_date) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    const apiUrl = `http://${domainName?.value}.${PORT}${fbaApiurl}`;
    let queryParams = "";
    if (fba_date){
      queryParams += `fba_date=${fba_date}&`;
    }
    if (fc_code?.length > 0) {
      queryParams += `fc_code=${fc_code.join(",")}&`;
    }
    if (sku?.length > 0) {
      queryParams += `sku=${sku.join(",")}&`;
    }
    if (disposition?.length > 0) {
      queryParams += `disposition=${disposition.join(",")}&`;
    }
    try {
      const response = await axios.get(`${apiUrl}?${queryParams}`, { headers });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  export default {
    Uploadwarehouse,
    UploadLocalstock,
    Uploadproduct,
    Uploadlocalstock,
    Uploadlocalfba,
    fbaStock,
    localStockupdate,
    localStockdelete,
    Uploadsell,
    fetchdatawareHouse,
    fetchdataproduct,
    fetchLocalstock,
    wareHousedelete,
    productdelete,
    createwareHouse,
    createproduct,
    createLocalStock,
    warehouesupdate,
    productupdate,
    filterFba,
  };
