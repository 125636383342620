import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  FormControlLabel,
  styled,
  TableCell,
  tableCellClasses,
  Checkbox,
  Tooltip,
  Typography,
} from "@mui/material";
import { tokens } from "../../theme";
import { useUIContext } from "../../context/Basicproviders";
import { FcInfo } from "react-icons/fc";
import {
  DownloadPdfbutton,
  TableContainerStyled,
  UploadBtnStyle,
} from "../../Style/Common.style";
import Searchbar from "../globel/Searchbar";
import { DownloadPdf, BpCheckbox, SellIn } from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import Bottombar from "../globel/Bottombar";
import { useTheme } from "@emotion/react";
import Loader from "../globel/Loader";
import { showErrorAlert } from "../../toast/toast";
import { TbFileDownload } from "react-icons/tb";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import {
  DATE_SHIPMENT_FILTER,
  EXPORT_PROCESS,
  FETCH_SHIPMNET_REQUEST,
  SHIPMENT_FILTER,
} from "../../redux/shipment/shipmentAction";
import { useShipmentMaster } from "../../redux/shipment/shipmentReducer";
import { DOWNLOAD_FORMAT_EXCEL } from "../../redux/warehouse/warehouseAction";
import { useStockMaster } from "../../redux/stock/stockReducer";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { SKU_FILTER, STATE_FILTER } from "../../redux/filter/filterAction";
import { useFilterMaster } from "../../redux/filter/filterReducer";
const { RangePicker } = DatePicker;
const ShipmentAlert = () => {
  const theme = useTheme();
  const Colors = tokens(theme.palette.mode);
  const StyledTableCell = styled(TableCell)(({ theme, index }) => {
    return {
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: Colors.black[400],
        color: Colors.header_fontcolor[100],
        fontSize: "1rem",
        position: "sticky",
        top: 0,
        zIndex: 1000,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: "0.9rem",
        fontWeight: 500,
        color: Colors.black_font[100],
        padding: "1rem 1rem",
        textTransform: "uppercase",
        backgroundColor: index ? index : `${Colors.white[400]}`,
      },
    };
  });

  const {
    itemsPerPage,
    currentPage,
    setCurrentPage,
    selectAll,
    setSelectAll,
    setCheckedRows,
    checkedRows,
    searchValue,
    shipmentDic,
    setshipmentdic,
    selectedDomain,
    setSearchvalue,
  } = useUIContext();
  const dispatch = useDispatch();
  const [selectedColor, setSelectedColor] = useState("none");
  const [selectedSku, setSelectedSku] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectDate, setselectedDate] = useState([]);
  const { processData } = useShipmentMaster();
  const { uniqueState, uniqueSku } = useFilterMaster();
  const { indicater } = useStockMaster();
  const navigate = useNavigate();
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const fetchData = () => {
    dispatch({
      type: FETCH_SHIPMNET_REQUEST,
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
  };
  const handlechangeDate = (dates) => {
    setselectedDate(dates);
  };

  const handleFilterChange = (selectedOptions, type) => {
    let selectedstate, setterFunction;
    switch (type) {
      case "sku":
        selectedstate = selectedSku;
        setterFunction = setSelectedSku;
        break;
      case "fccode":
        selectedstate = selectedState;
        setterFunction = setSelectedState;
        break;
      default:
        return;
    }
    setterFunction(selectedOptions.map((option) => option.value));
  };

  const handleFilter = () => {
    let startDate;
    let endDate;
    if (selectDate === null) {
      startDate = null;
      endDate = null;
    }
    startDate = selectDate?.[0]?.format("YYYY-MM-DD");
    endDate = selectDate?.[1]?.format("YYYY-MM-DD");
    dispatch({
      type: DATE_SHIPMENT_FILTER,
      startDate: startDate,
      endDate: endDate,
      ship_to_state: selectedState,
      sku: selectedSku,
      shipment_color: selectedColor,
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
    dispatch({
      type: STATE_FILTER,
    });
    dispatch({
      type: SKU_FILTER,
    });
  };

  useEffect(() => {
    if (selectedDomain !== null) {
      if (
        (selectDate && selectDate.length > 0) ||
        selectedColor ||
        selectedSku.length > 0 ||
        selectedState.length > 0
      ) {
        handleFilter();
      } else if (selectedColor === "none") {
        fetchData();
      }
    }
  }, [
    selectedSku,
    selectedState,
    selectDate,
    selectedColor,
    selectedDomain,
    currentPage,
    itemsPerPage,
  ]);

  const handleCheckboxChange = (row) => {
    const isChecked = checkedRows.includes(row.id);
    const updatedCheckedRows = isChecked
      ? checkedRows.filter((checkedRow) => checkedRow !== row.id)
      : [...checkedRows, row.id];
    setCheckedRows(updatedCheckedRows);
    setSelectAll(updatedCheckedRows.length === processData?.length);
    isChecked
      ? setshipmentdic((prevdata) =>
          prevdata.filter((item) => item.id !== row.id)
        )
      : setshipmentdic((prevSelectedRows) => [
          ...prevSelectedRows,
          { ...row, processed_quantity: row.suggested_quantity },
        ]);
  };
  const handleSelectAllChange = () => {
    const allChecked = !selectAll;
    setSelectAll(allChecked);
    setCheckedRows(allChecked ? processData?.results.map((row) => row.id) : []);
    if (allChecked) {
      setshipmentdic(processData?.results);
    } else {
      setshipmentdic([]);
    }
  };
  const moveProcessdata = () => {
    if (checkedRows.length === 0) {
      showErrorAlert("Please select rows data");
    } else {
      dispatch({
        type: EXPORT_PROCESS,
        payload: shipmentDic,
        href: navigate,
      });      
      setshipmentdic([]);
      setCheckedRows([]);
    }
  };

  const handleColorChange = (selectedOption) => {
    setSelectedColor(selectedOption.value);
  };

  useEffect(() => {
    if (searchValue) {
      if (searchValue) {
        const filterdata = processData?.results?.filter(
          (item) =>
            item?.sku &&
            item?.sku
              .toLowerCase()
              .includes(searchValue ? searchValue.toLowerCase() : "")
        );
        dispatch({ type: SHIPMENT_FILTER, payload: filterdata });
      } else {
        handleFilter();
        setCheckedRows([]);
        setSelectAll(false);
        setshipmentdic([]);
      }
    }
  }, [searchValue, currentPage, itemsPerPage]);

  useEffect(() => {
    setSearchvalue("");
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #2684ff",
      borderRadius: "5px",
      boxShadow: state.isFocused ? "0 0 0 1px #2684ff" : "none",
      height: "10px",
      overflow: "auto",
      "&:hover": {
        borderColor: "#2684ff",
      },
    }),
  };
  const colorOptions = [
    { value: "%23F7E3E3", label: "Alert", color: "red" },
    { value: "%23FFFAEB", label: "Warning", color: "yellow" },
    { value: "none", label: "None" },
  ];
  return (
    <>
      {indicater && <Loader />}
      <Box
        sx={{
          height: "78vh",
          overflowY: "auto",
          padding: "0rem 2rem 0rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Searchbar placeholder="Search sku" />

          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ width: "9rem" }}>
              <Select
                value={colorOptions.find(
                  (option) => option.value === selectedColor
                )}
                onChange={handleColorChange}
                options={colorOptions}
                isSearchable={false}
                defaultValue={{ value: "none", label: "None" }}
              />
            </Box>
            <RangePicker
              onChange={handlechangeDate}
              renderExtraFooter={() => "extra footer"}
              style={{
                height: "40px",
                width: "280px",
                border: "1px solid #7e7e7e",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Select
              isMulti
              name="fccode"
              options={uniqueState?.map((product) => ({
                value: product,
                label: product,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectedState?.map((product) => ({
                value: product,
                label: product,
              }))}
              onChange={(event) => handleFilterChange(event, "fccode")}
              styles={customStyles}
              placeholder="Select State"
            />
            <Box sx={{ display: "flex", gap: 1 }}>
              <Select
                isMulti
                name="Sku"
                options={uniqueSku?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedSku?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                onChange={(event) => handleFilterChange(event, "sku")}
                styles={customStyles}
                placeholder="Select Sku"
              />
            </Box>
            <SellIn />
            <DownloadPdf data={processData} filename="shipmentAlert" />
            <DownloadPdfbutton
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
              onClick={() =>
                dispatch({
                  type: DOWNLOAD_FORMAT_EXCEL,
                  payload: "sample_sales",
                })
              }
            >
              <TbFileDownload fontSize="1.2rem" /> Excel Format
            </DownloadPdfbutton>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "2rem",
            height: "65vh",
            position: "sticky",
          }}
        >
          <TableContainerStyled>
            <Table aria-label="customized table">
              <TableHead>
                {processData?.display_names && processData?.display_names && (
                  <TableRow>
                    <StyledTableCell align="center">
                      <FormControlLabel
                        control={
                          <BpCheckbox
                            checked={selectAll}
                            indeterminate={!selectAll && checkedRows.length > 0}
                            onChange={handleSelectAllChange}
                          />
                        }
                        label="ID"
                      />
                    </StyledTableCell>
                    {Object.entries(processData?.display_names).map(
                      ([key, value], index) => (
                        <StyledTableCell
                          sx={{ textAlign: "center" }}
                          key={index}
                        >
                          {value}
                        </StyledTableCell>
                      )
                    )}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {processData?.results && processData?.results.length > 0 ? (
                  processData?.results?.map((row, index) => (
                    <TableRow key={row.id}>
                      <StyledTableCell
                        align="center"
                        index={row.shipment_color}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkedRows.includes(row.id)}
                              onChange={() => handleCheckboxChange(row)}
                            />
                          }
                        />
                      </StyledTableCell>
                      {Object.keys(processData.display_names).map((key) => (
                        <StyledTableCell
                          key={key}
                          align="center"
                          index={row.shipment_color}
                        >
                          {key === "fba_warehouse_available_balance" ? (
                            <Tooltip
                              title={
                                <React.Fragment>
                                  {Object.entries(row.fc_code_balance_dict).map(
                                    ([key, value]) => (
                                      <Typography key={key}>
                                        <strong>{key}:</strong> {value}
                                      </Typography>
                                    )
                                  )}
                                </React.Fragment>
                              }
                            >
                              <span>
                                {row.fba_warehouse_available_balance}
                                <FcInfo
                                  style={{
                                    fontSize: "1.2rem",
                                    marginLeft: "1rem",
                                  }}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            row[key]
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{ textAlign: "center" }}>
                    <TableCell
                      align="center"
                      colSpan={
                        Object.keys(processData?.display_names || {}).length + 1
                      }
                    >
                      NO ROW DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainerStyled>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <UploadBtnStyle
            sx={{ padding: "0.5rem 3rem" }}
            onClick={moveProcessdata}
          >
            Process
          </UploadBtnStyle>
        </Box>
      </Box>
      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(processData?.count / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default ShipmentAlert;
