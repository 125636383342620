import { useSelector } from "react-redux";
import { WAREHOUSE_DATA,PRODUCT_DATA } from "./warehouseAction";

const initialstate = { wareHouse: {},Product:{}};
const warehouseReducer = (state = initialstate, action) => {
  switch (action.type) {
    case WAREHOUSE_DATA:
      return {
        ...state,
        wareHouse: action.payload,
      };
      case PRODUCT_DATA:
        return {
          ...state,
          Product: action.payload,
        };
    default:
      return state;
  }
};

export default warehouseReducer;

export function useWarehouseMaster() {
  return useSelector((state) => state.warehouse);
}
