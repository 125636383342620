import { useSelector } from "react-redux";
import {
  FAILED_LOAD_REQUEST,
  FBA_DATA,
  FBA_SEARCH_FILTER,
  FETCH_LOCAL_REQUEST_FAILED,
  FETCH_MISSINGSKU,
  LOCAL_DATA,
  LOCAL_FILTER,
  UNIQUE_FBA_KEY,
  UPLOAD_LOAD_REQUEST,
} from "./stockAction";

const initialState = {
  localStock: {},
  fbaStock: {},
  uniqueKey: {},
  indicater: false,
  fbaMissingSku: [],
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCAL_DATA:
      return {
        ...state,
        localStock: action.payload,
      };
    case UNIQUE_FBA_KEY:
      return {
        ...state,
        uniqueKey: action.payload,
      };
    case UPLOAD_LOAD_REQUEST:
      return {
        indicater: true,
      };
    case FAILED_LOAD_REQUEST:
      return {
        indicater: false,
      };
    case FBA_DATA:
      return {
        ...state,
        fbaStock: action.payload,
      };
    case FETCH_LOCAL_REQUEST_FAILED:
      return {
        localStock: {},
      };
    case LOCAL_FILTER:
      return {
        ...state,
        localStock: {
          ...state.localStock,
          results: action.payload,
        },
      };
    case FBA_SEARCH_FILTER:
      return {
        ...state,
        fbaStock: {
          ...state.fbaStock,
          results: action.payload,
        },
      };
    case FETCH_MISSINGSKU:
      return {
        ...state,
        fbaMissingSku: action.payload?.missing_skus,
        indicater: false,
      };
    default:
      return state;
  }
};

export default stockReducer;
export function useStockMaster() {
  return useSelector((state) => state.stock);
}
