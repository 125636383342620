import React, { useState } from "react";
import {
  Box,
  Typography,
  styled,
  TableRow,
  Table,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Switch,
} from "@mui/material";
import { tokens } from "../../theme";
import { FaFileImport } from "react-icons/fa";
import {
  ReceiveformButton,
  StyledTableCell1,
  SpecialTextField,
} from "../../Style/Common.style";
import { Modal } from "react-bootstrap";

const Colors = tokens();

const Label = styled("label")({
  marginBottom: "0.5rem",
  fontSize: "1.2rem",
  fontWeight: 500,
  width: "40%",
});

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Receiveform = ({
  receiveform,
  handleClose,
  handleInputChange,
  moveReceived,
  handleErrordata,
  errordata,
  showShipmentError,
  setShowShipmentError,
  shipmentDic,
  setshipmentdic,
  seterrordata,
  selectedErrorType,
  setSelectedErrorType
}) => {

  const handleSwitchChange = (index, row) => {
    setShowShipmentError((prevErrors) => {
      const newErrorState = !prevErrors[row.id];

      setshipmentdic((prevShipments) =>
        prevShipments.map((item) => {
          if (item.id === row.id) {
            return {
              ...item,
              error: newErrorState,
            };
          }
          return item;
        })
      );

      seterrordata((prevData) => {
        const existingErrorData = prevData.find(
          (item) => item.shipment_id === row.id
        );        

        if (existingErrorData) {
          return prevData.map((item) =>
            item.shipment_id === row.id
              ? {
                  ...item,
                  shipment_number: row.shipment_number,
                  shipment_id: row.id,
                  sku: row.sku,
                  fc_code: row.fc_code,
                  error_quantity: row.error_quantity,
                }
              : item
          );
        } else {
          return [
            ...prevData,
            {
              shipment_number: row.shipment_number,
              shipment_id: row.id,
              sku: row.sku,
              fc_code: row.fc_code,
              error_quantity: row.error_quantity,
            },
          ];
        }
      });

      return {
        ...prevErrors,
        [row.id]: newErrorState,
      };
    });
  };

  const handleErrorTypeChange = (row, value) => {
    setSelectedErrorType((prevState) => ({
      ...prevState,
      [row.id]: value,
    }));
  };

  return (
    <>
      <Modal show={receiveform} onHide={handleClose}>
        <Box
          sx={{
            width: "38vw",
            backgroundColor: "#fff",
            height: "43rem",
            overflow: "auto",
            justifyContent: "center",
            left: "50%",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Receive Form</Modal.Title>
          </Modal.Header>
          <Box sx={{ padding: " 2rem 5rem " }}>
            {shipmentDic && shipmentDic.length > 0
              ? shipmentDic.map((row, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        padding: "1rem",
                        border: "1px solid #ccc",
                        borderRadius: "0.5rem",
                        margin: "1rem",
                        backgroundColor: "#FDFDFD",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color: Colors.black_font[200],
                          marginBottom: "1rem",
                          backgroundColor: "#F1F4F8",
                          borderRadius: "50rem",
                          width: "3rem",
                          padding: "0.5rem 1rem",
                          height: "3rem",
                        }}
                      >
                        {index + 1}
                      </Typography>
                      <Table>
                        <TableRow>
                          <StyledTableCell1>
                            <Label>Sku</Label>
                            <SpecialTextField
                              size="small"
                              disabled
                              id="outlined-disabled"
                              value={row.sku}
                            />
                          </StyledTableCell1>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell1>
                            <Label>Fc Code</Label>
                            <SpecialTextField
                              size="small"
                              disabled
                              id="outlined-disabled"
                              value={row.fc_code}
                            />
                          </StyledTableCell1>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell1>
                            <Label>Shipment Number</Label>
                            <SpecialTextField
                              size="small"
                              disabled
                              id="outlined-disabled"
                              value={row.shipment_number}
                            />
                          </StyledTableCell1>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell1>
                            <Label>Ship Qty</Label>
                            <SpecialTextField
                              size="small"
                              disabled
                              id="outlined-disabled"
                              value={row.shipped_quantity}
                            />
                          </StyledTableCell1>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell1>
                            <Label>Create Error</Label>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Android12Switch
                                checked={showShipmentError[row.id] || false}
                                onChange={() => handleSwitchChange(index, row)}
                              />
                            </Box>
                          </StyledTableCell1>
                        </TableRow>

                        {showShipmentError[row.id] && (
                          <>
                            <TableRow>
                              <StyledTableCell1>
                                <Label>Shipment Error</Label>
                                <FormControl sx={{ width: "7rem" }}>
                                  <InputLabel id="demo-simple-select-label">
                                    Error type
                                  </InputLabel>
                                  <Select
                                    id="demo-simple-select"
                                    name="error_data_type"
                                    label="Wrong shipment"
                                    value={selectedErrorType[row.id] || ""}
                                    onChange={(e) => {
                                      handleErrordata(
                                        row,
                                        e.target.name,
                                        e.target.value
                                      );
                                      handleErrorTypeChange(
                                        row,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="seller_data">
                                      Seller
                                    </MenuItem>
                                    <MenuItem value="amazon_data">
                                      Amazon
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </StyledTableCell1>
                            </TableRow>
                            {selectedErrorType[row.id] === "amazon_data" && (
                              <>
                                <TableRow>
                                  <StyledTableCell1>
                                    <Label>Claim Name</Label>
                                    <SpecialTextField
                                      id="Claimname"
                                      label="Claim name"
                                      name="claim_name"
                                      variant="outlined"
                                      size="small"
                                      placeholder="Claim name"
                                      // style={{border:"1px solid red",  backgroundColor: "#F1F4F8",
                                      //   borderRadius: "0.2rem",
                                      //   color: "#000000",
                                      //   padding: "0.5rem",
                                      // }}
                                      onChange={(e) =>
                                        handleErrordata(
                                          row,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </StyledTableCell1>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell1>
                                    <Label>Claim ID</Label>
                                    <SpecialTextField
                                      id="Claimname"
                                      label="Claim id"
                                      name="claim_id"
                                      variant="outlined"
                                      size="small"
                                      placeholder="Claim id"
                                      // style={{border:"1px solid red",  backgroundColor: "#F1F4F8",
                                      //   borderRadius: "0.2rem",
                                      //   color: "#000000",
                                      //   padding: "0.5rem",
                                      // }}
                                      onChange={(e) =>
                                        handleErrordata(
                                          row,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </StyledTableCell1>
                                </TableRow>
                              </>
                            )}

                            {selectedErrorType[row.id] &&
                            (selectedErrorType[row.id] === "seller_data" ||
                              selectedErrorType[row.id] === "amazon_data") ? (
                              <TableRow>
                                <StyledTableCell1>
                                  <Label>Notes</Label>
                                  <SpecialTextField
                                    id="Notes"
                                    label="Notes"
                                    variant="outlined"
                                    size="small"
                                    name="notes"
                                    placeholder="notes"
                                    // style={{border:"1px solid red",  backgroundColor: "#F1F4F8",
                                    //   borderRadius: "0.2rem",
                                    //   color: "#000000",
                                    //   padding: "0.5rem",
                                    // }}
                                    onChange={(e) =>
                                      handleErrordata(
                                        row,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  required
                                  />
                                </StyledTableCell1>
                              </TableRow>
                            ) : null}
                          </>
                        )}

                        <TableRow>
                          <StyledTableCell1>
                            <Label>Received Date</Label>
                            <SpecialTextField
                              type="date"
                              id="Received Date"
                              size="small"
                              variant="outlined"
                              value={row.shipment_received_date || ""}
                              name="shipment_received_date"
                              sx={{ width: "14rem" }}
                              onChange={(e) =>
                                handleInputChange(
                                  row,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              error={!row.shipment_received_date}
                              helperText={
                                !row.shipment_received_date &&
                                "Received Date is required"
                              }
                              disabled={!!row.entry_received_data}
                            />
                          </StyledTableCell1>
                        </TableRow>
                        <TableRow>
                        <StyledTableCell1>
                            <Label>Error Qty</Label>
                            <SpecialTextField
                              id="Error Qty"
                              size="small"
                              variant="outlined"
                              name="error_quantity"
                              value={row.error_quantity || 0}
                              disabled={!showShipmentError[row.id]}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value);
                                handleInputChange(row, e.target.name, newValue);
                                seterrordata((prevData) =>
                                  prevData.map((item) =>
                                    item.id === row.id
                                      ? {
                                          ...item,
                                          "error_quantity": newValue,
                                        }
                                      : item
                                  )
                                );
                              }}
                            />
                          </StyledTableCell1>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell1>
                            <Label>Received Qty</Label>
                            <SpecialTextField
                              id="Received Qty"
                              size="small"
                              variant="outlined"
                              name="received_quantity"
                              value={row.received_quantity || 0}
                              disabled={showShipmentError[row.id]}
                              onChange={(e) =>
                                handleInputChange(
                                  row,
                                  e.target.name,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </StyledTableCell1>
                        </TableRow>
                      </Table>
                    </Box>
                  );
                })
              : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2rem",
            }}
          >
            <ReceiveformButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={moveReceived}
            >
              <FaFileImport /> Submit
            </ReceiveformButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Receiveform;
