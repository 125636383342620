import { all, call, put, takeLatest } from "redux-saga/effects";
import userServices from "../../services/user.services";
import {
  AUTH_FAILED,
  AUTH_REQUEST,
  CREATE_USER,
  FETCH_USERDATA,
  USERDATA_REQUEST,
} from "./authAction";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
function* loginSaga(action) {
  try {
    const { data } = yield call(userServices.loginuser, action.payload);
    localStorage.setItem("token", data?.data?.access_token);
    window.location.replace("/dashboard");
  } catch (error) {
    yield put({ type: AUTH_FAILED, payload: error?.response?.data?.data });
  }
}

function* loginUser(action) {
  try {
    const { data } = yield call(userServices.loginuser);
  } catch (error) {
    console.log(error);
  }
}
function* fetchUserdata() {
  try {
    const { data } = yield call(userServices.getusers);
    yield put({ type: FETCH_USERDATA, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* createNewUser(action) {
  try {
    const { data } = yield call(userServices.createuser, action.payload);
    showSuccessAlert(data?.data?.message);
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* authSaga() {
  yield all([
    takeLatest(AUTH_REQUEST, loginSaga),
    takeLatest(USERDATA_REQUEST, fetchUserdata),
    takeLatest(CREATE_USER, createNewUser),
  ]);
}

export default authSaga;
