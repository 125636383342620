import { all, call, put, takeLatest } from "redux-saga/effects";
import { showErrorAlert } from "../../toast/toast";
import filterServices from "../../services/filter.services";
import {
  DISPOSITION_FILTER,
  FCCODE_FILTER,
  FETCH_DISPOSITION_FILTER,
  FETCH_FCCODE_FILTER,
  FETCH_SKU_FILTER,
  FETCH_STATE_FILTER,
  SKU_FILTER,
  STATE_FILTER,
} from "./filterAction";

function* fetchSku() {
  try {
    const { data } = yield call(filterServices.fetchSku);
    yield put({ type: FETCH_SKU_FILTER, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* fetchFccode() {
  try {
    const { data } = yield call(filterServices.fetchFccode);
    yield put({ type: FETCH_FCCODE_FILTER, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* fetchDisposition() {
  try {
    const { data } = yield call(filterServices.fetchDisposition);
    yield put({ type: FETCH_DISPOSITION_FILTER, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* fetchState() {
  try {
    const { data } = yield call(filterServices.fetchState);
    yield put({ type: FETCH_STATE_FILTER, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* filterSaga() {
  yield all([
    takeLatest(SKU_FILTER, fetchSku),
    takeLatest(FCCODE_FILTER, fetchFccode),
    takeLatest(DISPOSITION_FILTER, fetchDisposition),
    takeLatest(STATE_FILTER, fetchState),
  ]);
}
export default filterSaga;
