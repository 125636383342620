import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  styled,
  Modal,
  Alert,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import userServices from "../../services/user.services";
import { showSuccessAlert } from "../../toast/toast";

const Colors = tokens();

const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],

});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});

const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize:"1rem",
  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});
const Resetpassword = ({ open, handleClose, setpassworderror, passwordError, userId }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleChangepassword = (values) => {
    setpassworderror('');
    try {
      userServices
        .resetPassword({
          password: values.password,
        }, userId)
        .then(({ data, status }) => {
          if (data.result === "success") {
            showSuccessAlert("User password has been change")
            handleClose(setpassworderror);
          }
        })
        .catch((error) => setpassworderror(error.response.data));
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "590px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "1rem",
          }}
        >
          <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" >
              Reset password
            </Typography>
            <IoMdClose
              size={"25px"}
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
          <hr/>
          {passwordError && <Alert severity="error" sx={{ marginBottom: "1rem" }}>{passwordError}</Alert>}
          <Formik
            onSubmit={handleChangepassword}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form style={{ marginLeft: "5rem" }}>
                <FormRow>
                  <Label htmlFor="NewPassword">New Password </Label>
                  <TextField
                    type={showNewPassword ? "text" : "password"}
                    id="NewPassword"
                    name="password"
                    size="small"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={handleToggleNewPassword}
                            sx={{ color: Colors.black[100] }}
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "22rem",
                    }}
                  />
                </FormRow>

                <FormRow>
                  <Label htmlFor="ConfirmPassword">Confirm Password</Label>
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    id="ConfirmPassword"
                    name="ConfirmPassword"
                    size="small"
                    value={values.ConfirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      !!touched.ConfirmPassword && !!errors.ConfirmPassword
                    }
                    helperText={
                      touched.ConfirmPassword && errors.ConfirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={handleToggleConfirmPassword}
                            sx={{ color: Colors.black[100] }}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "22rem",
                    }}
                  />
                </FormRow>
                <CustomStyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save Changes 
                </CustomStyledButton>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
const checkoutSchema = yup.object().shape({
  password: yup
    .string()
    .required("Required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one number and one special character"
    ),
  ConfirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const initialValues = {
  password: "",
  ConfirmPassword: "",
};

export default Resetpassword;
