import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import { CustomDashboardBox } from "../../Style/Common.style";
import { useUIContext } from "../../context/Basicproviders";
import { ToastContainer } from "react-toastify";
import Loader from "./Loader";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import WrongShipmentdata from "../modals/WrongShipmentdata";
import { showErrorAlert } from "../../toast/toast";
import { PiWarningLight } from "react-icons/pi";
import missingproductServices from "../../services/missingproduct.services";
import { SellIn } from "./Uploadstock";
import shipmenetServices from "../../services/shipmenet.services";

const Dashboard = () => {
  const {
    selectedDomain,
    loading,
    setLoading,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    setItemsPerPage
  } = useUIContext();
  const [wrongshipmentModal, setwrongshipmentModal] = useState(false);
  const [shipmentModalname, setshipmentModalname] = useState("");
  const [wrongShipment, setWrongshipment] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [wrongshipCount, setWrongshipCount] = useState(0);
  const [missingFccount, setMissingFccount] = useState(0);
  const [missingStateCount, setMissingstatecount] = useState(0);
  const [dispositionItem, setDispositionItem] = useState(0);
  const [missingSkucount, SetMissingSkucount] = useState(0);
  const [monthlySalesCount, setMonthlysalescount] = useState(0);
  const [shipmentErrorCount, setShipmentErrorCount] = useState(0);
  const [storageFeesCount, setStorageFeescount] = useState(0);
  const [lastUpdateStatus, setLastUpdateStatus] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [selectDate, setSelectDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [errorDate, setErrorDate] = useState({
    start_date: "",
    end_date: "",
  });

  const fetchLastUpdate = async () => {
    try {
      await missingproductServices
        .lastUploadSales()
        .then((response) => {
          setLastUpdateStatus(response?.data?.message);
        })
        .catch((error) => showErrorAlert(error));
    } catch (error) {
      showErrorAlert(error);
    }
  };
  const fetchData = async () => { 
       
    try {
      const data = await Promise.all([
        missingproductServices.wrongShipmentdataApi(itemsPerPage, currentPage),
        missingproductServices.missingFcProduct(itemsPerPage, currentPage),
        missingproductServices.missingSku(itemsPerPage, currentPage),
        missingproductServices.missingState(itemsPerPage, currentPage),
        missingproductServices.missingDisposition(itemsPerPage, currentPage),
        missingproductServices.missingMonthlysales(selectedDate),
        missingproductServices.storageFees(selectedDate),
        missingproductServices.overCharged(),
        shipmenetServices.getErrors(errorDate.start_date, errorDate.end_date)
      ]);      
  
      data.forEach((response, index) => {
        if (response.result === "success") {
          switch (index) {
            case 0:
              setWrongshipCount(response?.data?.count);
              break;
            case 1:
              setMissingFccount(response?.data?.count);
              break;
            case 2:
              SetMissingSkucount(response?.data?.count);
              break;
            case 3:
              setMissingstatecount(response?.data?.count);
              break;
            case 4:
              setDispositionItem(response?.data?.count);
              break;
            case 5:
              setMonthlysalescount(response?.data?.total_product_selling);
              break;
            case 6:
              setStorageFeescount(response?.data?.total_estimated_monthly_storage_fee);
              break;
            case 8:
              setShipmentErrorCount(response?.data?.count);
              break;
            case 7:
            default:
              break;
          }
        }
      });
    } catch (error) {
      showErrorAlert(error);
    }
  };

  const handleClosemodal = () => {
    setSelectDate({});
    setWrongshipment([]);
    setErrorDate({start_date:"",end_date:""})
    setItemsPerPage(10);
    // setErrorDate(errorDate.start_date, errorDate.end_date);
    setwrongshipmentModal(false);
    fetchData();
  };
  console.log("erro",errorDate);
  

useEffect(() => {
  if (selectedDomain !== null) {
    setLoading(false);
    fetchData();
    fetchLastUpdate();
  }
}, [selectedDomain]);

  const boxData = [
    { title: "Wrong Shipments", count: wrongshipCount },
    { title: "Missing FC", count: missingFccount },
    { title: "Missing SKU", count: missingSkucount },
    { title: "Missing State", count: missingStateCount },
    { title: "Disposition Items", count: dispositionItem },
    { title: "Monthly Sales", count: monthlySalesCount },
    { title: "Storage Fee", count: storageFeesCount },
    { title: "Over Charged", count: 0 },
    { title: "Shipment Errors", count:  shipmentErrorCount},
  ];  
  
  const fetchResult = async (title) => {
    try {
      let responseData;
      switch (title) {
        case "Wrong Shipments":
          responseData = await missingproductServices.wrongShipmentdataApi(
            itemsPerPage,
            currentPage
          );
          break;
        case "Missing FC":
          responseData = await missingproductServices.missingFcProduct(
            itemsPerPage,
            currentPage
          );
          break;
        case "Missing SKU":
          responseData = await missingproductServices.missingSku(
            itemsPerPage,
            currentPage
          );
          break;
        case "Missing State":
          responseData = await missingproductServices.missingState(
            itemsPerPage,
            currentPage
          );
          break;
        case "Disposition Items":
          responseData = await missingproductServices.missingDisposition(
            itemsPerPage,
            currentPage
          );
          break;
        case "Monthly Sales":
          responseData = await missingproductServices.missingMonthlysales(
            selectDate?.start_date,
            selectDate?.end_date
          );
          break;
        case "Storage Fee":
          responseData = await missingproductServices.storageFees(selectedDate);
          break;
        case "Over Charged":
          responseData = await missingproductServices.overCharged();
          break;
        case "Shipment Errors":
          responseData = await shipmenetServices.getErrors(
            errorDate?.start_date,
            errorDate?.end_date
          );
          break;
        default:
          break;
      }
      if (responseData?.result === "success") {
        setWrongshipment(responseData?.data);
      }
    } catch (error) {
      showErrorAlert(error);
    }
  };
  const dashbordOpenmodal = async (title) => {
    setCurrentPage(1);
    setwrongshipmentModal(true);
    setshipmentModalname(title);
    fetchResult(title);
  };
  useEffect(() => {
    fetchResult(shipmentModalname);
  }, [itemsPerPage, currentPage, selectedDate, selectDate, errorDate]);

  console.log("Last Uploaded Sales Date", lastUpdateStatus);
  
  return (
    <>
      {loading ? <Loader /> : null}
      <Container sx={{ height: "80vh", overflow: "auto " }}>
        <Box
          sx={{
            display: "flex",
            paddingTop: "2rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontWeight: 500, fontSize: "1.2rem", color:"#eb5e28", fontWeight:"bold" }}
          >
            {lastUpdateStatus}
          </Typography>
          <SellIn />
        </Box>
        <Grid container spacing={4} sx={{ paddingTop: "2rem" }}>
          {boxData.map((box, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <CustomDashboardBox onClick={() => dashbordOpenmodal(box.title)}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      borderRadius: "50%",
                      padding: "0.4rem",
                      background: "#344054",
                      color: "#fff",
                    }}
                  >
                    <PiWarningLight fontSize={25} />
                  </Typography>
                  {(box.title !== "Over Charged") && 
                  <Typography variant="h5">{box.count}</Typography>}
                </Box>
                <Typography
                  variant="h6"
                  sx={{ textTransform: "uppercase", paddingTop: "2rem" }}
                >
                  {box.title}
                </Typography>
              </CustomDashboardBox>
            </Grid>
          ))}
        </Grid>
      </Container>
      <WrongShipmentdata
        wrongshipmentModal={wrongshipmentModal}
        setwrongshipmentModal={setwrongshipmentModal}
        wrongShipment={wrongShipment}
        setWrongshipment={setWrongshipment}
        shipmentModalname={shipmentModalname}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        selectDate={selectDate}
        setSelectDate={setSelectDate}
        errorDate={errorDate}
        setErrorDate={setErrorDate}
        handleClosemodal={handleClosemodal}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default Dashboard;
