import {
  UPLOAD_WAREHOUSE_STOCK,
  UPLOAD_PRODUCT_STOCK,
  FETCH_WAREHOUSE_REQUEST,
  FETCH_PRODUCT_REQUEST,
} from "../redux/warehouse/warehouseAction";
import {
  FETCH_LOCAL_REQUEST,
  UPLOAD_LOCAL_STOCK,
} from "../redux/stock/stockAction";

const handleWarehouseUpload = (dispatch, file) => {
  dispatch({ type: UPLOAD_WAREHOUSE_STOCK, payload: file });
};
const handleproductUpload = (dispatch, file) => {
  dispatch({ type: UPLOAD_PRODUCT_STOCK, payload: file });
};
const handlelocalstockUpload = (dispatch, file) => {
  dispatch({ type: UPLOAD_LOCAL_STOCK, payload: file });
};

const fetchDataWarehouse = (dispatch, currentPage, itemsPerPage) => {
  dispatch({
    type: FETCH_WAREHOUSE_REQUEST,
    currentPage: currentPage,
    itemsPerPage: itemsPerPage,
  });
};

const fetchdataproduct = (dispatch, currentPage, itemsPerPage) => {
  dispatch({
    type: FETCH_PRODUCT_REQUEST,
    currentPage: currentPage,
    itemsPerPage: itemsPerPage,
  });
};
const fetchdatalocalstock = (dispatch, currentPage, itemsPerPage) => {
  dispatch({
    type: FETCH_LOCAL_REQUEST,
    currentPage: currentPage,
    itemsPerPage: itemsPerPage,
  });
};

export const ReportBoxtitle = [
  {
    id: 1,
    name: "Manage Warehouses",
    sample_excel: "sample_warehouse",
    handleFileChange: handleWarehouseUpload,
    fetchdata: fetchDataWarehouse,
  },
  {
    id: 2,
    name: "Manage Product",
    sample_excel: "sample_product",
    handleFileChange: handleproductUpload,
    fetchdata: fetchdataproduct,
  },
  {
    id: 3,
    name: "Manage Local",
    sample_excel: "sample_local",
    handleFileChange: handlelocalstockUpload,
    fetchdata: fetchdatalocalstock,
  },
];
