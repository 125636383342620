import { Box } from "@mui/material";
import React from "react";
import TitleHeader from "./TitleHeader";
import AdminProfile from "./AdminProfile";

const Topbar = ({ title }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" ,padding:"1rem 1.5rem 0rem",alignItems:"center"}}>
        <TitleHeader title={title} />
        <AdminProfile />
      </Box>
    </>
  );
};

export default Topbar;
