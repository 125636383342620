import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NavLink, useNavigate } from "react-router-dom";
import "../../src/../Style/Sidebar.css";
import { Box, Grid } from "@mui/material";
// import { MenuItemJSON } from "../../data/Mockdata";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useUIContext } from "../../context/Basicproviders";
import userServices from "../../services/user.services";
import { API_URl } from "../../config/config";
import { GoHome } from "react-icons/go";
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";

const Sidebar = () => {
  const {
    domain,
    setDomain,
    selectedDomain,
    setSelectedDomain,
    setLoading,
    setloginuser,
    setFetchdata,
    setItemsPerPage,
    setCurrentPage
  } = useUIContext();
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [openSubMenus, setOpenSubMenus] = useState([]);
  const navigate = useNavigate();

  const handleSubMenuClick = (index) => {
    setOpenSubMenus((prevOpenSubMenus) => {
      if (prevOpenSubMenus.includes(index)) {
        return prevOpenSubMenus.filter((item) => item !== index);
      } else {
        return [...prevOpenSubMenus, index];
      }
    });
    setActiveMenuItem("");
  };

  const handleMenuItemClick = (path) => {
    setActiveMenuItem(path);
    setItemsPerPage(10);
    setCurrentPage(1);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
  };

  useEffect(() => {
    try {
      userServices
        .getloginUser()
        .then(({ data }) => {
          if (data.result === "success") {
            localStorage.setItem("user", JSON.stringify(data.data));
            setloginuser(data.data);
            setUserRole(data?.data?.role);
            const options = data.data.organizations.map((item) => ({
              value: item.schema_name,
              label: (
                <>
                  {item.logo ? (
                    <img
                      src={`${API_URl}/${item.logo}`}
                      alt={item.schema_name}
                      style={{ width: "8rem", height: "4rem" }}
                    />
                  ) : (
                    item.schema_name
                  )}
                </>
              ),
              id: item.id,
            }));
            setDomain(options);
            if (options.length > 0) {
              setSelectedDomain(options[0]);
              localStorage.setItem("domain", JSON.stringify(options[0]));
            }
          }
        })
        .catch((error) => {
          navigate("/");
          if (error && error.response && error.response.status === 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("domain");
            localStorage.removeItem("token");
            navigate("/");
          }
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  }, []);
  const handleChange = (selectedOption) => {
    setSelectedDomain(selectedOption);
    localStorage.setItem("domain", JSON.stringify(selectedOption));
    setLoading(true);
    setFetchdata(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const renderSubMenu = (subMenu, parentIndex) => {
    return (
      <ul style={{ listStyleType: "none" }}>
        {subMenu.map((subItem, subIndex) => (
          <li
            key={subIndex}
            style={{
              paddingLeft: "15px",
              borderLeft: "2px solid #ccc",
            }}
          >
            <NavLink
              className={`submenu ${
                subItem.path === activeMenuItem ? "active" : ""
              }`}
              to={subItem.path}
              onClick={() => {
                handleMenuItemClick(subItem.path);
                handleSubMenuClick();
              }}
            >
              <Grid
                sx={{
                  padding: "0 1rem 0 0",
                  fontSize: "1.1rem",
                }}
              >
                {subItem.icon}
              </Grid>
              <Grid
                sx={{
                  display: openSubMenus.includes(parentIndex)
                    ? "block"
                    : "none",
                }}
              >
                {subItem.name}
              </Grid>
            </NavLink>
            {subItem.subMenu && renderSubMenu(subItem.subMenu, parentIndex)}
          </li>
        ))}
      </ul>
    );
  };
  const MenuItemJSON = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <GoHome />,
    },
    {
      name: "Stock",
      icon: <InventoryRoundedIcon />,
      subMenu: [
        {
          path: "/local",
          name: "Local",
        },
        {
          path: "/fba",
          name: "FBA",
        },
      ],
    },
    {
      name: "Shipment",
      icon: <ViewInArRoundedIcon />,
      subMenu: [
        {
          path: "/shipment-alert",
          name: "Shipment Alert",
        },
        {
          path: "/process",
          name: "Process",
        },
        {
          path: "/in-transaction",
          name: "In-Transaction",
        },
        {
          path: "/shipment-in-transaction",
          name: "Shipment In Transaction",
        },
        {
          path: "/received",
          name: "Received",
        },
      ],
    },
    {
      name: "Configuration",
      icon: <SettingsSuggestRoundedIcon />,
      subMenu: [
        userRole === "admin"
          ? { path: "/user-management", name: "User Management" }
          : null,
        { path: "/system-data-mapping", name: "System Data Mapping" },
      ].filter(Boolean),
    },
  ];
  return (
    <>
      <Box>
        <Box
          sx={{
            width: "253px",
          }}
          className="sidebar"
        >
          <Box
            className="top_section"
            sx={{ width: "250px", padding: "3rem 2rem" }}
          >
            <Select
              options={domain}
              value={selectedDomain}
              onChange={handleChange}
              styles={customStyles}
              defaultValue={domain.find(
                (option) => option?.value === selectedDomain?.value
              )}
            />
          </Box>
          <Grid container direction="column" spacing={0}>
            {MenuItemJSON.map((item, index) => (
              <Grid item key={index}>
                {item.path ? (
                  <Link
                    to={item.path}
                    className={`sidebar-menu ${
                      item.path === activeMenuItem ? "active" : ""
                    }`}
                    onClick={() => handleMenuItemClick(item.path)}
                  >
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      fontWeight={600}
                    >
                      <Grid item className="icon">
                        {item.icon}
                      </Grid>
                      <Grid item component={Box}>
                        {item.name}
                      </Grid>
                    </Grid>
                  </Link>
                ) : (
                  <>
                    <Box
                      className={`sidebar-menu ${
                        item.path === activeMenuItem ? "active" : ""
                      }`}
                      onClick={() =>
                        item.subMenu
                          ? handleSubMenuClick(index)
                          : setActiveMenuItem(item.path)
                      }
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item className="icon">
                          {item.icon}
                        </Grid>
                        <Grid
                          item
                          component={Box}
                          sx={{ display: "flex", cursor: "pointer" }}
                          fontWeight={600}
                        >
                          {item.name}
                          {item.subMenu && (
                            <Grid item>
                              {openSubMenus.includes(index) ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    {item.subMenu &&
                      openSubMenus.includes(index) &&
                      renderSubMenu(item.subMenu, index)}
                  </>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
