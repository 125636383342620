import { useSelector } from "react-redux";
import {
  FETCH_PROCESS,
  FETCH_SHIPMENT,
  FILTER_SHIPMENT_TRANCTION,
  GET_ERROR,
  SHIPMENT_FILTER,
  UNIQUE_SHIPMENT_KEY,
  UPDATE_SHIPMENT,
} from "./shipmentAction";

const intialstate = {
  shipmentData: {},
  uniqueKey: {},
  processData: {},
};
const shipmentReducer = (state = intialstate, action) => {
  switch (action.type) {
    case FETCH_SHIPMENT:
      return {
        ...state,
        shipmentData: action.payload,
      };
    // case GET_ERROR:
    //   return {
    //     ...state,
    //     errorsData: action.payload,
    //   };
    case UNIQUE_SHIPMENT_KEY:
      return {
        ...state,
        uniqueKey: action.payload,
      };
    case FETCH_PROCESS:
      return {
        ...state,
        processData: action.payload,
      };
    case SHIPMENT_FILTER:
      return {
        ...state,
        processData: {
          ...state.processData,
          results: action.payload,
        },
      };
    case UPDATE_SHIPMENT:
      const updatedItem = action.payload;

      const updatedResults = state.processData.results.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      );

      return {
        ...state,
        processData: {
          ...state.processData,
          results: updatedResults,
        },
      };
    case FILTER_SHIPMENT_TRANCTION:
      const filterdata = state.processData?.results?.filter(
        (item) =>
          item?.sku &&
          item?.sku
            .toLowerCase()
            .includes(
              action.searchValue ? action.searchValue.toLowerCase() : ""
            )
      );

      return {
        ...state,
        processData: {
          ...state.processData,
          results: filterdata,
        },
      };
    default:
      return state;
  }
};

export default shipmentReducer;

export function useShipmentMaster() {
  return useSelector((state) => state.shipment);
}
