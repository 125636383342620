import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const showSuccessAlert = (message) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 1800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme:"colored"
  });
};

export const showErrorAlert = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme:"colored"
  });
};

export const showDeleteDataAlert = (itemName, callback) => {
  Swal.fire({
    title: `Are you sure?`,
    text: itemName,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, remove it!',
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};