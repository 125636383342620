import React, { useState } from "react";
import { Box, Drawer, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useUIContext } from "../../../context/Basicproviders";
import { GoHome } from "react-icons/go";
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NavLink } from "react-router-dom";
import logo from "../../../Images/logo.png";

export default function AppDrawer() {
  const [isOpen] = useState(true);
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [activeSubMenuIndex] = useState("");
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <GoHome />,
    },
    {
      path: "/report",
      name: "Report",
      icon: <NewspaperRoundedIcon />,
    },
  ];
  const submenuItem = [
    {
      name: "Stock",
      icon: <InventoryRoundedIcon />,
      subMenu: [
        {
          path: "/local",
          name: "Local",
        },

        {
          path: "/fba",
          name: "FBA",
        },
      ],
    },
    {
      name: "Shipment",
      icon: <ViewInArRoundedIcon />,
      subMenu: [
        {
          path: "/shipment-alert",
          name: "Shipment Alert ",
        },
        {
          path: "/process ",
          name: "Process",
        },
        {
          path: "/in-transaction",
          name: "In-Transaction ",
        },
        {
          path: "/shipment-in-transaction",
          name: "Shipment In Transaction ",
        },
        {
          path: "/received",
          name: "Received ",
        },
      ],
    },

    {
      name: "configuration ",
      icon: <SettingsSuggestRoundedIcon />,
      subMenu: [
        {
          path: "/user-management",
          name: "User Management ",
        },
        {
          path: "/system-data-mapping",
          name: "System Data Mapping",
        },
      ],
    },
    {
      path: "/sales",
      name: "Sales",
      icon: <ReceiptRoundedIcon />,
    },
  ];

  const handleSubMenuClick = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };
  return (
    <>
      <Drawer open={drawerOpen}>
        <Box>
          <Box sx={{ margin: "2rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} style={{ width: "150px" }} alt="logo"></img>

              <CloseIcon
                sx={{
                  fontSize: "2.5rem",
                  width: "25px",
                }}
                onClick={() => setDrawerOpen(false)}
              />
            </Box>

            <Grid container direction="column" spacing={0}>
              {menuItem.map((item, index) => (
                <Grid item key={index}>
                  <NavLink
                    to={item.path}
                    className={`sidebar-menu ${
                      item.path === activeSubMenuIndex ? "active" : ""
                    }`}
                    onClick={() => setActiveMenuItem(item.path)}
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item className="icon">
                        {item.icon}
                      </Grid>
                      <Grid item component={Box}>
                        {item.name}
                      </Grid>
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
            </Grid>
            <Grid container direction="column" spacing={0}>
              {submenuItem.map((item, index) => (
                <Grid item key={index}>
                  <Box
                    className={`sidebar-menu ${
                      item.path === activeMenuItem ? "active" : ""
                    }`}
                  >
                    <Box
                      className="menu-item"
                      onClick={() =>
                        item.subMenu
                          ? handleSubMenuClick(index)
                          : setActiveMenuItem(item.path)
                      }
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item className="icon">
                          {item.icon}
                        </Grid>
                        <Grid
                          item
                          component={Box}
                          sx={{ display: isOpen ? "block" : "none" }}
                        >
                          {item.name}
                        </Grid>
                        {item.subMenu && (
                          <Grid item>
                            {openSubMenu === index ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                  {item.subMenu && openSubMenu === index && (
                    <ul style={{ listStyleType: "none" }}>
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <NavLink
                            className={`submenu ${
                              index === activeSubMenuIndex ? "active" : ""
                            }`}
                            to={subItem.path}
                          >
                            <Grid
                              sx={{ padding: "0 1rem 0 0", fontSize: "1.1rem" }}
                            >
                              {subItem.icon}
                            </Grid>
                            <Grid sx={{ display: isOpen ? "block" : "none" }}>
                              {" "}
                              {subItem.name}
                            </Grid>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
