export const DOWNLOAD_FORMAT_EXCEL = "DOWNLOAD_FORMAT_EXCEL";

export const FETCH_WAREHOUSE_REQUEST = "FETCH_WAREHOUSE_REQUEST";
export const WAREHOUSE_DATA = "WAREHOUSE_DATA";
export const UPLOAD_WAREHOUSE_STOCK = "UPLOAD_WAREHOUSE_STOCK";
export const EDIT_WAREHOUSE_STOCK = "EDIT_WAREHOUSE_STOCK";

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const PRODUCT_DATA = "PRODUCT_DATA";
export const UPLOAD_PRODUCT_STOCK = "UPLOAD_PRODUCT_STOCK";
