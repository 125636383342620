import React, { useEffect, useState } from "react";
import {
  Errormessage,
  StyledTableCell3,
  TableContainerStyled,
  Textfield,
} from "../../Style/Common.style";
import {
  Table,
  TableHead,
  TableRow,
  FormControlLabel,
  TableCell,
  Box,
  TableBody,
  Checkbox,
  MenuItem,
  TextField,
} from "@mui/material";
import { BpCheckbox } from "../globel/Uploadstock";
import { RiSave3Line, RiCloseFill, RiDeleteBin5Line } from "react-icons/ri";
import { BiUndo } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { useUIContext } from "../../context/Basicproviders";
import { useLocation } from "react-router-dom";
import Sellerdata from "../modals/Sellerdata";
import shipmenetServices from "../../services/shipmenet.services";
import { useDispatch } from "react-redux";
import {
  FETCH_PROCESS_REQUEST,
  UPDATE_SHIPMENT,
  UPDATE_SHIPMENT_IN_TRANSACTION,
} from "../../redux/shipment/shipmentAction";
import moment from "moment/moment";
// import {useLocation} from 'react-router-dom';

const Shipmentprocess = ({
  tabledata,
  handleCheckboxChange,
  handleSelectAllChange,
  undoProcess,
  handleInputChange,
  updatedShipment,
  setUpdatedShipment,
}) => {
  const {
    selectAll,
    checkedRows,
    editingRow,
    setEditingRow,
    shipmentDic,
    openErrormodal,
    setOpenerrormodal,
  } = useUIContext();
  const [fccodeList, setfccodeList] = useState([]);
  const [errorResponse, seterrorResponse] = useState([]);

  const handleClose = () => {
    setEditingRow(null);
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const handleOpenerrormodal = () => {
    setOpenerrormodal(true);
  };
  const handleCloseerrormodal = () => {
    setOpenerrormodal(false);
  };
  const handleEditClick = (item) => {   
    setEditingRow(item.id);
    setUpdatedShipment(item);
    if (location.pathname === "/process" && item.state) {
      try {
        shipmenetServices
          .getfccodelist(item.state)
          .then((data) => {
            if (data.result === "success") {
              setfccodeList(data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        throw error;
      }
    }
  };

  const handleEditShipInTransistClick = (item) => {
    setEditingRow(item.id);
    setUpdatedShipment(item);
    if (location.pathname === "/shipment-in-transaction" && item.state) {
      try {
        shipmenetServices
          .getfccodelist(item.state)
          .then((data) => {
            if (data.result === "success") {
              setfccodeList(data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        throw error;
      }
    }
  };

  const handleSaveditedata = () => {
    setEditingRow(null);
    if (location.pathname === "/shipment-in-transaction") {
      dispatch({
        type: UPDATE_SHIPMENT_IN_TRANSACTION,
        payload: updatedShipment,
      });

     setTimeout(() => {
      dispatch({
        type: FETCH_PROCESS_REQUEST,
        payload: "shipment_in_transaction",
      });
    }, 2000);
    } else {
      dispatch({ type: UPDATE_SHIPMENT, payload: updatedShipment });
    }
  };

  const geterrorObject = (id) => {
    try {
      shipmenetServices
        .getidbyError(id)
        .then((data) => {
          seterrorResponse(data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      throw error;
    }
  };
  
  const formatDateForInput = (date) => {
    if (!date) return "";
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  };

  const formatDateForStorage = (date) => {
    if (!date) return "";
    return moment(date).format("DD-MM-YYYY");
  };

  return (
    <>
      <TableContainerStyled>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell3 align="center">
                <FormControlLabel
                  control={
                    <BpCheckbox
                      checked={selectAll}
                      indeterminate={!selectAll && checkedRows.length > 0}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label="ID"
                />
              </StyledTableCell3>
              {tabledata?.display_names &&
                Object.values(tabledata?.display_names).map((displayName) => (
                  <StyledTableCell3 align="center" key={displayName}>
                    {displayName}
                  </StyledTableCell3>
                ))}
              {
                tabledata?.editable_fields?.length > 0 && (
                  // location.pathname !== "/shipment-in-transaction" && (
                  <StyledTableCell3>Action</StyledTableCell3>
                )
                // )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {tabledata?.results?.length <= 0 ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={Object.keys(tabledata.display_names).length}
                >
                  NO ROW DATA
                </TableCell>
              </TableRow>
            ) : (
              tabledata?.results?.map((row, index) => {
                return (
                  <TableRow
                    key={row.id}
                    index={index}
                    sx={{
                      backgroundColor: row.shipment_completed
                        ? "#CFF7D3"
                        : "inherit",
                    }}
                  >
                    <StyledTableCell3 align="center" index={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedRows.includes(row.id)}
                            onChange={() => handleCheckboxChange(row)}
                          />
                        }
                      />
                    </StyledTableCell3>
                    {Object.keys(tabledata.display_names).map((fieldName) => (
                      <StyledTableCell3
                        align="center"
                        key={fieldName}
                        index={index}
                      >
                        {fieldName === "shipment_error" && row[fieldName] ? (
                          <Errormessage
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenerrormodal();
                              geterrorObject(row.id);
                            }}
                          >
                            Error
                          </Errormessage>
                        ) : tabledata?.editable_fields?.includes("fc_code") &&
                          editingRow === row.id &&
                          fieldName === "fc_code" ? (
                          <TextField
                            select
                            id="role"
                            name="fc_code"
                            size="small"
                            value={updatedShipment.fc_code || ""}
                            defaultValue={1}
                            onChange={(e) =>
                              handleInputChange(
                                row,
                                e.target.name,
                                e.target.value
                              )
                            }
                            sx={{
                              width: "8rem",
                            }}
                          >
                            {fccodeList &&
                              fccodeList.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </TextField>
                        ) : tabledata?.editable_fields?.includes(
                            "shipped_quantity"
                          ) &&
                          editingRow === row.id &&
                          fieldName === "shipped_quantity" ? (
                          <Textfield
                            id="standard-basic"
                            label="Shipped Quantity"
                            variant="standard"
                            value={updatedShipment.shipped_quantity || ""}
                            name="shipped_quantity"
                            onChange={(e) =>
                              handleInputChange(
                                row,
                                e.target.name,
                                parseInt(e.target.value)
                              )
                            }
                          />
                        ) : tabledata?.editable_fields?.includes(
                            "actual_delivery_date"
                          ) &&
                          editingRow === row.id &&
                          fieldName === "actual_delivery_date" ? (
                          <input
                            type="date"
                            name="actual_delivery_date"
                            value={formatDateForInput(
                              updatedShipment?.actual_delivery_date
                            )}
                            onChange={(e) =>
                              handleInputChange(
                                row,
                                e.target.name,
                                formatDateForStorage(e.target.value)
                              )
                            }
                            style={{
                              padding: "0.5rem",
                              border: "2px solid #C0C0C0",
                              borderRadius: "0.5rem",
                            }}
                          />
                        ) : tabledata?.editable_fields?.includes(
                            "delivery_method"
                          ) &&
                          editingRow === row.id &&
                          fieldName === "delivery_method" ? (
                          <Textfield
                            id="standard-basic"
                            label="Delivery Method"
                            variant="standard"
                            name="delivery_method"
                            value={updatedShipment.delivery_method || ""}
                            onChange={(e) =>
                              handleInputChange(
                                row,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        ) : tabledata?.editable_fields?.includes(
                            "shipment_number"
                          ) &&
                          editingRow === row.id &&
                          fieldName === "shipment_number" ? (
                          <Textfield
                            id="standard-basic"
                            label="Shipment Number"
                            variant="standard"
                            name="shipment_number"
                            value={updatedShipment.shipment_number || ""}
                            onChange={(e) =>
                              handleInputChange(
                                row,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          row[fieldName]
                        )}
                      </StyledTableCell3>
                    ))}

                    {location.pathname !== "/received" && (
                      <StyledTableCell3 align="center" index={index}>
                        {location.pathname === "/shipment-in-transaction" ? (
                          row.remaining_quantity === 0 &&
                          tabledata?.editable_fields?.length > 0 ? (
                            editingRow === row.id ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  gap: 2,
                                  cursor: "pointer",
                                }}
                              >
                                <RiSave3Line
                                  onClick={() => handleSaveditedata()}
                                  style={{
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                                <RiCloseFill
                                  onClick={handleClose}
                                  style={{
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              </Box>
                            ) : (
                              <FiEdit
                                onClick={() =>
                                  handleEditShipInTransistClick(row)
                                }
                                style={{
                                  fontSize: "1.5rem",
                                  cursor: "pointer",
                                }}
                              />
                            )
                          ) : null
                        ) : location.pathname !== "/received" &&
                          tabledata?.editable_fields?.length > 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              gap: 2,
                              cursor: "pointer",
                            }}
                          >
                            {editingRow === row.id ? (
                              <>
                                <RiCloseFill
                                  onClick={handleClose}
                                  style={{ fontSize: "1.5rem" }}
                                />
                                <RiSave3Line
                                  onClick={() => handleSaveditedata()}
                                  style={{ fontSize: "1.5rem" }}
                                />
                              </>
                            ) : location.pathname === "/process" ? (
                              row.is_delete === false ? (
                                <RiDeleteBin5Line
                                  style={{
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    undoProcess(row, "singledelete")
                                  }
                                />
                              ) : (
                                <BiUndo
                                  style={{
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    undoProcess(row, "singledelete")
                                  }
                                />
                              )
                            ) : (
                              <BiUndo
                                style={{
                                  fontSize: "1.5rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => undoProcess(row)}
                              />
                            )}

                            {editingRow !== row.id &&
                              shipmentDic?.length > 0 &&
                              (row.remaining_quantity === 0 ? (
                                <FiEdit
                                  onClick={() =>
                                    handleEditClick(row)
                                  }
                                  
                                  style={{ fontSize: "1.5rem" }}
                                />
                              ) : (
                                <FiEdit
                                  onClick={() => handleEditClick(row)}
                                  style={{ fontSize: "1.5rem" }}
                                />
                                
                              ))}
                          </Box>
                        ) : null}
                      </StyledTableCell3>
                    )}
                  </TableRow>
                );
              })
            )}
            <Sellerdata
              open={openErrormodal}
              handleClose={handleCloseerrormodal}
              errorResponse={errorResponse}
            />
          </TableBody>
        </Table>
      </TableContainerStyled>
    </>
  );
};

export default Shipmentprocess;
