import React, { useState } from "react";
import { tokens } from "../../theme";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { Box, Typography, Badge, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUIContext } from "../../context/Basicproviders";
import { FiUser } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const Colors = tokens();
const Customiconavtar = styled(Avatar)({
  maxWidth: "100%",
  marginRight: "18px",
});
const CustomAvtarbox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "200px",
  height: "60px",
  border: `1px solid ${Colors.black[500]}`,
  borderRadius: "0.5rem",
  cursor: "pointer",
});
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 8,
    padding: "5px 4px",
    fontSize: "0.9rem",
    color: Colors.black[100],
    backgroundColor: Colors.grey[400],
    border: "2px solid black",
    fontWeight: "700",
  },
}));

const AdminProfile = () => {
  const { firstLetter, setOpenprofile } = useUIContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("domain");
    localStorage.removeItem("user");
    navigate("/");
  };
  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton aria-label="cart">
          {/* <StyledBadge badgeContent={8}>
            <NotificationsNoneIcon sx={{ color: "black", fontSize: "2rem" }} />
          </StyledBadge> */}
        </IconButton>
        <CustomAvtarbox onClick={handleClick}>
          <Customiconavtar src={firstLetter} alt={firstLetter} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h7">
              {JSON.parse(localStorage.getItem("user"))
                ? JSON.parse(localStorage.getItem("user")).username
                : null}
            </Typography>
            <Typography variant="h8">
              {JSON.parse(localStorage.getItem("user"))
                ? JSON.parse(localStorage.getItem("user")).role
                : null}
            </Typography>
          </Box>
        </CustomAvtarbox>{" "}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose} sx={{ width: "12.5rem" }}>
          <FiUser style={{ margin: "0.5rem " }} /> Profile
        </MenuItem>
        <Divider /> */}
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenprofile(true);
          }}
        >
          <FiSettings style={{ margin: "0.5rem " }} /> Change Password
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          <FiLogOut style={{ margin: "0.5rem " }} /> Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AdminProfile;
