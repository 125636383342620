import React from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { Box, Typography, TextField, styled, Button } from "@mui/material";
import { tokens } from "../../theme";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import stockServices from "../../services/stock.services";
import { Formik } from "formik";
import * as yup from "yup";
const Colors = tokens();

const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],
});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});
const Product = ({ open, handleClose, currentState }) => {
  const initialValues = {
    sku: currentState?.title === "missingSku"? currentState?.currentState:"",
    cartoon: "",
    cartoon_pieces: "",
    cartoon_length: "",
    cartoon_weight: "",
    cartoon_height: "",
    cartoon_width: "",
  };
  
  const handleFormSubmit = (values) => {
    try {
      stockServices
        .createproduct(values)
        .then(({ data }) => {
          if (data.result === "success") {
            showSuccessAlert("Product created successfully done");
            handleClose();
          }
        })
        .catch((error) =>
          showErrorAlert(
            error.response?.data?.data.message ||
              "An error occurred while product not created"
          )
        );
    } catch (error) {
      throw error;
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "590px",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "1rem",
        }}
      >
        <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Product</Typography>
          <IoMdClose
            size={"25px"}
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <hr />
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => (
            <form style={{ marginLeft: "6rem" }}>
              <FormRow>
                <Label htmlFor="sku">Sku</Label>
                <TextField
                  type="text"
                  id="sku"
                  name="sku"
                  value={values.sku}
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.sku && !!errors.sku}
                  helperText={touched.sku && errors.sku}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon">Cartoon</Label>
                <TextField
                  type="text"
                  id="cartoon"
                  name="cartoon"
                  value={values.cartoon}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Pieces">Cartoon Pieces</Label>
                <TextField
                  type="text"
                  id="cartoon_pieces"
                  name="cartoon_pieces"
                  value={values.cartoon_pieces}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Length">Cartoon Length</Label>
                <TextField
                  type="text"
                  id="cartoon_length"
                  name="cartoon_length"
                  value={values.cartoon_length}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Weight">Cartoon Weight</Label>
                <TextField
                  type="text"
                  id="cartoon_weight"
                  name="cartoon_weight"
                  value={values.cartoon_weight}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label>Cartoon Width</Label>
                <TextField
                  type="text"
                  name="cartoon_width"
                  value={values.cartoon_width}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Height">Cartoon Height</Label>
                <TextField
                  type="text"
                  id="cartoon_height"
                  name="cartoon_height"
                  value={values.cartoon_height}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <CustomStyledButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </CustomStyledButton>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const checkoutSchema = yup.object().shape({
  sku: yup.string().required("Required"),
});

export default Product;
