import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import { useUIContext } from "../../context/Basicproviders";
import InputAdornment from "@mui/material/InputAdornment";

const Searchbar = ({ placeholder }) => {
  const { setSearchvalue } = useUIContext();
  const handleSearch = (e) => {
    setSearchvalue(e.target.value);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className="input-field"
          type="search"
          onChange={handleSearch}
          size="small"
          sx={{ width: "16rem" }}
        />
      </Box>
    </>
  );
};
export default Searchbar;
