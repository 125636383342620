export const FETCH_SHIPMNET_REQUEST = "FETCH_SHIPMNET_REQUEST";
export const FETCH_SHIPMENT = "FETCH_SHIPMENT";

export const DATE_SHIPMENT_FILTER = "DATE_SHIPMENT_FILTER";
export const UNIQUE_SHIPMENT_KEY = "UNIQUE_SHIPMENT_KEY";

export const EXPORT_PROCESS = "EXPORT_PROCESS";
export const FETCH_PROCESS_REQUEST = "FETCH_PROCESS_REQUEST";
export const FETCH_PROCESS = "FETCH_PROCESS";
export const UNDO_PROCESS = "UNDO_PROCESS";

export const PROCESS_INTRANSACTION = "PROCESS_INTRANSACTION";
export const UNDO_INTRANSACTION = "EXPERT_INTRANSACTION";
export const PROCESS_RECEIVED = "PROCESS_RECEIVED";
export const CREATE_ERROR = "CREATE_ERROR";
export const FILTER_SHIPMENT_TRANCTION = "FILTER_SHIPMENT_TRANCTION";

export const SHIPMENT_FILTER = "SHIPMENT_FILTER";
export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT";
export const UPDATE_SHIPMENT_IN_TRANSACTION = "UPDATE_SHIPMENT_IN_TRANSACTION";

export const INTRANSACTION_SHIPTRANSACTION = "INTRANSACTION_SHIPTRANSACTION";

export const UPLOAD_SHIPMENT_OBJECT = "UPLOAD_SHIPMENT_OBJECT";

export const GET_ERROR_REQUEST = "GET_ERROR_REQUEST";
export const GET_ERROR = "GET_ERROR";

