import React, { useState } from "react";
import { useUIContext } from "../../context/Basicproviders";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { tokens } from "../../theme";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  styled,
  Box,
  Typography,
  Modal,
} from "@mui/material";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import { FiEdit } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import stockServices from "../../services/stock.services";
import EditWarehouse from "./EditWarehouse";
import EditProduct from "./EditProduct";
import EditLocalstock from "./EditLocalstock";
import { useStockMaster } from "../../redux/stock/stockReducer";
import PageChange from "../globel/Pagination";

const Colors = tokens();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.black[400],
    color: Colors.white[400],
    fontSize: "1rem",
    border: "1px solid white",
    borderBottom: 0,
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
    color: Colors.black[400],
    backgroundColor: Colors.white[400],
    border: "1px solid black",
  },
}));

const Datamapping = () => {
  const {
    show,
    handleCloseModal,
    Datamappingname,
    setSystemDataMapping,
    itemsPerPage,
    currentPage,
    setCurrentPage,
  } = useUIContext();
  const { localStock } = useStockMaster();

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const [editCell, setEditCell] = useState(false);
  const [editProduct, setEditproduct] = useState(false);
  const [editLocalstock, setEditLocalstock] = useState(false);
  const [formValues, setFormValues] = useState({});

  const handleClose = () => {
    setEditCell(false);
    setEditproduct(false);
    setEditLocalstock(false);
  };
  const handledelete = (id) => {
    try {
      let deleteFunction, successMessage;
      if (Datamappingname === "Manage Warehouses") {
        deleteFunction = stockServices.wareHousedelete;
        successMessage = "WareHouse successfully Deleted";
      } else if (Datamappingname === "Manage Product") {
        deleteFunction = stockServices.productdelete;
        successMessage = "Product successfully Deleted";
      } else if (Datamappingname === "Manage Local") {
        deleteFunction = stockServices.localStockdelete;
        successMessage = "Local Stock successfully Deleted";
      }
      deleteFunction(id)
        .then((data) => {
          showSuccessAlert(successMessage);
          setSystemDataMapping((prev) => {
            if (Array.isArray(prev)) {
              return prev.filter((item) => item.id !== id);
            } else {
              return prev;
            }
          });
        })
        .catch((error) =>
          showErrorAlert(
            error.response?.data?.data.message ||
              "An error occurred while delete stock"
          )
        );
    } catch (error) {
      throw error;
    }
  };
  const handleEditButtonClick = (rowData) => {
    setFormValues(rowData);
    if (Datamappingname === "Manage Warehouses") {
      setEditCell(true);
      setEditproduct(false);
      setEditLocalstock(false);
    } else if (Datamappingname === "Manage Product") {
      setEditCell(false);
      setEditproduct(true);
      setEditLocalstock(false);
    } else if (Datamappingname === "Manage Local") {
      setEditCell(false);
      setEditproduct(false);
      setEditLocalstock(true);
    }
  };
  const renderTableRows = () => {
    if (!localStock?.results || localStock?.results?.length === 0)
      return (
        <TableRow sx={{ textAlign: "center" }}>
          <TableCell>NO ROW DATA</TableCell>
        </TableRow>
      );

    return localStock?.results?.map((rowData, index) => {
      return (
        <TableRow key={index}>
          <StyledTableCell>{index + 1}</StyledTableCell>
          {Object.entries(localStock?.display_names).map(
            ([key, value], index) => (
              <StyledTableCell align="center" key={index}>
                {Datamappingname === "Manage Local"
                  ? key === "product_name"
                    ? rowData?.product?.product_name
                    : key === "product_price"
                    ? rowData?.product?.product_price
                    : key === "sku"
                    ? rowData?.product?.sku
                    : rowData[key]
                  : rowData[key]}
              </StyledTableCell>
            )
          )}
          <StyledTableCell align="center">
            <FiEdit
              onClick={() => handleEditButtonClick(rowData)}
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          </StyledTableCell>
          <StyledTableCell align="center">
            <RiDeleteBin5Line
              style={{ fontSize: "1.5rem", cursor: "pointer", color: "red" }}
              onClick={() => handledelete(rowData.id)}
            />
          </StyledTableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            p: 2,
            width: { xl: "90%", md: "90%", sm: "90%", xs: "90%" },
            maxHeight: "100%",
            height: "700px",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 20,
              cursor: "pointer",
            }}
          >
            <IoMdClose size={"25px"} onClick={handleCloseModal} />
          </Box>
          <Box
            sx={{
              display: { xl: "flex", md: "flex" },
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <Box>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold" }}
              >
                System Data Mapping
              </Typography>
              <Typography
                id="modal-description"
                sx={{ mt: 2, fontSize: 13 }}
                size="small"
              >
                <Link to="/dashboard" style={{ color: "inherit" }}>
                  Home
                </Link>{" "}
                <LiaGreaterThanSolid />
                <Link onClick={handleCloseModal} style={{ color: "inherit" }}>
                  configuration
                </Link>
                <LiaGreaterThanSolid />
                <Link onClick={handleCloseModal} style={{ color: "inherit" }}>
                  System Data Mapping
                </Link>
                <LiaGreaterThanSolid />
                <Link style={{ color: "inherit" }}>{Datamappingname} </Link>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              padding: {
                xl: "2rem 4rem 0rem",
                md: "2rem 4rem 0rem",
                sm: "2rem 2rem 0rem",
                xs: "2rem 2rem 1rem",
              },
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
            ></Box>
            <Box
              sx={{
                borderRadius: "0.50rem",
                padding: "0.5rem 0rem",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              <Box sx={{ maxHeight: "30rem", overflowY: "auto" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    {localStock?.display_names && (
                      <TableRow>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          No
                        </StyledTableCell>

                        {Object.entries(localStock?.display_names).map(
                          ([key, value], index) => (
                            <StyledTableCell
                              sx={{ textAlign: "center" }}
                              key={index}
                            >
                              {value}
                            </StyledTableCell>
                          )
                        )}
                        <StyledTableCell align="center">Edit</StyledTableCell>
                        <StyledTableCell align="center">Delete</StyledTableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>{renderTableRows()}</TableBody>
                </Table>
                <EditWarehouse
                  open={editCell}
                  handleClose={handleClose}
                  formValues={formValues}
                />
                <EditProduct
                  open={editProduct}
                  handleClose={handleClose}
                  formValues={formValues}
                />
                <EditLocalstock
                  open={editLocalstock}
                  handleClose={handleClose}
                  formValues={formValues}
                />
              </Box>
            </Box>
          </Box>
          <PageChange
            currentPage={currentPage}
            totalPages={Math.ceil(localStock?.count / itemsPerPage)}
            itemsPerPage={itemsPerPage}
            onChangePage={handlePageChange}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Datamapping;
