import axios from "axios";
import { PORT } from "../config/config";
const commonShipment = "/api/shipments/";
const shipmentalertApi = commonShipment + "alerts/";
const processApi = commonShipment + "update/";
const updateIntransctionApi = commonShipment + "update-in-transaction/";
const excelsheet = commonShipment + "in-transist-shipment/";
const updateShipmentIntransctionApi = commonShipment + "single-in-transit/"

//shipmentalert Api
const shipmentAlert = async (itemsPerPage, currentPage) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + shipmentalertApi,
      {
        headers,
        params: {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const inTransactionUpdate = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.patch(
      `http://${domainName?.value}.${PORT}` +
        commonShipment +
        "single-in-transit/",
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const updateInTransaction = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}` + updateIntransctionApi,
      data,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const downloadExcel = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const domainName = JSON.parse(localStorage.getItem("domain"));
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}${excelsheet}`,
      { headers }
    );
    if (response.data) {
      const link = document.createElement("a");
      link.href = response?.data?.data?.excel_url;
      link.download = "Manifest.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    throw error;
  }
};

const getShipmentalertparams = async (
  startDate,
  endDate,
  ship_to_state,
  sku,
  shipment_color,
  itemsPerPage,
  currentPage
) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  const apiUrl = `http://${domainName?.value}.${PORT}${shipmentalertApi}`;
  let queryParams = "";
  if (ship_to_state?.length > 0) {
    queryParams += `ship_to_state=${ship_to_state.join(",")}&`;
  }
  if (sku?.length > 0) {
    queryParams += `sku=${sku.join(",")}&`;
  }
  if (startDate !== undefined) {
    queryParams += `start_date=${startDate}&`;
  }
  if (endDate !== undefined) {
    queryParams += `end_date=${endDate}&`;
  }
  if (shipment_color && shipment_color !== "none") {
    queryParams += `shipment_color=${shipment_color}&`;
  }
  if (itemsPerPage) {
    queryParams += `limit=${itemsPerPage}&`;
  }
  if (currentPage) {
    queryParams += `offset=${(currentPage - 1) * itemsPerPage}&`;
  }
  try {
    const response = await axios.get(`${apiUrl}?${queryParams}`, {
      headers,
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getReceivedDate = async (sku, fc_code, shipment_number) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  const apiUrl = `http://${domainName?.value}.${PORT}/api/shipments/get-received`;
  let queryParams = "";
  if (fc_code?.length > 0) {
    queryParams += `fc_code=${fc_code.join(",")}&`;
  }
  if (sku?.length > 0) {
    queryParams += `sku=${sku.join(",")}&`;
  }
  if (shipment_number?.length > 0) {
    queryParams += `shipment_number=${shipment_number.join(",")}&`;
  }

  try {
    const response = await axios.get(`${apiUrl}?${queryParams}`, {
      headers,
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const movePocess = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}` + commonShipment,
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const moveRecieved = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));

  try {
    const response = await axios.patch(
      `http://${domainName?.value}.${PORT}` +
        commonShipment +
        "update-shipment-in-transaction/",
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const getProcessdata = async (params, itemsPerPage, currentPage, date,sku) => {
  
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  const apiParams = {
    limit: itemsPerPage,
    offset: (currentPage - 1) * itemsPerPage,
    sku:sku
  };

  if (params === "shipment_in_transaction") {
    apiParams.delivery_date = date;
    apiParams.shipment_status = params;
  } else {
    apiParams.shipment_status = params;
  }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + commonShipment,
      {
        headers,
        params: apiParams,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const undoprocessdata = async (ids, deletetype) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));

  const params = {
    ids: deletetype === "singledelete" ? ids : ids.join(","),
  };

  try {
    const response = await axios.delete(
      `http://${domainName?.value}.${PORT}${commonShipment}batch-delete/`,
      {
        headers: headers,
        params: params,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const moveIntransction = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.patch(
      `http://${domainName?.value}.${PORT}` + processApi,
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const updateIntransction = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.patch(
      `http://${domainName?.value}.${PORT}` + updateIntransctionApi,
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//create error api
const createError = async (data) => {
  
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}` + commonShipment + "errors/",
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getErrors = async (start_date, end_date) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/shipments/errors/?start_date=${start_date}&end_date=${end_date}`,
      // `http://${domainName?.value}.${PORT}/api/shipments/errors/`,
      {
        headers,
        // params: {
        //   start_date: start_date,
        //   end_date: end_date,
        // },
      }
    );
    
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getStateErrors = async (state, start_date, end_date) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/shipments/errors/?start_date=${start_date}&end_date=${end_date}&state=${state}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getidbyError = async (id) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` +
        commonShipment +
        "errors/get-error/",
      {
        headers,
        params: { shipment_id: id },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getfccodelist = async (params) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + commonShipment + "get-fc_code/",
      {
        headers,
        params: { state: params },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//downlaod excel format
const downlaodFormat = async (params) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/core/download-sample-excel",
      {
        headers,
        params: { sample_excel: params },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//downlaod error excel
const downlaodErrors = async (start_date,end_date) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/shipments/errors/download-excel/?start_date=${start_date}&end_date=${end_date}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

// delete error
const deleteErrors = async (id) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.delete(
      `http://${domainName?.value}.${PORT}` + commonShipment + "errors/" + id,
      {
        headers
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//in-transcation to shipment-in-transcation
const inTransactionToshipInTranscation = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.patch(
      `http://${domainName?.value}.${PORT}` +
        "/api/shipments/in-transist-to-ship/",
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const updateShipmentIntransction = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.patch(
      `http://${domainName?.value}.${PORT}` + updateShipmentIntransctionApi ,
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const UploadShipmentobject = async (file, params) => {
  const formData = new FormData();
  formData.append(params, file);
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}/api/shipments/update-shipment-object/`,
      formData,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const manualShipment = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}` +
        "/api/shipments/manual-shipments/",
      data,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export default {
  shipmentAlert,
  getShipmentalertparams,
  movePocess,
  getProcessdata,
  undoprocessdata,
  moveIntransction,
  updateIntransction,
  createError,
  getidbyError,
  getfccodelist,
  moveRecieved,
  downloadExcel,
  downlaodFormat,
  inTransactionToshipInTranscation,
  UploadShipmentobject,
  getReceivedDate,
  manualShipment,
  inTransactionUpdate,
  updateShipmentIntransction,
  getErrors,
  downlaodErrors,
  getStateErrors,
  deleteErrors
};
