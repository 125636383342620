import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { Box, Typography, TextField, styled, Button } from "@mui/material";
import { tokens } from "../../theme";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import { makeStyles } from "@mui/styles";
import stockServices from "../../services/stock.services";
import { Formik } from "formik";
import * as yup from "yup";
const Colors = tokens();

const useStyles = makeStyles(() => ({
  button: {
    marginTop: "3rem",
    padding: "1.3rem 8rem",
    backgroundColor: "#002B53",
    color: "#fff",
    border: "none",
    fontSize: "18px",
    width: "100%",
  },
  modalBackground: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  forgotPasswordModal: {
    backgroundColor: "#fff",
    padding: "3rem",
    borderRadius: "8px",
    width: "80%",
    maxWidth: "450px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
}));
const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],
});
const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});
const Localstock = ({ open, handleClose }) => {
  const handleFormSubmit = (values) => {
    try {
      stockServices
        .createLocalStock(values)
        .then(({ data }) => {
          if (data.result === "success") {
            showSuccessAlert("localstock created successfully done");
            handleClose();
          }
        })
        .catch((error) =>
          showErrorAlert(
            error.response?.data?.data.message ||
              "An error occurred while Localstock not created"
          )
        );
    } catch (error) {
      throw error;
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "590px",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            alignItems: "center",
            height: "3rem",
            padding: "0.5rem",
          }}
        >
          <Typography variant="h5" sx={{ color: Colors.grey[100] }}>
            Localstock
          </Typography>
          <IoMdClose
            size={"30px"}
            onClick={handleClose}
            style={{
              cursor: "pointer",
              marginRight: "3rem",
            }}
          />
        </Box>
        <hr />
        <Box sx={{ height: "50rem", overflow: "auto" }}>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => (
              <form style={{ marginLeft: "6rem" }}>
                <FormRow>
                  <Label htmlFor="sku">SKU</Label>
                  <TextField
                    type="text"
                    id="Fc_Code"
                    name="sku"
                    value={values.sku}
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.sku && !!errors.sku}
                    helperText={touched.sku && errors.sku}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="product_name">Product Name</Label>
                  <TextField
                    type="text"
                    id="product_name"
                    name="product_name"
                    value={values.product_name}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="product_price">Product Price</Label>
                  <TextField
                    type="text"
                    id="product_price"
                    name="product_price"
                    value={values.product_price}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="total_amount">Total Amount</Label>
                  <TextField
                    type="text"
                    id="total_amount"
                    name="total_amount"
                    value={values.total_amount}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="cartoon">Cartoon</Label>
                  <TextField
                    type="text"
                    id="cartoon"
                    name="cartoon"
                    value={values.cartoon}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="cartoon_pieces">Cartoon Pieces</Label>
                  <TextField
                    type="text"
                    id="cartoon_pieces"
                    name="cartoon_pieces"
                    value={values.cartoon_pieces}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="cartoon_length">Cartoon Length</Label>
                  <TextField
                    type="text"
                    id="cartoon_length"
                    name="cartoon_length"
                    value={values.cartoon_length}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="cartoon_weight">Cartoon Weight</Label>
                  <TextField
                    type="text"
                    id="cartoon_weight"
                    name="cartoon_weight"
                    value={values.cartoon_weight}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="cartoon_width">Cartoon Width</Label>
                  <TextField
                    type="text"
                    id="cartoon_width"
                    name="cartoon_width"
                    value={values.cartoon_width}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="cartoon_height">Cartoon Height</Label>
                  <TextField
                    type="text"
                    id="cartoon_height"
                    name="cartoon_height"
                    value={values.cartoon_height}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="total_pieces">Total Pieces</Label>
                  <TextField
                    type="text"
                    id="total_pieces"
                    name="total_pieces"
                    value={values.total_pieces}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="purchased_date">Purchase Date</Label>
                  <TextField
                    type="date"
                    id="purchased_date"
                    name="purchased_date"
                    value={values.purchased_date}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px",
                    }}
                  />
                </FormRow>
                <CustomStyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </CustomStyledButton>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};
const checkoutSchema = yup.object().shape({
  sku: yup.string().required("Required"),
});
const initialValues = {
  sku: "",
  product_name: "",
  product_price: "",
  total_amount: "",
  cartoon: "",
  cartoon_pieces: "",
  cartoon_length: "",
  cartoon_weight: "",
  cartoon_width: "",
  cartoon_height: "",
  total_pieces: "",
  purchased_date: "",
};
export default Localstock;
