import React, { createContext, useState, useContext } from "react";

export const BasicContext = createContext({});
export const useUIContext = () => useContext(BasicContext);

const Basicprovider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [width] = useState(window.innerWidth);
  const [editingRow, setEditingRow] = useState(null);
  const [loginuser, setloginuser] = useState({});
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [domain, setDomain] = useState([]);
  const [searchValue, setSearchvalue] = useState("");
  const [dateFilter, setDatefilter] = useState([]);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openErrormodal, setOpenerrormodal] = useState(false);
  const [openProfile, setOpenprofile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processLoader, setprocessoader] = useState(false);
  const [fetchdatabool, setFetchdata] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [shipmentDic, setshipmentdic] = useState([]);
  const [systemDataMapping, setSystemDataMapping] = useState([]);
  const [Datamappingname, setdatamappingname] = useState("");
  const [error, setError] = useState("");
  const itemsPerPageOptions = [10, 25, 50];
  const Loginuser = JSON.parse(localStorage.getItem("user"));
  const firstLetter = Loginuser
    ? Loginuser.username.charAt(0).toUpperCase()
    : "";
  const isLaptopSize = width <= 1306;
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleClose = () => setShow(false);
  const handleCloseEditrow = () => {
    setEditingRow(null);
  };
  const handleShow = (value, name) => {
    setdatamappingname(name);
    setShow(true);
    if (typeof value === "function") {
      value().then((data) => {
        setSystemDataMapping(data.data);
      });
    }
  };

  const handleopenModal = () => setShow(true);
  const handleCloseprofilemodal = () => setOpenprofile(false);

  return (
    <BasicContext.Provider
      value={{
        drawerOpen,
        setDrawerOpen,
        editingRow,
        setEditingRow,
        handleShow,
        show,
        handleClose,
        handleCloseModal,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        setShow,
        openErrormodal,
        setOpenerrormodal,
        loginuser,
        setloginuser,
        firstLetter,
        handleopenModal,
        setOpenprofile,
        openProfile,
        domain,
        loading,
        setLoading,
        setDomain,
        selectedDomain,
        setSelectedDomain,
        itemsPerPageOptions,
        handleCloseprofilemodal,
        searchValue,
        setSearchvalue,
        dateFilter,
        setDatefilter,
        selectAll,
        setSelectAll,
        checkedRows,
        setCheckedRows,
        processLoader,
        setprocessoader,
        error,
        setError,
        shipmentDic,
        setshipmentdic,
        isLaptopSize,
        systemDataMapping,
        setSystemDataMapping,
        Datamappingname,
        handleCloseEditrow,
        setFetchdata,
        fetchdatabool,
      }}
    >
      {children}
    </BasicContext.Provider>
  );
};

export default Basicprovider;
