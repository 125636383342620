import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  TableCell,
} from "@mui/material";
import Select from "react-select";
import { useUIContext } from "../../context/Basicproviders";
import {
  DownloadPdfbutton,
  Nullvalue,
  StyledTableCell,
  TableContainerStyled,
  customStyles,
} from "../../Style/Common.style";
import Loader from "../globel/Loader";
import Searchbar from "../globel/Searchbar";
import { DownloadPdf, FBAStock } from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import Bottombar from "../globel/Bottombar";
import ErrorFbastock from "../modals/ErrorFbastock";
import { useDispatch } from "react-redux";
import {
  FBA_FILTER,
  FBA_SEARCH_FILTER,
  FETCH_FBA_REQUEST,
} from "../../redux/stock/stockAction";
import { useStockMaster } from "../../redux/stock/stockReducer";
import { DOWNLOAD_FORMAT_EXCEL } from "../../redux/warehouse/warehouseAction";
import { TbFileDownload } from "react-icons/tb";
import {
  DISPOSITION_FILTER,
  FCCODE_FILTER,
  SKU_FILTER,
} from "../../redux/filter/filterAction";
import { useFilterMaster } from "../../redux/filter/filterReducer";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const FBA = () => {
  const {
    setCurrentPage,
    currentPage,
    itemsPerPage,
    searchValue,
    isLaptopSize,
    selectedDomain,
  } = useUIContext();
  const dispatch = useDispatch();
  const { fbaStock, uniqueKey, fbaMissingSku } = useStockMaster();
  const { uniqueSku, uniqueFccode, uniqueDisposition } = useFilterMaster();
  const [missingskuModal, setMissingskuModal] = useState(false);
  const [selectedFccode, setSelectedFccode] = useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState([]);
  const [selectedMsku, setSelectedMsku] = useState([]);
  const [selectedDate, setSelectedDate] = useState(""); // State to hold selected date

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getFbastock = () => {
    dispatch({
      type: FETCH_FBA_REQUEST,
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
    dispatch({ type: SKU_FILTER });
    dispatch({ type: FCCODE_FILTER });
    dispatch({ type: DISPOSITION_FILTER });
  };
  const fbaFilter = () => {
    dispatch({
      type: FBA_FILTER,
      fc_code: selectedFccode,
      sku: selectedMsku,
      disposition: selectedDisposition,
      fba_date: selectedDate
    });
  };

  console.log(fbaStock);

  const handleFilterChange = (selectedOptions, type) => {
    let selectedState, setterFunction;
    switch (type) {
      case "disposition":
        selectedState = selectedDisposition;
        setterFunction = setSelectedDisposition;
        break;
      case "msku":
        selectedState = selectedMsku;
        setterFunction = setSelectedMsku;
        break;
      case "fccode":
        selectedState = selectedFccode;
        setterFunction = setSelectedFccode;
        break;
      default:
        return;
    }
    setterFunction(selectedOptions.map((option) => option.value));
  };

  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  useEffect(() => {
    if (selectedDomain !== null) {
      if (
        selectedDisposition.length > 0 ||
        selectedMsku.length > 0 ||
        selectedFccode.length > 0 || 
        selectedDate
      ) {
        fbaFilter();
      } else if (searchValue) {
        const filterdata = uniqueKey?.results?.filter(
          (item) =>
            (item?.product?.product_name &&
              item?.product?.product_name
                .toLowerCase()
                .includes(searchValue ? searchValue.toLowerCase() : "")) ||
            item.product.sku.toLowerCase().includes(searchValue.toLowerCase())
        );
        dispatch({ type: FBA_SEARCH_FILTER, payload: filterdata });
      } else if (
        selectedDisposition?.length === 0 &&
        selectedMsku?.length === 0 &&
        selectedFccode?.length === 0
      ) {
        getFbastock();
      }
    }
  }, [
    selectedDisposition,
    selectedFccode,
    selectedMsku,
    selectedDate,
    searchValue,
    selectedDomain,
    currentPage,
    itemsPerPage,
  ]);

  const handleFbamissingmodal = () => {
    setMissingskuModal(true);
  };

  const handleCloseFBAmissing = () => {
    setMissingskuModal(false);
  };

  return (
    <>
      <Box
        sx={{
          height: "78vh",
          overflowY: "auto",
          padding: "0rem 1rem 0rem",
          width: isLaptopSize ? "80vw" : "86vw",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Searchbar placeholder="Search product name,sku" />
          <Box sx={{ display: "flex", gap: 1 }}>
            {fbaMissingSku?.length > 0 ? (
              <DownloadPdfbutton onClick={handleFbamissingmodal}>
                Missing SKU
              </DownloadPdfbutton>
            ) : null}
            <Box>
            <DatePicker
                onChange={handleDatePickerChange}
                renderExtraFooter={() => "extra footer"}
                style={{
                  height: "2.5rem",
                  border: "1px solid #2684ff",
                }}
              />
            </Box>
            <Box sx={{ width: "13rem" }}>
              <Select
                isMulti
                name="fccode"
                options={uniqueFccode?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedFccode?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                onChange={(event) => handleFilterChange(event, "fccode")}
                styles={customStyles}
                placeholder="Select Fccode"
              />
            </Box>

            <Box sx={{ width: "13rem" }}>
              <Select
                isMulti
                name="msku"
                options={uniqueSku?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedMsku?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                onChange={(event) => handleFilterChange(event, "msku")}
                styles={customStyles}
                placeholder="Select Sku"
              />
            </Box>
            <Box sx={{ width: "13rem" }}>
              <Select
                isMulti
                name="disposition"
                options={uniqueDisposition?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedDisposition?.map((product) => ({
                  value: product,
                  label: product,
                }))}
                onChange={(event) => handleFilterChange(event, "disposition")}
                styles={customStyles}
                placeholder="Select Disposition"
              />
            </Box>
            <FBAStock />
            <DownloadPdf data={fbaStock} filename="FBA" />
            <DownloadPdfbutton
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                textWrap: "nowrap",
              }}
              onClick={() =>
                dispatch({
                  type: DOWNLOAD_FORMAT_EXCEL,
                  payload: "sample_fba",
                })
              }
            >
              <TbFileDownload fontSize="1.2rem" /> Excel Format
            </DownloadPdfbutton>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "2rem",
            height: "65vh",
          }}
        >
          <TableContainerStyled>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                {fbaStock?.display_names && fbaStock?.display_names && (
                  <TableRow>
                    {Object.entries(fbaStock?.display_names).map(
                      ([key, value], index) => (
                        <StyledTableCell
                          sx={{ textAlign: "center" }}
                          key={index}
                        >
                          {value}
                        </StyledTableCell>
                      )
                    )}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {fbaStock?.results && fbaStock?.results.length > 0 ? (
                  fbaStock?.results?.map((row, index) => (
                    <TableRow key={row.id}>
                      {Object.entries(fbaStock?.display_names).map(
                        ([key, displayName], index) => (
                          <>
                            {fbaStock?.results === null ? (
                              <Nullvalue>-</Nullvalue>
                            ) : (
                              <StyledTableCell align="center" key={index}>
                                {key === "product_name"
                                  ? row.product.product_name
                                  : key === "fc_code"
                                  ? row.warehouse.fc_code
                                  : key === "sku"
                                  ? row.product.sku
                                  : row[key]}
                              </StyledTableCell>
                            )}
                          </>
                        )
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{ textAlign: "center" }}>
                    <TableCell
                      align="center"
                      colSpan={
                        Object.keys(fbaStock?.display_names || {}).length + 1
                      }
                    >
                      NO ROW DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainerStyled>
        </Box>
      </Box>

      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(fbaStock?.count / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ErrorFbastock
        missingskuModal={missingskuModal}
        handleCloseFBAmissing={handleCloseFBAmissing}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default FBA;
