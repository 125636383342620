import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  styled,
  Modal,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useUIContext } from "../../context/Basicproviders";
import userServices from "../../services/user.services";
import { useNavigate } from "react-router";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";

const Colors = tokens();

const Label = styled("label")({
  display: "block",
  marginBottom: "0.7rem",
  marginTop: "0.7rem",
  fontSize: "1rem",
  fontWeight: 500,
});

const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "2rem 0rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const ProfileResetPassword = () => {
  const { openProfile, setOpenprofile, handleCloseprofilemodal } =
    useUIContext();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate("");
  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlechangepassword = (values) => {
    try {
      userServices
        .changePassword({
          current_password: values.current_password,
          new_password: values.new_password,
        })
        .then(({ data }) => {
          if (data.result === "success") {
            setOpenprofile(false);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate("/");
            showSuccessAlert(data.message);
          }
        })
        .catch((error) => {
          showErrorAlert(
            error.response?.data?.data.message ||
              "An error occurred while updating password"
          );
        });
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Modal
        open={openProfile}
        onClose={handleCloseprofilemodal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "590px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
          }}
        >
          <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Change password</Typography>
            <IoMdClose
              size={"25px"}
              onClick={handleCloseprofilemodal}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
          <hr />
          <Formik
            onSubmit={handlechangepassword}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
            }) => (
              <form style={{ marginLeft: "5rem" }}>
                <Label htmlFor="CurrentPassword">Current Password </Label>
                <TextField
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Current password"
                  id="CurrentPassword"
                  name="current_password"
                  size="small"
                  value={values.current_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    !!touched.current_password && !!errors.current_password
                  }
                  helperText={
                    touched.current_password && errors.current_password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleToggleCurrentPassword}
                          sx={{ color: Colors.black[100] }}
                        >
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
                <Label htmlFor="NewPassword">New Password </Label>
                <TextField
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New password"
                  id="NewPassword"
                  name="new_password"
                  size="small"
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.new_password && !!errors.new_password}
                  helperText={touched.new_password && errors.new_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleToggleNewPassword}
                          sx={{ color: Colors.black[100] }}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
                <Label htmlFor="ConfirmPassword">Confirm Password</Label>
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  id="ConfirmPassword"
                  name="ConfirmPassword"
                  size="small"
                  value={values.ConfirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.ConfirmPassword && !!errors.ConfirmPassword}
                  helperText={touched.ConfirmPassword && errors.ConfirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleToggleConfirmPassword}
                          sx={{ color: Colors.black[100] }}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
                <CustomStyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Change password
                </CustomStyledButton>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
const checkoutSchema = yup.object().shape({
  current_password: yup.string().required("Required"),
  new_password: yup
    .string()
    .required("Required")
    .min(6, "Password must be at least 6 characters"),
  ConfirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

const initialValues = {
  current_password: "",
  new_password: "",
  ConfirmPassword: "",
};

export default ProfileResetPassword;
