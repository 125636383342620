import {
  useMediaQuery,
  TableCell,
  tableCellClasses,
  TableContainer,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
export const useCustomTheme = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const Colors = tokens(theme.palette.mode);
  return { theme, matches, Colors };
};

//  table css
export const StyledTableCell = styled(TableCell)(({ theme, index }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.black[400],
      color: Colors.header_fontcolor[100],
      fontSize: "1rem",
      position: "sticky",
      top: 0,
      zIndex: 1000,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: Colors.black_font[100],
      padding: "1rem 1rem",
      textTransform: "uppercase",
      backgroundColor:
        index % 2 === 0 ? `${Colors.white[400]}` : `${Colors.black[700]}`,
    },
  };
});
export const StyledTableCell1 = styled(TableContainer)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    fontSize: "1rem",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    padding: "0.5rem",
    alignItems: "center",
    display: "flex",
  };
});
export const StyledTableCell3 = styled(TableCell)(({ theme, index }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.black[400],
      color: Colors.header_fontcolor[100],
      fontSize: "1rem",
      position: "sticky",
      top: 0,
      zIndex: 1000,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: Colors.black_font[100],
      padding: "1rem 1rem",
      textTransform: "uppercase",
      // backgroundColor:
      //   index % 2 === 0 ? `${Colors.white[400]}` : `${Colors.black[700]}`,
    },
  };
});
export const StyledTableCell2 = styled(TableContainer)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {};
});
export const SpecialTextField = styled(TextField)({
  backgroundColor: "#F1F4F8",
  borderRadius: "0.2rem",
  color: "#000000",
  border: "none",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "5px",
    },
  },
  "& .MuiInputBase-input": {
    color: "#000000",
  },
});

export const TableContainerStyled = styled(TableContainer)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    maxHeight: "65vh",
    border: `1px solid ${Colors.black[600]}`,
    borderRadius: "0.5rem",
  };
});

// edit textfield css

export const Textfield = styled(TextField)({
  width: "7rem",
});
export const ReceiveformButton = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    backgroundColor: Colors.black[400],
    color: Colors.white[100],
    textTransform: "capitalize",
    fontSize: "1rem",
    margin: "1rem",
    marginLeft: "auto",
    display: "block",
    width: "10rem",
    "&:hover": {
      backgroundColor: Colors.black[400],
    },
  };
});
export const Textfieldbg = styled(TextField)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    width: "3rem",
    "& input": {
      padding: "0.5rem",
      border: `1px solid ${Colors.black[100]}`,
      borderRadius: "4px",
    },
  };
});

// table date search button
export const CustomSearchButton = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    backgroundColor: Colors.black[100],
    color: Colors.white[400],
    textTransform: "capitalize",
    marginBottom: "4rem",
    height: "2.5rem",
    "&:hover": {
      backgroundColor: Colors.black[100],
    },
  };
});

// data maping style button
export const SystemdataButton = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    backgroundColor: Colors.black[400],
    color: Colors.white[400],
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "6px",
    boxShadow: Colors.boxshadow[200],
    "&:hover": {
      backgroundColor: Colors.black[400],
      color: Colors.white[400],
    },
  };
});

export const Editbutton = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    backgroundColor: Colors.white[400],
    color: Colors.black[400],
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "6px",
    padding: "0.3rem 1.8rem",
    border: "1px solid #d0d5dd",
    boxShadow: Colors.boxshadow[200],
    "&:hover": {
      backgroundColor: Colors.white[400],
      color: Colors.black[400],
    },
  };
});
// input password textfield visualhidden
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// dashboard analysis box
export const CustomDashboardBox = styled("div")(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    padding: "20px 25px 0px 25px",
    border: `1px solid ${Colors.black[600]}`,
    borderRadius: "13.85px",
    height: "9rem",
    background: Colors.white[400],
    color: Colors.black_font[100],
    boxShadow: Colors.boxshadow[100],
    gap: "6.925px",
    cursor: "pointer",
  };
});

// upload button style
export const UploadBtnStyle = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    backgroundColor: Colors.primary[300],
    color: Colors.white[400],
    border: `1px solid ${Colors.black[200]}`,
    textTransform: "uppercase",
    fontSize: "0.8rem",
    borderRadius: "0.5rem",
    p: 1,
    "&:hover": {
      backgroundColor: Colors.primary[300],
      color: Colors.white[400],
    },
  };
});

//export pdf or excel download button
export const DownloadPdfbutton = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    p: 1,
    margin: "0 0.2rem ",
    backgroundColor: Colors.black[400],
    color: Colors.white[400],
    border: `1px solid ${Colors.black[200]}`,
    borderRadious: "0.5rem",
    textTransform: "uppercase",
    fontSize: "0.8rem",
    textWrap: "nowrap",
    "&:hover": {
      backgroundColor: Colors.black[400],
      color: Colors.white[400],
    },
  };
});

//error and success status style
export const Errormessage = styled(Typography)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    color: Colors.statustext[100],
    backgroundColor: Colors.statusBg[100],
    borderRadius: "0.5rem",
    padding: "0.4rem",
    fontWeight: 900,
  };
});

export const Nullvalue = styled(Typography)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    color: Colors.statustext[100],
    fontWeight: 500,
  };
});

export const Successmessage = styled(Typography)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    color: Colors.statustext[200],
    backgroundColor: Colors.statusBg[200],
    borderRadius: "0.5rem",
    padding: "0.4rem",
    fontWeight: 900,
  };
});
export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

//select filter
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #2684ff",
    borderRadius: "5px",
    boxShadow: state.isFocused ? "0 0 0 1px #2684ff" : "none",
    height: "10px",
    overflow: "auto",
    "&:hover": {
      borderColor: "#2684ff",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#f8f9fa", // Background color of the menu
    zIndex: 9999, // Adjust z-index to ensure the menu appears above other elements
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow for the menu
  }),
};

export const DeleteButton = styled(Button)(({ theme }) => {
  const { Colors } = useCustomTheme(theme.palette.mode);
  return {
    backgroundColor: "#ff0000",
    color: Colors.white[100],
    border: `1px solid ${Colors.black[200]}`,
    textTransform: "uppercase",
    fontSize: "0.8rem",
    borderRadius: "0.5rem",
    p: 1,
    display: "flex",
    gap: "0.3rem",
    "&:hover": {
      backgroundColor: "#ff0000",
      color: Colors.white[400],
    },
  };
});
