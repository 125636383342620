import { useSelector } from "react-redux";
import { AUTH_FAILED, FETCH_USERDATA } from "./authAction";

const initialStste = { authFailed: "", userData: {} };
const authReducer = (state = initialStste, action) => {
  switch (action.type) {
    case AUTH_FAILED:
      return {
        ...state,
        authFailed: action.payload,
      };
    case FETCH_USERDATA:
      return {
        ...state,
        userData: action.payload?.data,
      };
    default:
      return state;
  }
};

export default authReducer;

export function useAuthMaster() {
  return useSelector((state) => state.auth);
}
