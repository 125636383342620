import React from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { Box, Typography, TextField, styled, Button } from "@mui/material";
import { tokens } from "../../theme";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import { makeStyles } from "@mui/styles";
import stockServices from "../../services/stock.services";
import { Formik } from "formik";
import * as yup from "yup";
const Colors = tokens();

const useStyles = makeStyles(() => ({
  button: {
    marginTop: "3rem",
    padding: "1.3rem 8rem",
    backgroundColor: "#002B53",
    color: "#fff",
    border: "none",
    fontSize: "18px",
    width: "100%",
  },
  modalBackground: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  forgotPasswordModal: {
    backgroundColor: "#fff",
    padding: "3rem",
    borderRadius: "8px",
    width: "80%",
    maxWidth: "450px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
}));
const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],
});
const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});
const Warehouse = ({ open, handleClose, currentState}) => {
  const handleFormSubmit = (values) => {
    try {
      stockServices
        .createwareHouse(values)
        .then(({ data }) => {
          if (data.result === "success") {
            showSuccessAlert("Warehouse created successfully done");
            handleClose();
          }
        })
        .catch((error) =>
          showErrorAlert(
            error.response?.data?.data.message ||
              "An error occurred while editing warehouse"
          )
        );
    } catch (error) {
      throw error;
    }
  };

  const initialValues = {
    fc_code: currentState?.title === "missingFc"? currentState?.currentState:"",
    state: currentState?.title === "missingState"? currentState?.currentState:"",
    master_state: "",
    region: "",
    region_code: "",
    day: "",
  };
console.log(currentState);
  console.log(initialValues);


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "590px",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "1rem",
        }}
      >
        <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Warehouse</Typography>
          <IoMdClose
            size={"25px"}
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <hr />
        <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
          {({ values, handleSubmit, handleChange }) => (
            <form style={{ marginLeft: "6rem" }}>
              <FormRow>
                <Label htmlFor="Fc_Code">Fc Code</Label>
                <TextField
                  type="text"
                  id="Fc_Code"
                  name="fc_code"
                  value={values.fc_code}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>

              <FormRow>
                <Label htmlFor="State">State</Label>
                <TextField
                  type="text"
                  id="State"
                  name="state"
                  value={values.state}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="master_state">Mater State</Label>
                <TextField
                  type="text"
                  id="master_state"
                  name="master_state"
                  value={values.master_state}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Region">Region</Label>
                <TextField
                  type="text"
                  id="Region"
                  name="region"
                  value={values.region}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Region">Region Code</Label>
                <TextField
                  type="text"
                  id="Region"
                  name="region_code"
                  value={values.region_code}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Day">Day</Label>
                <TextField
                  type="text"
                  id="Day"
                  name="day"
                  value={values.day}
                  size="small"
                  onChange={handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <CustomStyledButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </CustomStyledButton>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default Warehouse;
