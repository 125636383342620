export const SKU_FILTER = "SKU_FILTER";
export const FETCH_SKU_FILTER = "FETCH_SKU_FILTER";

export const FCCODE_FILTER = "FCCODE_FILTER";
export const FETCH_FCCODE_FILTER = "FETCH_FCCODE_FILTER";

export const DISPOSITION_FILTER = "DISPOSITION_FILTER";
export const FETCH_DISPOSITION_FILTER = "FETCH_DISPOSITION_FILTER";

export const STATE_FILTER = "STATE_FILTER";
export const FETCH_STATE_FILTER = "FETCH_STATE_FILTER";
