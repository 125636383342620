import React from "react";
import { Box, Pagination, Typography } from "@mui/material";
import { useUIContext } from "../../context/Basicproviders";
import { Textfieldbg } from "../../Style/Common.style";

const PageChange = ({ currentPage, totalPages, onChangePage }) => {
  const { setItemsPerPage, itemsPerPage } = useUIContext();
  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "0.5rem",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography>Show</Typography>
          <Textfieldbg
            id="filled-basic"
            variant="filled"
            value={itemsPerPage}
            onChange={(event) => setItemsPerPage(event.target.value)}
          />
        </Box>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={onChangePage}
          siblingCount={0}
          boundaryCount={2}
        />
      </Box>
    </>
  );
};

export default PageChange;
