import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import rootSaga from "./rootsaga";
import stockReducer from "./stock/stockReducer";
import shipmentReducer from "./shipment/shipmentReducer";
import warehouseReducer from "./warehouse/warehouseReducer";
import filterReducer from "./filter/filterReducer";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    stock: stockReducer,
    shipment: shipmentReducer,
    warehouse: warehouseReducer,
    filter: filterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(rootSaga);
export default store;
