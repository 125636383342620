import React, { useState, useContext } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  styled,
  Grid,
} from "@mui/material";
import { BasicContext } from "../../context/Basicproviders";
import { tokens } from "../../theme";
// import { Rolls } from "../../data/Mockdata";
import { IoMdClose } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";

const Colors = tokens();

const Permissionmodel = () => {
  const { show, handleCloseModal } = useContext(BasicContext);
  const [rows, setRows] = useState([]);
  const CustomStyledSaveButton = styled(Button)({
    backgroundColor: Colors.primary[100],
    color: Colors.white[100],
    textTransform: "capitalize",
    padding: " 0.6rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Colors.grey[200],
    },
  });

  return (
    <>
      <Modal
        open={show}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            bgcolor: Colors.white[100],
            transform: "translate(-50%, -50%)",
            p: 3,
            width: { xl: "55%", md: "65%", sm: "90%", xs: "90%" },
            maxHeight: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <FaArrowLeft />
            <Typography>Back</Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 20,
              cursor: "pointer",
            }}
          >
            <IoMdClose size={"25px"} onClick={handleCloseModal} />
          </Box>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", marginTop: "2rem", paddingLeft: "1rem" }}
          >
            Rolls And Permission
          </Typography>
          <Box sx={{ display: { xl: "flex", md: "flex" } }}>
            <Box style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="User Name"
                style={{
                  height: "2rem",
                  padding: "1.3rem",
                  borderRadius: "0.4rem",
                  border: "0.1rem solid grey",
                  margin: "1rem 0rem 0rem 1rem",
                }}
              />
              <Box>
                <CiSearch
                  style={{
                    position: "absolute",
                    top: "1.5rem",
                    right: "1rem",
                    fontSize: "1.5rem",
                  }}
                />
              </Box>
            </Box>
            <select
              style={{
                borderRadius: "0.4rem",
                height: "2.7rem",
                padding: "0.5rem",
                marginLeft: "1rem",
                margin: "1rem 0rem 0rem 1rem",
                border: "0.1rem solid grey",
              }}
            >
              <option>All</option>
              <option>Ten</option>
              <option>Twenty</option>
            </select>
          </Box>

          <Box
            sx={{
              display: { xl: "flex", md: "flex" },
              paddingLeft: { xl: "1rem", md: "1rem" },
              marginTop: "2rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-Between",
                  marginBottom: "1rem",
                }}
              >
                <Box sx={{ marginTop: "0.5rem" }}>
                  <Typography>Mostly Used</Typography>
                </Box>
                <Box>
                  {" "}
                  <Box style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Search Roll"
                      style={{
                        height: "2rem",
                        padding: "1rem",
                        borderRadius: "0.4rem",
                        border: "0.1rem solid grey",
                        background: Colors.primary[600],
                      }}
                    />
                    <Box>
                      <CiSearch
                        style={{
                          position: "absolute",
                          top: "0.4rem",
                          right: "0.8rem",
                          fontSize: "1.3rem",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "25rem",
                    height: "10rem",
                    overflowX: "auto",
                    background: Colors.primary[600],
                    paddingLeft: "0.6rem",
                    borderRadius: "0.7rem",
                  }}
                >
                  <Grid container spacing={1}>
                    {rows.map((row) => (
                      <Grid item xs={5} sm={5} md={4} lg={4}>
                        <Button
                          sx={{
                            backgroundColor: Colors.white[100],
                            color: Colors.black[100],
                            marginTop: "0.6rem",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            width: "7rem",
                          }}
                        >
                          {row.name}
                          <FaPlus sx={{ paddingLeft: "1.4rem" }} />
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: "0.6rem",
                marginTop: "1.5rem",
              }}
            >
              <Box sx={{ marginTop: "-1rem", marginBottom: "1rem" }}>
                <Typography>User Rolls</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "25rem",
                  height: "10rem",
                  overflowX: "auto",
                  background: Colors.primary[600],
                  paddingLeft: "0.6rem",
                  borderRadius: "0.7rem",
                }}
              >
                <Grid container spacing={1}>
                  {rows.map((row) => (
                    <Grid item xs={5} sm={5} md={4} lg={4}>
                      <Button
                        sx={{
                          backgroundColor: Colors.white[100],
                          color: Colors.black[100],
                          marginTop: "0.6rem",
                          fontWeight: 600,
                          textTransform: "capitalize",
                          width: "7rem",
                        }}
                      >
                        {row.name}
                        <FaPlus sx={{ paddingLeft: "1.4rem" }} />
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <CustomStyledSaveButton>Save Changes</CustomStyledSaveButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Permissionmodel;
