import axios from "axios";
import { PORT } from "../config/config";

const fetchSku = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/core/sku",
      {
        headers,
      }
    );
    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const fetchFccode = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/core/fc-codes",
      {
        headers,
      }
    );
    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const fetchDisposition = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/core/disposition",
      {
        headers,
      }
    );
    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const fetchState = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/core/states",
      {
        headers,
      }
    );
    if (response?.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export default {
  fetchSku,
  fetchFccode,
  fetchDisposition,
  fetchState,
};
