export const UPLOAD_LOCAL_STOCK = "UPLOAD_LOCAL_STOCK";
export const UPLOAD_FBA_STOCK = "UPLOAD_FBA_STOCK";
export const UPLOAD_SELL_STOCK = "UPLOAD_SELL_STOCK";

export const FETCH_LOCAL_REQUEST = "FETCH_LOCAL_REQUEST";
export const LOCAL_DATA = "LOCAL_DATA";
export const FETCH_LOCAL_REQUEST_FAILED = "FETCH_LOCAL_REQUEST_FAILED";

export const FETCH_FBA_REQUEST = "FETCH_FBA_REQUEST";
export const FBA_DATA = "FBA_DATA";

export const REQUEST_UPDATE_LOCAL = "REQUEST_UPDATE_LOCAL";
export const REQUEST_DELETE_LOCAL = "REQUEST_DELETE_LOCAL";

export const FBA_FILTER = "FBA_FILTER";
export const UNIQUE_FBA_KEY = "UNIQUE_FBA_KEY";

export const UPLOAD_LOAD_REQUEST = "UPLOAD_LOAD_REQUEST";
export const FAILED_LOAD_REQUEST = "FAILED_LOAD_REQUEST";

export const LOCAL_FILTER = "LOCAL_FILTER";
export const FBA_SEARCH_FILTER = "FBA_SEARCH_FILTER";
export const FETCH_MISSINGSKU = "FETCH_MISSINGSKU";
