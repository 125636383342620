import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useUIContext } from "../../context/Basicproviders";
import { Box} from "@mui/material";
import TitleHeader from "../globel/TitleHeader";
import Avatar from '@mui/material/Avatar';
import { tokens } from "../../theme";

const Mobilemenu = ({title}) => {
  const { setDrawerOpen } = useUIContext();
  const Colors = tokens();
  return (
    <>
      <Box sx={{display:"flex",justifyContent:"space-between",padding:"1rem 2rem",backgroundColor:Colors.white[100],height:"4.5rem"}}>
      <Avatar alt="Travis Howard" src="https://us.123rf.com/450wm/petunyia/petunyia1903/petunyia190300048/119763042-summer-outdoor-portrait-of-beautiful-happy-child.jpg?ver=6" />
        <TitleHeader title={title}/>
        <Box onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </Box>
      </Box>
    </>
  );
};

export default Mobilemenu;
