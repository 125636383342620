import React, { useEffect } from "react";
import { Box } from "@mui/material";
import AppDrawer from "./componet/appbar/Drawer/Index";
import Sidebar from "./componet/appbar/Sidebar";
import Mobilemenu from "./componet/appbar/Mobilemenu";
import { useCustomTheme } from "./Style/Common.style";
import { useNavigate } from "react-router";
import Topbar from "./componet/globel/Topbar";

const Main = ({ title, headerTitle }) => {
  const navigate = useNavigate();
  const ComponentToRender = title;
  const { matches } = useCustomTheme();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <AppDrawer />
      <Box className="app">
        {matches ? null : <Sidebar />}
        <main className="content">
          {matches ? <Mobilemenu title={headerTitle} /> : null}
          <Box className="app content">
            <Box className="content">
              {matches ? null : (
                <>
                  <Topbar title={headerTitle} />
                  <hr />
                </>
              )}
              <ComponentToRender />
            </Box>
          </Box>
        </main>
      </Box>
    </>
  );
};

export default Main;
