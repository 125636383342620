import axios from "axios";
import { API_URl } from "../config/config";
const userApiurl = API_URl + "/api/account/user/";
const authApiurl = API_URl + "/api/account/auth/";

const loginuser = async (values) => {
  const response = await axios.post(authApiurl + "login/", values);
  if (response.data) {
    return { data: response.data, status: response.status };
  }
};

const getloginUser = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const response = await axios.get(userApiurl + "me/", { headers });
  if (response.data) {
    return { data: response.data, status: response.status };
  }
};

//user management api
const createuser = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  try {
    const response = await axios.post(
      userApiurl,
      { ...data, schema_name: "test" },
      {
        headers,
      }
    );
    if (response.data) {
      return { data: response.data, status: response.status };
    }
  } catch (error) {
    throw error;
  }
};

const getusers = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  try {
    const response = await axios.get(userApiurl, { headers });
    if (response.data) {
      return { data: response.data, status: response.status };
    }
  } catch (error) {
    throw error;
  }
};

const deleteuser = async (id) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  try {
    const response = await axios.delete(userApiurl + id + "/", {
      headers,
    });
    if (response.data) {
      return { data: response.data, status: response.status };
    }
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (data, id) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  try {
    const response = await axios.patch(userApiurl + id + "/", data, {
      headers,
    });
    if (response.data) {
      return { data: response.data, status: response.status };
    }
  } catch (error) {
    throw error;
  }
};

const changePassword = async (data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  try {
    const response = await axios.post(authApiurl + "change-password/", data, {
      headers,
    });
    if (response.data) {
      return { data: response.data, status: response.status };
    }
  } catch (error) {
    throw error;
  }
};

export default {
  loginuser,
  getloginUser,
  createuser,
  getusers,
  deleteuser,
  resetPassword,
  changePassword,
};
