import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/authSaga";
import stockSaga from "./stock/stockSaga";
import shipmentSaga from "./shipment/shipmentSaga";
import warehouseSaga from "./warehouse/warehouseSaga";
import filterSaga from "./filter/filterSaga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(stockSaga),
    fork(shipmentSaga),
    fork(warehouseSaga),
    fork(filterSaga),
  ]);
}
