import React from "react";
import { Box , Typography} from "@mui/material";
import PageChange from "./Pagination";

const Bottombar = ({totalPages,currentPage,onChangePage}) => {
  return (
    <>
        <hr />
      <Box sx={{ mx: 3}}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"center", }}>
          <Typography>© 2024 LYROVO. All Rights Reserved</Typography>
          <PageChange totalPages={totalPages} currentPage={currentPage} onChangePage={onChangePage}/>
        </Box>
      </Box>
    </>
  );
};

export default Bottombar;
