import { useState, createContext, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        primary: {
          100: "#F25744",
          200: "#FFF0EE",
          300: "#FF9184",
          400: "#FFECEA",
          500: "#FFC3BC",
          600: "#FDE9E7",
        },
        grey: {
          100: "#6E6E6E",
          200: "#F25744",
          300: "#9D9D9D",
          400: "#F2F2F2",
          500: "#D9D9D9",
          600: "#F1F4F8",
        },
        black: {
          100: "#000",
          200: "#4F4F4F",
        },
        white: {
          100: "#000",
        },
        statusBg: {
          100: "#FEF2F2",
          200: "#ECFDF5",
        },
        statustext: {
          200: "#1E1E1E",
          100: "#991B1B",
        },
        black_font: {
          100: "#000",
        },
        header_fontcolor: {
          100: "#fff",
        },
        boxshadow: {
          100: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
          200: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
          300: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        },
      }
    : {
        primary: {
          100: "#f3dfdd",
          200: "#dea69f",
          300: "#eb5e28",
          400: "#f25744",
          500: "#fde9e7",
          600: "#3538CD",
          700: "#6941C6",
          800: "#175CD3",
        },
        grey: {
          100: "#6E6E6E",
          200: "#F25744",
          300: "#9D9D9D",
          400: "#F2F2F2",
          500: "#D9D9D9",
          600: "#EEF4FF",
          700: "#F9F5FF",
          800: "#EFF8FF",
        },
        black: {
          100: "#403d38",
          200: "#7e7e7e", //border
          300: "#374550",
          400: "#344054",
          500: "#e7eaee", // table strip color
          600: "#C0C0C0", //table border color
          700: "#F9FAFC", // user data color
        },
        white: {
          100: "#e2e2e2",
          200: "#d0d5dd",
          300: "#f2f2f2",
          400: "#ffffff",
        },
        statusBg: {
          100: "#FEF2F2",
          200: "#ECFDF5",
        },
        statustext: {
          100: "#B42318",
          200: "#027A48",
        },
        black_font: {
          100: "#000000",
        },
        header_fontcolor: {
          100: "#fff",
        },
        boxshadow: {
          100: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
          200: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
          300: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
          400: "1px 4px 5px 0 rgba(0, 0, 0, 0.25)",
          500: "0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08)",
        },
      }),
});

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.header_fontcolor[100],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.header_fontcolor[100],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
