import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  styled,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { IoMdClose } from "react-icons/io";
import { tokens } from "../../theme";
import userServices from "../../services/user.services";
import { Formik } from "formik";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import { useUIContext } from "../../context/Basicproviders";

const Colors = tokens();
const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],

});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});

const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const Createuser = ({ show, handleCloseModal, getUsers }) => {
  const { domain } = useUIContext();
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleFormSubmit = (values) => {
    try {
      userServices
        .createuser(values)
        .then(({ data }) => {
          if (data.result === "success") {
            showSuccessAlert("User created successfully done")
            handleCloseModal();
            getUsers();
          }
        })
        .catch((error) =>  showErrorAlert(error.response?.data?.data.message || "User not created"));
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
      <Modal
        open={show}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "590px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            padding: "1rem",

          }}
        >
          <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" >
              Create User
            </Typography>
            <IoMdClose
              size={"25px"}
              onClick={handleCloseModal}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
          <hr/>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => (
              <form style={{ marginLeft: "5.5rem" }}>
                <FormRow>
                  <Label htmlFor="username">User Name</Label>
                  <TextField
                    type="text"
                    id="username"
                    name="username"
                    value={values.username}
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px"
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="email">Email Id</Label>
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    size="small"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px"
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Label htmlFor="role">Roles</Label>
                  <TextField
                    select
                    id="role"
                    name="role"
                    size="small"
                    value={values.role || "admin"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.role && !!errors.role}
                    helperText={touched.role && errors.role}
                    sx={{
                      width: "22rem",
                      border: "1px solid black",
                      borderRadius: "8px"
                    }}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </TextField>
                </FormRow>
                <FormRow>
                  <Label htmlFor="role">Access</Label>
                  <Box
                    sx={{
                      width: "22rem",
                      border: "2px solid black",
                      background: "white",
                      padding: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "flex",
                      flexDirection: "column",  
                    }}
                  >
                    {domain.length > 0 ? domain.map((value) => (<FormControlLabel
                      sx={{ color: Colors.black[400] }}
                      control={
                        <Checkbox
                          color="default"
                          name="organizations"
                          onChange={handleChange}
                          value={value.id}
                        />
                      }
                      label={value.value}
                    />)) : null}
                  </Box>
                </FormRow>
                <FormRow>
                  <Label htmlFor="password">Password</Label>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    size="small"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={handleTogglePassword} sx={{ color: Colors.black[100] }}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "22rem",
                      border: "0.5px solid black",
                      borderRadius: "8px"
                    }}
                  />
                </FormRow>
                <CustomStyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Create user
                </CustomStyledButton>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
const checkoutSchema = yup.object().shape({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
  role: yup.string().required("Required"),
  email: yup.string().email("Invalid email format").required("Required"),
});
const initialValues = {
  username: "",
  email: "",
  role: "",
  password: "",
};
export default Createuser;
