import React from "react";
import { UploadBtnStyle } from "../../Style/Common.style";
import { RiRefreshLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import { HiOutlineDownload } from "react-icons/hi";
import {
  DownloadPdfbutton,
  VisuallyHiddenInput,
} from "../../Style/Common.style";
import { useUIContext } from "../../context/Basicproviders";
import { showErrorAlert } from "../../toast/toast";
import { Checkbox } from "@mui/material";
import { BpCheckedIcon, BpIcon } from "../../Style/Common.style";
import { downloadExcel } from "../../services/shipmenet.services";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import {
  UPLOAD_FBA_STOCK,
  UPLOAD_LOCAL_STOCK,
  UPLOAD_SELL_STOCK,
} from "../../redux/stock/stockAction";
import { UPLOAD_SHIPMENT_OBJECT } from "../../redux/shipment/shipmentAction";

const FileUploadComponent = ({ label, startIcon, prams }) => {
  const dispatch = useDispatch();

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      showErrorAlert("Please select a file.");
      return;
    }
    switch (label) {
      case "Local Stock":
        dispatch({ type: UPLOAD_LOCAL_STOCK, payload: file });
        break;
      case "FBA Stock":
        dispatch({ type: UPLOAD_FBA_STOCK, payload: file });
        break;
      case "Sell In":
        dispatch({ type: UPLOAD_SELL_STOCK, payload: file });
        break;
      case "Shipment object":
        dispatch({
          type: UPLOAD_SHIPMENT_OBJECT,
          payload: file,
          params: prams,
        });
        break;
      default:
        break;
    }
    event.target.value = "";
  };
  return (
    <UploadBtnStyle
      component="label"
      startIcon={startIcon}
      role={undefined}
      variant="contained"
      tabIndex={-1}
    >
      {label}
      <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
    </UploadBtnStyle>
  );
};
export const SellIn = () => {
  return <FileUploadComponent label="Sell In" startIcon={<FiUploadCloud />} />;
};

export const FBAStock = () => {
  const { setmissingSku } = useUIContext();

  return (
    <>
      <FileUploadComponent
        label="FBA Stock"
        startIcon={<FiUploadCloud style={{ marginLeft: "5px" }} />}
        setmissingSku={setmissingSku}
      />
    </>
  );
};

export const Localstock = () => {
  return (
    <FileUploadComponent
      label="Local Stock"
      startIcon={<FiUploadCloud style={{ marginLeft: "5px" }} />}
    />
  );
};

export const ShipmentObject = ({ label, prams }) => {
  return (
    <FileUploadComponent
      label={label}
      startIcon={<FiUploadCloud />}
      prams={prams}
    />
  );
};

export const Refresh = () => {
  return (
    <UploadBtnStyle>
      <RiRefreshLine size={"1.5rem"} />
    </UploadBtnStyle>
  );
};

export const DownloadPdf = ({ data, filename }) => {
  const { checkedRows, selectedRows } = useUIContext();

  const downloadExcel = () => {
    const displayNames = data.display_names;
    const keys = Object.keys(displayNames);
    const keysToDownload = ["id", ...keys];
    if (filename !== "local" && filename !== "FBA") {
      keysToDownload.push("shipment_status");
    }

    console.log("Keys to Download:", data);

    const rowsToUse =
      filename === "local" || filename === "FBA" ? selectedRows : checkedRows;

    const filteredData = (
      rowsToUse?.length > 0
        ? data.results.filter((item) => rowsToUse.includes(item.id))
        : data.results
    ).map((item) => {
      const filteredItem = {};

      if (filename === "local" || filename === "FBA") {
        if (keysToDownload.includes("product_name")) {
          filteredItem["product_name"] = item.product?.product_name || "";
        }
        if (keysToDownload.includes("sku")) {
          filteredItem["sku"] = item.product?.sku || "";
        }
        if (keysToDownload.includes("product_price")) {
          filteredItem["product_price"] = item.product?.product_price || 0.0;
        }
        if (keysToDownload.includes("fc_code")) {
          filteredItem["fc_code"] = item.warehouse?.fc_code || "";
        }
      }

      keysToDownload.forEach((key) => {
        if (
          key === "product_name" ||
          key === "product_price" ||
          ((filename === "local" || filename === "FBA") && key === "sku")
        ) {
          return;
        }
        filteredItem[key] = item[key];
      });
      Object.keys(filteredItem).forEach((key) => {
        if (
          key !== "sku" &&
          key !== "id" &&
          typeof filteredItem[key] === "string"
        ) {
          filteredItem[key] = filteredItem[key].toUpperCase();
        }
      });
      return filteredItem;
    });
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename + ".xlsx");
  };

  return (
    <DownloadPdfbutton
      startIcon={<HiOutlineDownload />}
      onClick={downloadExcel}
    >
      Download Excel
    </DownloadPdfbutton>
  );
};
export const DownloadInTransactionPdf = () => {
  return (
    <>
      <DownloadPdfbutton
        onClick={downloadExcel}
        startIcon={<HiOutlineDownload />}
      >
        Manifest File
      </DownloadPdfbutton>
    </>
  );
};

export const BpCheckbox = (props) => {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      indeterminate={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
};
