import { Box, Modal, Table, TableRow, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { ReceiveformButton, SpecialTextField } from "../../Style/Common.style";
import { useFilterMaster } from "../../redux/filter/filterReducer";
import { FaFileImport } from "react-icons/fa";
import shipmenetServices from "../../services/shipmenet.services";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import { useDispatch } from "react-redux";
import { FETCH_PROCESS_REQUEST } from "../../redux/shipment/shipmentAction";
import { useUIContext } from "../../context/Basicproviders";

const Label = styled("label")({
  marginBottom: "0.5rem",
  fontSize: "1.2rem",
  fontWeight: 500,
  width: "40%",
});

const ManualShipment = ({ openManualshipment, handleclose }) => {
  const { uniqueState, uniqueFccode, uniqueSku } = useFilterMaster();
  const { itemsPerPage, currentPage } = useUIContext();
  const dispatch = useDispatch();
  const [manualdata, setManualdata] = useState({
    sku: "",
    fc_code: "",
    state: "",
    processed_quantity: 0,
  });

  const handleChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setManualdata({ ...manualdata, [name]: selectedOption.value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManualdata({ ...manualdata, [name]: value });
  };

  const handleSubmit = () => {
    try {
      shipmenetServices
        .manualShipment(manualdata)
        .then((data) => {
          showSuccessAlert(data?.data?.message);
          handleclose();
          dispatch({
            type: FETCH_PROCESS_REQUEST,
            payload: "in_process",
            itemsPerPage: itemsPerPage,
            currentPage: currentPage,
          });
        })
        .catch((error) => showErrorAlert(error?.response?.data?.data?.message));
    } catch (error) {
      showErrorAlert(error?.response?.data?.data?.message);
    }
  };

  const mapOptions = (options) =>
    options.map((value) => ({ value, label: value }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F1F4F8",
      color: "#000",
      borderColor: state.isFocused ? "#80bdff" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#80bdff" : "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <>
      <Modal
        open={openManualshipment}
        onClose={handleclose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "590px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "1rem",
          }}
        >
          <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Manual Shipment</Typography>
            <IoMdClose
              size={"25px"}
              onClick={handleclose}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>

          <Box
            sx={{
              padding: "1rem",
              border: "1px solid #ccc",
              borderRadius: "0.5rem",
              margin: "1rem",
              backgroundColor: "#FDFDFD",
            }}
          >
            <Table
              sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <TableRow sx={{ display: "flex" }}>
                <Label>Sku</Label>
                <Select
                  name="sku"
                  style={{ width: "19rem", border: "2px solid red" }}
                  value={mapOptions(uniqueSku).find(
                    (option) => option.value === manualdata.sku
                  )}
                  onChange={handleChange}
                  options={mapOptions(uniqueSku)}
                  styles={customStyles}
                  placeholder="Select SKU"
                />
              </TableRow>
              <TableRow sx={{ display: "flex" }}>
                <Label>Fc Code</Label>
                <Select
                  name="fc_code"
                  value={mapOptions(uniqueFccode).find(
                    (option) => option.value === manualdata.fc_code
                  )}
                  onChange={handleChange}
                  options={mapOptions(uniqueFccode)}
                  styles={customStyles}
                  placeholder="Select FC Code"
                />
              </TableRow>
              <TableRow sx={{ display: "flex" }}>
                <Label>State</Label>
                <Select
                  name="state"
                  value={mapOptions(uniqueState).find(
                    (option) => option.value === manualdata.state
                  )}
                  onChange={handleChange}
                  options={mapOptions(uniqueState)}
                  styles={customStyles}
                  placeholder="Select State"
                />
              </TableRow>
              <TableRow>
                <Label>Processed Quantity</Label>
                <SpecialTextField
                  size="small"
                  id="outlined-disabled"
                  name="processed_quantity"
                  value={manualdata.processed_quantity}
                  onChange={handleInputChange}
                />
              </TableRow>
            </Table>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ReceiveformButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              <FaFileImport /> Submit
            </ReceiveformButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ManualShipment;
