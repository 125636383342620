import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useUIContext } from "../../context/Basicproviders";
import { UploadBtnStyle } from "../../Style/Common.style";
import Searchbar from "../globel/Searchbar";
import {
  DownloadInTransactionPdf,
  DownloadPdf,
  ShipmentObject,
} from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import Bottombar from "../globel/Bottombar";
import Loader from "../globel/Loader";
import { showErrorAlert } from "../../toast/toast";
import Processloader from "../globel/Processloader";
import Shipmentprocess from "./Shipmentprocess";
import { useDispatch } from "react-redux";
import {
  FETCH_PROCESS_REQUEST,
  INTRANSACTION_SHIPTRANSACTION,
  SHIPMENT_FILTER,
  UNDO_INTRANSACTION,
} from "../../redux/shipment/shipmentAction";
import { useShipmentMaster } from "../../redux/shipment/shipmentReducer";
import { useStockMaster } from "../../redux/stock/stockReducer";
import { useNavigate } from "react-router-dom";

const Intransaction = () => {
  const {
    setEditingRow,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    checkedRows,
    setCheckedRows,
    selectedDomain,
    processLoader,
    selectAll,
    setSelectAll,
    shipmentDic,
    setshipmentdic,
    searchValue,
    setSearchvalue,
  } = useUIContext();

  const [updatedShipment, setUpdatedShipment] = useState({});

  const dispatch = useDispatch();
  const { processData } = useShipmentMaster();
  const { indicater } = useStockMaster();
  const navigate = useNavigate();

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleCheckboxChange = (row) => {
    const isChecked = checkedRows.includes(row.id);
    const updatedcheckedRows = isChecked
      ? checkedRows.filter((checkedRow) => checkedRow !== row.id)
      : [...checkedRows, row.id];
      setEditingRow(null);
    setCheckedRows(updatedcheckedRows);
    setSelectAll(updatedcheckedRows.length === processData?.results?.length);
    if (isChecked) {
      setshipmentdic((prevdata) =>
        prevdata.filter((item) => item.id !== row.id)
      );
    } else {
      if (row.shipment_number) {
        const { shipment_number } = row;
        setshipmentdic((prevSelectedRows) => [
          ...prevSelectedRows,
          {
            ...row,
            shipment_status: "shipment_in_transaction",
          },
        ]);
      } else {
        setshipmentdic((prevSelectedRows) => [
          ...prevSelectedRows,
          { ...row, shipment_status: "shipment_in_transaction" },
        ]);
      }
    }
  };

  const handleSelectAllChange = () => {
    const allChecked = !selectAll;
    setSelectAll(allChecked);
    setCheckedRows(
      allChecked ? processData?.results?.map((row) => row.id) : []
    );
    if (allChecked) {
      setshipmentdic(
        processData?.results?.map((row) => ({
          ...row,
          shipment_status: "shipment_in_transaction",
        }))
      );
    } else {
      setshipmentdic([]);
    }
  };

  const handleInputChange = (row, fieldName, newValue) => {
    setUpdatedShipment((prevShipment) => ({
      ...prevShipment,
      [fieldName]: newValue,
      id: row?.id,
      shipment_status: row?.shipment_status,
    }));

    setshipmentdic((prevData) =>
      prevData.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      })
    );
  };
  
  const fetchIntransction = () => {
    dispatch({
      type: FETCH_PROCESS_REQUEST,
      payload: "in_transaction",
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
  };

  const moveIntransction = () => {
    console.log(shipmentDic);
    const hasNullShipmentNumber = shipmentDic.some(
      (item) => item.shipment_number === null
    );

    if (checkedRows.length === 0) {
      showErrorAlert("Please select rows data");
    } else if (hasNullShipmentNumber) {
      showErrorAlert("Shipment number is mandatory. Please fill it.");
    } else {
      dispatch({
        type: INTRANSACTION_SHIPTRANSACTION,
        payload: shipmentDic,
        href: navigate,
      });
    }
  };

  const undoIntransction = (row) => {
    dispatch({
      type: UNDO_INTRANSACTION,
      payload: [{ ...row, shipment_status: "in_process" }], itemsPerPage,
      currentPage,
    });
  };

  useEffect(() => {
    if (searchValue !== null && selectedDomain !== null) {
      if (searchValue) {
        dispatch({
          type: FETCH_PROCESS_REQUEST,
          payload: "in_transaction",
          itemsPerPage: itemsPerPage,
          currentPage: currentPage,
          sku: searchValue,
        });
      } else {
        setCheckedRows([]);
        setEditingRow(null);
        setSelectAll(false);
        setshipmentdic([]);
        fetchIntransction();
      }
    }
  }, [searchValue, selectedDomain, itemsPerPage, currentPage]);

  useEffect(() => {
    setSearchvalue("");
  }, []);

  return (
    <>
      {indicater && <Loader />}
      <Box
        sx={{
          height: "78vh",
          overflowY: "auto",
          padding: "0rem 2rem 0rem",
          width: "86vw",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Searchbar placeholder="Search sku" />
          <Box sx={{ display: "flex", gap: 1 }}>
            <ShipmentObject label="Shipment object" prams="in_transist_file" />
            <DownloadPdf data={processData} filename="Intransaction" />
            <DownloadInTransactionPdf />
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "2rem",
            height: "65vh",
          }}
        >
          <Shipmentprocess
            tabledata={processData}
            handleCheckboxChange={handleCheckboxChange}
            handleSelectAllChange={handleSelectAllChange}
            undoProcess={undoIntransction}
            handleInputChange={handleInputChange}
            updatedShipment={updatedShipment}
            setUpdatedShipment={setUpdatedShipment}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {processLoader ? (
            <Processloader />
          ) : (
            <UploadBtnStyle
              sx={{ padding: "0.5rem 3rem" }}
              onClick={moveIntransction}
            >
              Shipment In-Transaction
            </UploadBtnStyle>
          )}
        </Box>
      </Box>
      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(processData?.count / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default Intransaction;
