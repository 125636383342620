import React from "react";
import { Box, Typography, Grid, Button, styled } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import { tokens } from "../../theme";
import { useCustomTheme } from "../../Style/Common.style";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";

const Colors = tokens();
const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[400],
  border: `1px solid ${Colors.black[400]}`,
  textTransform: "capitalize",
  marginTop: "1rem",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: Colors.white[400],
    color: Colors.black[400],
  },
});

const Report = () => {
  const reportBox = [
    {
      id: 1,
      name: "Shipment Alter",
    },
    {
      id: 2,
      name: "Shipment Asvf",
    },
    {
      id: 3,
      name: "Shipment Alter",
    },
    {
      id: 4,
      name: "Shipment Alter",
    },
    {
      id: 5,
      name: "Shipment Alter",
    },
    {
      id: 6,
      name: "Shipment Alter",
    },
    {
      id: 7,
      name: "Shipment Alter",
    },
    {
      id: 8,
      name: "Shipment Alter",
    },
  ];
  const Colors = tokens();
  const { matches } = useCustomTheme();

  return (
    <>
      <Box
        sx={{
          height: "80vh",
          overflowY: "auto",
          padding: matches ? "2rem 1rem 0rem" : "2rem 3rem 0rem",
        }}
      >
        <Grid
          container
          rowSpacing={6}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            height: "80vh",
            overflow: "auto",
            padding: {
              xl: "0rem 10rem",
              md: "0rem 0rem",
              sm: "0rem 0rem",
              xs: "0rem 0rem",
            },
          }}
        >
          {reportBox.map((item, index) => (
            <Grid item xl={3} md={4} sm={4} xs={12} key={index}>
              <Box
                sx={{
                  textAlign: "center",
                  boxShadow: Colors.boxshadow[100],
                  padding: "6rem 0rem",
                  borderRadius: "0.5rem",
                  background: Colors.white[400],
                  border: `1px solid ${Colors.black[600]}`,
                }}
              >
                <Typography sx={{ fontWeight: 600}} variant="h5">
                  {item.name}
                </Typography>
                <CustomStyledButton>
                  Export to
                  <FaRegFilePdf className="mx-2" />
                  <RiFileExcel2Line />
                </CustomStyledButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default Report;
