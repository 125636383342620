import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useUIContext } from "../../context/Basicproviders";
import Searchbar from "../globel/Searchbar";
import { DownloadPdf } from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import Bottombar from "../globel/Bottombar";
import shipmenetServices from "../../services/shipmenet.services";
import { showErrorAlert } from "../../toast/toast";
import Shipmentprocess from "./Shipmentprocess";
import { UploadBtnStyle } from "../../Style/Common.style";
import { useNavigate } from "react-router";
import Receiveform from "../modals/Receiveform";
import { useDispatch } from "react-redux";
import {
  FETCH_PROCESS_REQUEST,
  PROCESS_RECEIVED
} from "../../redux/shipment/shipmentAction";
import { useShipmentMaster } from "../../redux/shipment/shipmentReducer";
import { DatePicker } from "antd";
import moment from "moment";

const ShipmentTransaction = () => {
  const {
    setEditingRow,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    selectedDomain,
    checkedRows,
    setCheckedRows,
    setSelectAll,
    selectAll,
    setshipmentdic,
    shipmentDic,
    searchValue,
  } = useUIContext();
  const { processData } = useShipmentMaster();
  const [errordata, seterrordata] = useState([]);
  const [receiveformopen, setReceiveformopen] = useState(false);
  const [checkSku, setCheckSku] = useState([]);
  const [checkShipmentNumber, setCheckShipmentNumber] = useState([]);
  const [checkFcCode, setcheckFcCode] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [updatedShipment, setUpdatedShipment] = useState({});
  const [showShipmentError, setShowShipmentError] = useState(false);
  const [selectedErrorType, setSelectedErrorType] = useState({});
  const [formValid, setFormValid] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenreceivedform = () => {
    if (checkedRows.length === 0 || shipmentDic?.length === 0) {
      showErrorAlert("Please select rows data");
    } else {
      setReceiveformopen(true);
      try {
        shipmenetServices
          .getReceivedDate(checkSku, checkFcCode, checkShipmentNumber)
          .then((data) => {
            const updatedErrorData = shipmentDic.map((shipmentItem) => {
              const matchingEntry = data?.data.find(
                (entry) =>
                  entry.sku === shipmentItem.sku &&
                  entry.fc_code === shipmentItem.fc_code
              );

              if (matchingEntry) {
                return {
                  ...shipmentItem,
                  received_quantity: matchingEntry.received_quantity,
                  shipment_received_date: matchingEntry.received_date,
                  entry_received_data: matchingEntry.received_date,
                };
              } else {
                return shipmentItem;
              }
            });
            setshipmentdic(updatedErrorData);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseReceivedform = () => {
    setCheckedRows([]);
    setReceiveformopen(false);
    setshipmentdic([]);
    setSelectedErrorType([]);
    seterrordata([]);
  };  

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleInputChange = (row, fieldName, newValue) => {
    // Update the updatedShipment state
    setUpdatedShipment((prevShipment) => ({
      ...prevShipment,
      [fieldName]: newValue,
    }));
        // Update the shipmentdic state
    setshipmentdic((prevData) =>
      prevData.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      })
    );
    // Update the errordata state if the field is 'error_quantity'
    if (fieldName === 'error_quantity') {
      seterrordata((prevData) =>
        prevData.map((item) =>
          item.shipment_id === row.id
            ? {
                ...item,
                error_quantity: newValue,
              }
            : item
        )
      );
    }
  };

  const handleCheckboxChange = (row) => {
    const isChecked = checkedRows.includes(row.id);
    const updatedcheckedRows = isChecked
      ? checkedRows.filter((checkedRow) => checkedRow !== row.id)
      : [...checkedRows, row.id];
      setEditingRow(null);
    setCheckedRows(updatedcheckedRows);
    setSelectAll(updatedcheckedRows.length === processData?.results?.length);
    isChecked
      ? setshipmentdic((prevdata) =>
          prevdata.filter((item) => item.id !== row.id)
        )
      : setshipmentdic((prevSelectedRows) => [
          ...prevSelectedRows,
          { ...row, shipment_status: "received" },
        ]);
    if (!isChecked) {
      // seterrordata((prevData) => [
      //   ...prevData,
      //   {
      //     shipment_number:row.shipment_number,
      //     shipment_id: row.id,
      //     sku: row.sku,
      //     fc_code: row.fc_code,
      //     error_quantity: row.error_quantity
      //   },
      // ]);
      setCheckSku((prevCheckSku) => [...prevCheckSku, row.sku]);
      setcheckFcCode((prevCheckFccode) => [...prevCheckFccode, row.fc_code]);
      setCheckShipmentNumber((prevCheckNumber) => [
        ...prevCheckNumber,
        row.shipment_number,
      ]);
    } else {
      seterrordata((prevData) =>
        prevData.filter((item) => item.shipment_id !== row.id)
      );
      setCheckSku((prevCheckSku) =>
        prevCheckSku.filter((sku) => sku !== row.sku)
      );
      setcheckFcCode((prevCheckFccode) =>
        prevCheckFccode.filter((fc_code) => fc_code !== row.fc_code)
      );
      setCheckShipmentNumber((prevCheckNumber) =>
        prevCheckNumber.filter(
          (shipment_number) => shipment_number !== row.shipment_number
        )
      );
    }
  };

  const handleSelectAllChange = () => {
    const allChecked = !selectAll;
    setSelectAll(allChecked);
    setCheckedRows(
      allChecked ? processData?.results?.map((row) => row.id) : []
    );
    setcheckFcCode(
      allChecked ? processData?.results?.map((row) => row.fc_code) : []
    );
    setCheckSku(allChecked ? processData?.results?.map((row) => row.sku) : []);
    setCheckShipmentNumber(
      allChecked ? processData?.results?.map((row) => row.shipment_number) : []
    );
    if (allChecked) {
      const newObjects =
        processData?.results?.map((row) => ({
          shipment_id: row.id,
          sku: row.sku,
          fc_code: row.fc_code,
        })) || [];

      seterrordata((prevData) => [...prevData, ...newObjects]);
    } else {
      seterrordata([]);
    }
    if (allChecked) {
      setshipmentdic(
        processData?.results?.map((row) => ({
          ...row,
          shipment_status: "received",
        }))
      );
      seterrordata(shipmentDic.map((row) => ({ shipment_id: row.id })));
    } else {
      setshipmentdic([]);
    }
  };

  const handleErrordata = (row, fieldName, newValue) => {  
    seterrordata((prevData) => {
      return prevData.map((item) => {
        if (item.shipment_id === row.id) {
          let updatedItem = {
            ...item,
            [fieldName]: newValue,
          };
  
          if (fieldName === "error_data_type" && (newValue === "seller_data" || newValue === "amazon_data")) {
            updatedItem.error = true;
          }
          return updatedItem;
        }
        return item;
      });
    });
  };

  const fetchData = () => {
    dispatch({
      type: FETCH_PROCESS_REQUEST,
      payload: "shipment_in_transaction",
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
  };
  const handlechangeDate = (dates, dateStrings) => {
    if (!dateStrings) {
      fetchData();
    } else {
      dispatch({
        type: FETCH_PROCESS_REQUEST,
        payload: "shipment_in_transaction",
        itemsPerPage: itemsPerPage,
        currentPage: currentPage,
        date: moment(dateStrings).format("DD-MM-YYYY"),
      });
    }
  };
  const moveReceived = () => {
    const filteredShipmentDic = shipmentDic.filter((item) =>
      filteredData.some((filteredItem) => filteredItem.id === item.id)
    );

    const hasNullReceivedDate = filteredShipmentDic.some(
      (item) => item.shipment_received_date === null
    );

    if (hasNullReceivedDate) {
    } else {
      const payloadWithoutEntryReceivedDate = filteredShipmentDic.map(
        (item) => {
          const newItem = { ...item };
          delete newItem.entry_received_date;
          return newItem;
        }
      );
      
      dispatch({
        type: PROCESS_RECEIVED,
        payload: payloadWithoutEntryReceivedDate,
        errorData: errordata,
        searchValue: searchValue,
        itemsPerPage:itemsPerPage,
        currentPage:currentPage,
        // href: navigate,
      });
      handleCloseReceivedform();
      setCheckedRows([]);
      setFilteredData([]);
      seterrordata([]);
      setSelectedErrorType([]);
    }
  };

  // const validateForm = () => {
  //   let isValid = true;
  //   console.log("call validate");
    
  //   shipmentDic.forEach((row) => {
  //     if (showShipmentError[row.id]) {
  //       const errorType = selectedErrorType[row.id];
  //       console.log(errorType);
        
  //       if (
  //         (errorType === 'seller_data' && !errordata.find(item => item.shipment_id === row.id)?.notes) ||
  //         (errorType === 'amazon_data' && (!errordata.find(item => item.shipment_id === row.id)?.claim_name || !errordata.find(item => item.shipment_id === row.id)?.claim_id) || !errordata.find(item => item.shipment_id === row.id)?.notes)
  //       ) {
  //         isValid = false;
  //         console.log(isValid);
          
  //       }
  //     }
  //   });

  //   setFormValid(isValid);
  //   return isValid;
  // };

  // const handleSubmit = () => {
  //   if (validateForm()) {
  //     moveReceived();
  //   }
  // };

  useEffect(() => {
    if (searchValue !== null && selectedDomain !== null) {
      if (searchValue) {
        dispatch({
          type: FETCH_PROCESS_REQUEST,
          payload: "shipment_in_transaction",
          itemsPerPage: itemsPerPage,
          currentPage: currentPage,
          sku: searchValue,
        });
      } else {
        fetchData();
        setCheckedRows([]);
        setEditingRow(null);
        setSelectAll(false);
        setshipmentdic([]);
        setFilteredData([]);
      }
    }
  }, [searchValue, selectedDomain, currentPage, itemsPerPage]);

  useEffect(() => {
    if (processData?.results) {
      const filtered = processData?.results?.filter(
        (item) =>
          item?.sku?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.shipment_number
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchValue, processData]);

  return (
    <>
      {/* {loading ? <Loader /> : null} */}
      <Box
        sx={{
          height: "78vh",
          overflowY: "auto",
          padding: "0rem 2rem 0rem",
          width: "86vw",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Searchbar placeholder="Search sku" />
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box>
              <DatePicker
                name="delivery_date"
                onChange={handlechangeDate}
                style={{
                  height: "40px",
                  width: "200px",
                  border: "1px solid #7e7e7e",
                }}
              />
            </Box>
            <DownloadPdf data={processData} filename="shipmentinTransaction" />
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "2rem",
            height: "65vh",
          }}
        >
          <Shipmentprocess
            tabledata={processData}
            handleCheckboxChange={handleCheckboxChange}
            handleSelectAllChange={handleSelectAllChange}
            undoProcess=""
            handleInputChange={handleInputChange}
            updatedShipment={updatedShipment}
            setUpdatedShipment={setUpdatedShipment}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <UploadBtnStyle
            sx={{ padding: "0.5rem 3rem" }}
            onClick={handleOpenreceivedform}
          >
            Received
          </UploadBtnStyle>
        </Box>
      </Box>
      <Receiveform
        receiveform={receiveformopen}
        handleClose={handleCloseReceivedform}
        handleInputChange={handleInputChange}
        moveReceived={moveReceived}
        handleErrordata={handleErrordata}
        errordata={errordata}
        showShipmentError={showShipmentError}
        setShowShipmentError={setShowShipmentError}
        shipmentDic={shipmentDic}
        setshipmentdic={setshipmentdic}
        seterrordata={seterrordata}
        selectedErrorType={selectedErrorType}
        setSelectedErrorType={setSelectedErrorType}
      />
      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(processData?.count / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default ShipmentTransaction;
