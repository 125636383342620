import React from "react";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { Box, Typography, TextField, styled, Button } from "@mui/material";
import { tokens } from "../../theme";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import stockServices from "../../services/stock.services";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { FETCH_PRODUCT_REQUEST } from "../../redux/warehouse/warehouseAction";
const Colors = tokens();

const CustomStyledButton = styled(Button)({
  backgroundColor: Colors.black[400],
  color: Colors.white[100],
  textTransform: "capitalize",
  margin: "1rem 6rem",
  fontSize: "1rem",

  "&:hover": {
    backgroundColor: Colors.black[400],
  },
});

const Label = styled("label")({
  display: "block",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  fontWeight: 500,
  color: Colors.black[100],
});

const FormRow = styled("div")({
  marginBottom: "1rem",
  alignItems: "center",
});
const EditProduct = ({ handleClose, open, formValues }) => {
  const dispatch = useDispatch();
  const handleUpdateSave = async (values, { resetForm }) => {
    try {
      const data = await stockServices.productupdate(formValues.id, values);
      if (data.result === "success") {
        showSuccessAlert(" product successfully updated");
        handleClose();
        dispatch({ type: FETCH_PRODUCT_REQUEST });
      }
    } catch (error) {
      showErrorAlert(
        error.response?.data?.data.message ||
          "An error occurred while updating product"
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "590px",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" mb={2}>
            Edit product Data
          </Typography>
          <IoMdClose
            size={"25px"}
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <hr />
        <Formik onSubmit={handleUpdateSave} initialValues={formValues}>
          {(formikProps) => (
            <form
              style={{ marginLeft: "5rem" }}
              onSubmit={formikProps.handleSubmit}
            >
              <FormRow>
                <Label htmlFor="sku">Sku</Label>
                {formikProps.values.sku}
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon">Cartoon</Label>
                <TextField
                  type="text"
                  id="cartoon"
                  name="cartoon"
                  size="small"
                  value={formikProps.values.cartoon}
                  onChange={formikProps.handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Pieces">Cartoon Pieces</Label>
                <TextField
                  type="text"
                  id="cartoon_pieces"
                  name="cartoon_pieces"
                  size="small"
                  value={formikProps.values.cartoon_pieces}
                  onChange={formikProps.handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Length">Cartoon Length</Label>
                <TextField
                  type="text"
                  id="cartoon_length"
                  name="cartoon_length"
                  size="small"
                  value={formikProps.values.cartoon_length}
                  onChange={formikProps.handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Weight">Cartoon Weight</Label>
                <TextField
                  type="text"
                  id="cartoon_weight"
                  name="cartoon_weight"
                  size="small"
                  value={formikProps.values.cartoon_weight}
                  onChange={formikProps.handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="cartoon_width">cartoon width</Label>
                <TextField
                  type="text"
                  id="cartoon_width"
                  name="cartoon_width"
                  size="small"
                  value={formikProps.values.cartoon_width}
                  onChange={formikProps.handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <FormRow>
                <Label htmlFor="Cartoon_Height">Cartoon Height</Label>
                <TextField
                  type="text"
                  id="cartoon_height"
                  name="cartoon_height"
                  size="small"
                  value={formikProps.values.cartoon_height}
                  onChange={formikProps.handleChange}
                  sx={{
                    width: "22rem",
                    border: "0.5px solid black",
                    borderRadius: "8px",
                  }}
                />
              </FormRow>
              <CustomStyledButton
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </CustomStyledButton>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
const checkoutSchema = yup.object().shape({
  sku: yup.string().required("Required"),
  cartoon: yup.string().required("Required"),
  cartoon_pieces: yup.string().required("Required"),
  cartoon_length: yup.string().required("Required"),
  cartoon_weight: yup.string().required("Required"),
  cartoon_width: yup.string().required("Required"),
  cartoon_height: yup.string().required("Required"),
});

export default EditProduct;
