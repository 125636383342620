import React from 'react'
import { Box } from '@mui/material'
import { ThreeDots } from 'react-loader-spinner'

const Processloader = () => {
    return (
        <>
            <Box sx={{ }}>
                <ThreeDots
                    visible={true}
                    height="70"
                    width="70"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    color="#F25744" />
            </Box>
        </>
    )
}

export default Processloader