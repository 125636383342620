import axios from "axios";
import { PORT } from "../config/config";
import { showErrorAlert } from "../toast/toast";

const getCurrentMonthAndYear = () => {
  const currentDate = new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
  const year = currentDate.getFullYear().toString();
  return `${month}-${year}`;
};

//worng shipment
const wrongShipmentdataApi = async (itemsPerPage, currentPage) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/sales/get-wrong-shipped/",
      {
        headers,
        params: {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
//missing fccode and sku
const missingFcProduct = async (itemsPerPage, currentPage) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/non-product-and-fc_codes?missing_fc_codes=true`,
      {
        headers,
        params: {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//missing SKU
const missingSku = async (itemsPerPage, currentPage) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/non-product-and-fc_codes?missing_product=true`,
      {
        headers,
        params: {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

// missing state
const missingState = async (itemsPerPage, currentPage) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/sales/get-non-warehouse/`,
      {
        headers,
        params: {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//missing Non Sellable Fba
const missingDisposition = async (itemsPerPage, currentPage) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/non-sellable-fba`,
      {
        headers,
        params: {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//monthly sales
const missingMonthlysales = async (start_date, end_date) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  let monthYearParam;

  // if (selectedDate) {
  //   const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = selectedDate.getFullYear().toString();
  //   monthYearParam = `${month}-${year}`;
  // } else {
  //   const currentDate = new Date();
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = currentDate.getFullYear().toString();
  //   monthYearParam = `${month}-${year}`;
  // }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/sales/get-monthly-sales/`,
      {
        headers,
        params: {
          start_date: start_date,
          end_date: end_date,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
//storage fees
const storageFees = async (selectedDate) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  let monthYearParam;
  if (selectedDate) {
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = selectedDate.getFullYear().toString();
    monthYearParam = `${month}-${year}`;
  } else {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    monthYearParam = `${month}-${year}`;
  }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/reports/storage-fees/monthly-storage-fees/?month_year=${monthYearParam}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const getStateMonthlysales = async (state, start_date, end_date) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  let monthYearParam;
  // if (selectedDate) {
  //   const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = selectedDate.getFullYear().toString();
  //   monthYearParam = `${month}-${year}`;
  // } else {
  //   const currentDate = new Date();
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = currentDate.getFullYear().toString();
  //   monthYearParam = `${month}-${year}`;
  // }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/sales/get-state-monthly-sales/?start_date=${start_date}&end_date=${end_date}&state=${state}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getStateStoragefees = async (state, selectedDate) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  let monthYearParam;
  if (selectedDate) {
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = selectedDate.getFullYear().toString();
    monthYearParam = `${month}-${year}`;
  } else {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    monthYearParam = `${month}-${year}`;
  }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/reports/storage-fees/get-state-monthly-fees/?month_year=${monthYearParam}&state=${state}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const overCharged = async (sku) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/over-charged/`,
      {
        headers,
        params: {
          sku: sku,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const uploadStorageFee = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.post(
      `http://${domainName?.value}.${PORT}/api/reports/storage-fees/`,
      formData,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
//download excel
const downloadwrongShipmentdata = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}` + "/api/sales/get-wrong-shipped/",
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const downloadFcProduct = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/non-product-and-fc_codes?missing_fc_codes=true`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const downloadSku = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/non-product-and-fc_codes?missing_product=true`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const downloadState = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/sales/get-non-warehouse/`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const downloadDisposition = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/non-sellable-fba`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const downloadMonthlysales = async (start_date, end_date) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  let monthYearParam;
  // if (selectedDate) {
  //   const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = selectedDate.getFullYear().toString();
  //   monthYearParam = `${month}-${year}`;
  // } else {
  //   const currentDate = new Date();
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = currentDate.getFullYear().toString();
  //   monthYearParam = `${month}-${year}`;
  // }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/sales/download-excel/?start_date=${start_date}&end_date=${end_date}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//monthly sales
const downloadStoragefee = async (selectedDate) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  let monthYearParam;
  if (selectedDate) {
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = selectedDate.getFullYear().toString();
    monthYearParam = `${month}-${year}`;
  } else {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    monthYearParam = `${month}-${year}`;
  }
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/reports/storage-fees/download-excel/?month_year=${monthYearParam}`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
const downloadOvercharge = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/stocks/fba/download-excel/`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

//Last uploaded Sales Date
const lastUploadSales = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const domainName = JSON.parse(localStorage.getItem("domain"));
  try {
    const response = await axios.get(
      `http://${domainName?.value}.${PORT}/api/sales/last-uploaded-sales-date`,
      {
        headers,
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export default {
  missingFcProduct,
  missingSku,
  missingState,
  missingDisposition,
  downloadFcProduct,
  downloadSku,
  downloadState,
  downloadDisposition,
  wrongShipmentdataApi,
  downloadwrongShipmentdata,
  missingMonthlysales,
  downloadMonthlysales,
  storageFees,
  downloadStoragefee,
  uploadStorageFee,
  getStateMonthlysales,
  getStateStoragefees,
  overCharged,
  downloadOvercharge,
  lastUploadSales,
};
