import React from 'react'
import { Box } from '@mui/material'
import { ColorRing } from 'react-loader-spinner'

const Loader = () => {
  return (
    <>
      <Box sx={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: 9999, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#F25744", "#F25744", "#F25744", "#F25744", "#F25744"]}
        />{" "}
      </Box>
    </>
  )
}

export default Loader