import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useUIContext } from "../../context/Basicproviders";
import {
  StyledTableCell,
  TableContainerStyled,
} from "../../Style/Common.style";
import { DownloadPdf } from "../globel/Uploadstock";
import { useStockMaster } from "../../redux/stock/stockReducer";

const ErrorFbastock = ({ missingskuModal, handleCloseFBAmissing }) => {
  const { isLaptopSize } = useUIContext();
  const { fbaMissingSku } = useStockMaster();
  const renderTableHeaders = () => {
    if (!fbaMissingSku || fbaMissingSku.length === 0) return null;
    const headers = Object.keys(fbaMissingSku[0]);
    return headers.map((header, index) => (
      <StyledTableCell key={index}>{header}</StyledTableCell>
    ));
  };
  const renderTableRows = () => {
    return fbaMissingSku?.map((item, index) => (
      <tr key={index}>
        <StyledTableCell>{index + 1}</StyledTableCell>
        {Object.values(item).map((value, idx) => (
          <StyledTableCell key={idx}>{value}</StyledTableCell>
        ))}
      </tr>
    ));
  };
  return (
    <>
      <Modal
        open={missingskuModal}
        onClose={handleCloseFBAmissing}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "1300px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "1rem",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5">FBA Missing SKU Data</Typography>
              <Box>
                <DownloadPdf data={fbaMissingSku} filename="FBA" />
                <IoMdClose
                  onClick={handleCloseFBAmissing}
                  style={{ cursor: "pointer", fontSize: "2rem" }}
                />
              </Box>
            </Box>
            <hr />
            <Box
              sx={{
                height: "78vh",
                overflowY: "auto",
                padding: "0rem 1rem 0rem",
                width: isLaptopSize ? "80vw" : "65vw",
              }}
            >
              <Box
                sx={{
                  borderRadius: "0.50rem",
                  padding: "1rem 0rem",
                  marginBottom: "2rem",
                  height: "65vh",
                }}
              >
                <TableContainerStyled>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">No</StyledTableCell>
                        {renderTableHeaders()}
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ zIndex: -1 }}>
                      {renderTableRows()}
                    </TableBody>
                  </Table>
                </TableContainerStyled>
              </Box>
            </Box>
          </Box>
          <ErrorFbastock />
        </Box>
      </Modal>
    </>
  );
};

export default ErrorFbastock;
