import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  FAILED_LOAD_REQUEST,
  FBA_DATA,
  FBA_FILTER,
  FETCH_FBA_REQUEST,
  FETCH_LOCAL_REQUEST,
  FETCH_LOCAL_REQUEST_FAILED,
  FETCH_MISSINGSKU,
  LOCAL_DATA,
  REQUEST_DELETE_LOCAL,
  REQUEST_UPDATE_LOCAL,
  UNIQUE_FBA_KEY,
  UPLOAD_FBA_STOCK,
  UPLOAD_LOAD_REQUEST,
  UPLOAD_LOCAL_STOCK,
  UPLOAD_SELL_STOCK,
} from "./stockAction";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import stockServices from "../../services/stock.services";
import Swal from "sweetalert2";

//local stock
function* fetchLocalstockData(action) {
  try {
    const { data } = yield call(
      stockServices.fetchLocalstock,
      action.itemsPerPage,
      action.currentPage
    );
    yield put({ type: LOCAL_DATA, payload: data });
  } catch (error) {
    yield put({ type: FETCH_LOCAL_REQUEST_FAILED });
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* uploadLocalstock(action) {
  try {
    yield put({ type: UPLOAD_LOAD_REQUEST });
    const { data } = yield call(stockServices.UploadLocalstock, action.payload);
    showSuccessAlert("Local Stock file uploaded successfully!");
    const itemsPerPage = 10;
    const currentPage = 1; 
    yield call(fetchLocalstockData, { itemsPerPage, currentPage });
    yield put({ type: FAILED_LOAD_REQUEST });
  } catch (error) {
    yield put({ type: FAILED_LOAD_REQUEST });
    showErrorAlert(error?.response?.data?.data?.message);
    const itemsPerPage = 10;
    const currentPage = 1;  
    yield call(fetchLocalstockData, { itemsPerPage, currentPage });
  }
}

function* updateLocalstock(action) {
  try {
    const { data } = yield call(
      stockServices.localStockupdate,
      action.id,
      action.payload
    );
    showSuccessAlert("Local stock successfully updated");
    yield put({ type: FETCH_LOCAL_REQUEST });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* deleteLocalstock(action) {
  try {
    const confirmed = yield call(() =>
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this item!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#ff0000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => result.isConfirmed)
    );
    if (confirmed) {
      const { data } = yield call(
        stockServices.localStockdelete,
        action.payload,
        action.deletetype
      );
      showSuccessAlert("Local stock deleted successfully done");
      yield put({
        type: FETCH_LOCAL_REQUEST,
        itemsPerPage: 10,
        currentPage: 0,
      });
    }
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//fba stock
function* fetchFbaData(action) {
  try {
    const { data } = yield call(
      stockServices.fbaStock,
      action.itemsPerPage,
      action.currentPage
    );
    yield put({ type: FBA_DATA, payload: data });
    yield put({ type: UNIQUE_FBA_KEY, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* uplaodFBAstock(action) {
  try {
    yield put({ type: UPLOAD_LOAD_REQUEST });
    const { data } = yield call(stockServices.Uploadlocalfba, action.payload);
    showSuccessAlert("FBA Stock file uploaded successfully!");
    yield put({ type: FETCH_MISSINGSKU, payload: data });
    // yield call(fetchFbaData);
  } catch (error) {
    yield put({ type: FAILED_LOAD_REQUEST });
    showErrorAlert(error?.response?.data?.data?.message);

    const itemsPerPage = 10;
    const currentPage = 1;  
    yield call(fetchFbaData, { itemsPerPage, currentPage });
  }
}

function* fbaFilter(action) {
  try {
    const { data } = yield call(
      stockServices.filterFba,
      action.fc_code,
      action.sku,
      action.disposition,
      action.fba_date
    );
    yield put({ type: FBA_DATA, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//upload sell data
function* uploadSellinstock(action) {
  try {
    yield put({ type: UPLOAD_LOAD_REQUEST });
    const { data } = yield call(stockServices.Uploadsell, action.payload);
    showSuccessAlert("Sell In file uploaded successfully!");
    yield put({ type: FAILED_LOAD_REQUEST });
  } catch (error) {
    yield put({ type: FAILED_LOAD_REQUEST });
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* stockSaga() {
  yield all([
    takeLatest(UPLOAD_LOCAL_STOCK, uploadLocalstock),
    takeLatest(UPLOAD_FBA_STOCK, uplaodFBAstock),
    takeLatest(UPLOAD_SELL_STOCK, uploadSellinstock),
    takeLatest(FETCH_LOCAL_REQUEST, fetchLocalstockData),
    takeLatest(FETCH_FBA_REQUEST, fetchFbaData),
    takeLatest(REQUEST_UPDATE_LOCAL, updateLocalstock),
    takeLatest(REQUEST_DELETE_LOCAL, deleteLocalstock),
    takeLatest(FBA_FILTER, fbaFilter),
  ]);
}

export default stockSaga;