import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useUIContext } from "../../context/Basicproviders";
import Bottombar from "../globel/Bottombar";
import Searchbar from "../globel/Searchbar";
import { DownloadPdf } from "../globel/Uploadstock";
import ProfileResetPassword from "../modals/ProfileResetPassword";
import { ToastContainer } from "react-toastify";
import Shipmentprocess from "./Shipmentprocess";
import { useShipmentMaster } from "../../redux/shipment/shipmentReducer";
import { useDispatch } from "react-redux";
import {
  EXPORT_PROCESS,
  FETCH_PROCESS_REQUEST
} from "../../redux/shipment/shipmentAction";
import { showErrorAlert } from "../../toast/toast";

const Receive = () => {
  const {
    setCurrentPage,
    currentPage,
    itemsPerPage,
    checkedRows,
    setCheckedRows,
    selectAll,
    setSelectAll,
    setshipmentdic,
    setEditingRow,
    selectedDomain,
    searchValue,
    shipmentDic,
    setSearchvalue,
  } = useUIContext();
  const { processData } = useShipmentMaster();
  const dispatch = useDispatch();
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleCheckboxChange = (row) => {
    const isChecked = checkedRows.includes(row.id);
    const updatedcheckedRows = isChecked
      ? checkedRows.filter((checkedRow) => checkedRow !== row.id)
      : [...checkedRows, row.id];
    setCheckedRows(updatedcheckedRows);
    setSelectAll(updatedcheckedRows.length === processData?.results?.length);
    isChecked
      ? setshipmentdic((prevdata) =>
          prevdata.filter((item) => item.id !== row.id)
        )
      : setshipmentdic((prevSelectedRows) => [
          ...prevSelectedRows,
          { ...row, shipment_status: "shipment_in_transaction" },
        ]);
  };

  const handleSelectAllChange = () => {
    const allChecked = !selectAll;
    setSelectAll(allChecked);
    setCheckedRows(
      allChecked ? processData?.results?.map((row) => row.id) : []
    );
    if (allChecked) {
      setshipmentdic(
        processData?.results?.map((row) => ({
          ...row,
          shipment_status: "shipment_in_transaction",
        }))
      );
    } else {
      setshipmentdic([]);
    }
  };
  const moveIntransction = () => {
    if (checkedRows.length === 0) {
      showErrorAlert("Please select rows data");
    } else {
      dispatch({ type: EXPORT_PROCESS, payload: shipmentDic });
    }
  };
  const fetchReceived = () => {
    dispatch({
      type: FETCH_PROCESS_REQUEST,
      payload: "received",
      itemsPerPage: itemsPerPage,
      currentPage: currentPage,
    });
  };

  useEffect(() => {
    if (searchValue !== null && selectedDomain !== null) {
      if (searchValue) {
        dispatch({
          type: FETCH_PROCESS_REQUEST,
          payload: "received",
          itemsPerPage: itemsPerPage,
          currentPage: currentPage,
          sku: searchValue,
        });
      } else {
        fetchReceived();
        setCheckedRows([]);
        setEditingRow(null);
        setSelectAll(false);
        setshipmentdic([]);
      }
    }
  }, [searchValue, selectedDomain, itemsPerPage, currentPage]);

  useEffect(() => {
    setSearchvalue("");
  }, []);

  return (
    <>
      {/* {loading ? <Loader /> : null} */}
      <Box
        sx={{
          height: "76vh",
          overflowY: "auto",
          padding: "0rem 2rem 0rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Searchbar placeholder="Search sku" />
          <Box sx={{ display: "flex", gap: 1 }}>
            <DownloadPdf data={processData} filename="receive" />
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "0.50rem",
            padding: "1rem 0rem",
            marginBottom: "2rem",
            height: "65vh",
          }}
        >
          <Shipmentprocess
            tabledata={processData}
            handleCheckboxChange={handleCheckboxChange}
            handleSelectAllChange={handleSelectAllChange}
            undoProcess={moveIntransction}
            handleInputChange=""
          />
        </Box>
      </Box>
      <Bottombar
        currentPage={currentPage}
        totalPages={Math.ceil(processData?.count / itemsPerPage)}
        itemsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
      />
      <ProfileResetPassword />
      <ToastContainer />
    </>
  );
};

export default Receive;
