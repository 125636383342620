import { useSelector } from "react-redux";
import {
  FETCH_DISPOSITION_FILTER,
  FETCH_FCCODE_FILTER,
  FETCH_SKU_FILTER,
  FETCH_STATE_FILTER,
} from "./filterAction";

const intialstate = {
  uniqueSku: [],
  uniqueFccode: [],
  uniqueDisposition: [],
  uniqueState: [],
};

const filterReducer = (state = intialstate, action) => {
  switch (action.type) {
    case FETCH_SKU_FILTER:
      return {
        ...state,
        uniqueSku: action.payload,
      };
    case FETCH_FCCODE_FILTER:
      return {
        ...state,
        uniqueFccode: action.payload,
      };
    case FETCH_DISPOSITION_FILTER:
      return {
        ...state,
        uniqueDisposition: action.payload,
      };
    case FETCH_STATE_FILTER:
      return {
        ...state,
        uniqueState: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;

export function useFilterMaster() {
  return useSelector((state) => state.filter);
}
