import { all, call, put, takeLatest } from "redux-saga/effects";
import { showErrorAlert, showSuccessAlert } from "../../toast/toast";
import shipmenetServices from "../../services/shipmenet.services";

import {
  CREATE_ERROR,
  DATE_SHIPMENT_FILTER,
  EXPORT_PROCESS,
  FETCH_PROCESS,
  FETCH_PROCESS_REQUEST,
  FETCH_SHIPMNET_REQUEST,
  INTRANSACTION_SHIPTRANSACTION,
  PROCESS_INTRANSACTION,
  PROCESS_RECEIVED,
  UNDO_INTRANSACTION,
  UNDO_PROCESS,
  UNIQUE_SHIPMENT_KEY,
  UPLOAD_SHIPMENT_OBJECT,
  UPDATE_SHIPMENT,
  FILTER_SHIPMENT_TRANCTION,
  UPDATE_SHIPMENT_IN_TRANSACTION,
  GET_ERROR,
  GET_ERROR_REQUEST,
} from "./shipmentAction";
import { FAILED_LOAD_REQUEST, UPLOAD_LOAD_REQUEST } from "../stock/stockAction";
import Swal from "sweetalert2";

//shipment alert fetch data
function* fetchShipmentdata(action) {
  try {
    const { data } = yield call(
      shipmenetServices.shipmentAlert,
      action.itemsPerPage,
      action.currentPage
    );
    yield put({ type: FETCH_PROCESS, payload: data});
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* fetcherrordata(action) {
  try {
    const { data } = yield call(
      shipmenetServices.getErrors,
      action.itemsPerPage,
      action.currentPage,
      action.startDate,
      action.endDate,
    );
    yield put({ type: GET_ERROR, payload: data});
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//shipmentalert filter
function* dateShipmentfilter(action) {
  try {
    yield put({ type: UPLOAD_LOAD_REQUEST });
    const { data } = yield call(
      shipmenetServices.getShipmentalertparams,
      action.startDate,
      action.endDate,
      action.ship_to_state,
      action.sku,
      action.shipment_color,
      action.itemsPerPage,
      action.currentPage
    );
    yield put({ type: UNIQUE_SHIPMENT_KEY, payload: data });
    yield put({ type: FETCH_PROCESS, payload: data });
    yield put({ type: FAILED_LOAD_REQUEST });
  } catch (error) {
    yield put({ type: FAILED_LOAD_REQUEST });
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

// export alert to process
function* expertProcess(action) {
  try {
    const { data } = yield call(shipmenetServices.movePocess, action.payload);
    yield put(action.href("/process"));
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//undo process to alert
function* undoProcess(action) {
  try {
    const { data } = yield call(
      shipmenetServices.undoprocessdata,
      action.payload,
      action.deletetype,
      action.itemsPerPage, action.currentPage
    );
    showSuccessAlert("Process undo done successfully!");
    yield put({
      type: FETCH_PROCESS_REQUEST,
      payload: "in_process",
      // itemsPerPage: 10,
      // currentPage: 0,
    });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//fetch peocess,in-transaction and shipment-in-transaction and received data
function* fetchProccessData(action) {
  try {
    const { data } = yield call(
      shipmenetServices.getProcessdata,
      action.payload,
      action.itemsPerPage,
      action.currentPage,
      action.date,
      action.sku
    );
    yield put({ type: FETCH_PROCESS, payload: data });
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//process intansaction,shipment-in-transaction
function* proccessIntransaction(action) {
  try {
    const { data } = yield call(
      shipmenetServices.moveIntransction,
      action.payload,
      action.itemsPerPage,
      action.currentPage
    );
    yield put(action.href("/in-transaction"));
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* inTransistToship(action) {
  try {
    const { data } = yield call(
      shipmenetServices.inTransactionToshipInTranscation,
      action.payload
    );
     yield put({
      type: FETCH_PROCESS_REQUEST,
      payload: "shipment_in_transaction",
      itemsPerPage: 10,
      currentPage: 0,
    });
    yield put(action.href("/shipment-in-transaction"));
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
//undo
function* undoIntransction(action) {
  try {
    const confirmed = yield call(() =>
      Swal.fire({
        title: "Are you sure?",
        text: "Undo this item",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#ff0000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => result.isConfirmed)
    );
    if (confirmed) {
      const { data } = yield call(
        shipmenetServices.updateIntransction,
        action.payload
      );
      yield put({ type: FETCH_PROCESS_REQUEST, payload: "in_transaction" });
    }
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

//create error in shipment-in-transaction
function* createError(action) { 
  try {
    const { data } = yield call(shipmenetServices.createError, action.payload);
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}
function* processReceived(action) {
  try {
    const { data } = yield call(shipmenetServices.moveRecieved, action.payload);
    yield put({
      type: FETCH_PROCESS_REQUEST,
      payload: "shipment_in_transaction",
      itemsPerPage: action.itemsPerPage,
      currentPage: action.currentPage,
      sku: action.searchValue,
    });
    if(action.errorData.length > 0) {      
      yield put({
        type: CREATE_ERROR,
        payload: action.errorData,
      });
    }
       // yield put(action.href("/received"));
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

// function* createError(action) {
//   try {
//     yield put({
//       type: CREATE_ERROR,
//       payload: action.errorData,
//       searchValue: action.searchValue,
//     });
//   } catch (error) {
//     showErrorAlert(error?.response?.data?.data?.message || 'An unexpected error occurred');
//   }
// }

//process received
function* intansactionupdate(action) {
  try {
    const { data } = yield call(
      shipmenetServices.inTransactionUpdate,
      action.payload
    );
    showSuccessAlert("Shipment object updated successfully");
    // yield put({
    //   type: UPDATE_SHIPMENT,
    //   payload: data,
    //   itemsPerPage: 10,
    //   currentPage: 1,
    // });
  } catch (error) {
    showErrorAlert(
      error?.response?.data?.data?.message || "An unknown error occurred"
    );
  }
}
// update shipment in transaction
function* shipmentintansactionupdate(action) {
  try {
    const { data } = yield call(
      shipmenetServices.updateShipmentIntransction,
      action.payload
    );
    showSuccessAlert("Shipment in transaction object updated successfully");
    // yield put({
    //   type: UPDATE_SHIPMENT,
    //   payload: data,
    //   itemsPerPage: 10,
    //   currentPage: 1,
    // });
  } catch (error) {
    showErrorAlert(
      error?.response?.data?.data?.message || "An unknown error occurred"
    );
  }
}
//upload shipment object
function* shipmentObject(action) {
  try {
    const { data } = yield call(
      shipmenetServices.UploadShipmentobject,
      action.payload,
      action.params
    );
    showSuccessAlert("Shipment Object file uploaded successfully!");
    if (action.params === "process_file") {
      yield put({ type: FETCH_PROCESS_REQUEST, payload: "in_process" });
    } else {
      yield put({ type: FETCH_PROCESS_REQUEST, payload: "in_transaction" });
    }
  } catch (error) {
    showErrorAlert(error?.response?.data?.data?.message);
  }
}

function* shipmentSaga() {
  yield all([
    takeLatest(FETCH_SHIPMNET_REQUEST, fetchShipmentdata),
    takeLatest(DATE_SHIPMENT_FILTER, dateShipmentfilter),
    takeLatest(EXPORT_PROCESS, expertProcess),
    takeLatest(FETCH_PROCESS_REQUEST, fetchProccessData),
    takeLatest(UNDO_PROCESS, undoProcess),
    takeLatest(UNDO_INTRANSACTION, undoIntransction),
    takeLatest(PROCESS_INTRANSACTION, proccessIntransaction),
    takeLatest(PROCESS_RECEIVED, processReceived),
    takeLatest(CREATE_ERROR, createError),
    takeLatest(INTRANSACTION_SHIPTRANSACTION, inTransistToship),
    takeLatest(UPLOAD_SHIPMENT_OBJECT, shipmentObject),
    takeLatest(UPDATE_SHIPMENT, intansactionupdate),
    takeLatest(UPDATE_SHIPMENT_IN_TRANSACTION, shipmentintansactionupdate),
    takeLatest(GET_ERROR_REQUEST, fetcherrordata),
  ]);
}

export default shipmentSaga;
